import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { IMonster } from '@t12/common/characters/interfaces/monster.interface';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { QuestIcon } from '@t12/common/characters/types/quest-icon.type';
import { PlayerUpdateInfosActionSocket } from '@t12/common/player/constants/player-update-infos-action-socket.interface';

export const CharactersActions = createActionGroup({
  source: 'CHARACTER',
  events: {
    'Set characters': props<{ characters: (IMonster | INPC | IPlayer)[] }>(),
    'Add character': props<{ character: IMonster | INPC | IPlayer }>(),
    'Character is dead': props<{ id: number; kind: CharacterKind }>(),
    'Remove character by id': props<{ id: number; kind: CharacterKind }>(),
    'Reset characters': emptyProps(),
    'Delete all characters': emptyProps(),

    'Add player': props<{ id: number; position: Position }>(),
    'Add player failed': emptyProps(),

    'Add health': props<{ id: number; kind: CharacterKind; health: number }>(),
    'Add mana': props<{ id: number; kind: CharacterKind; mana: number }>(),
    'Add health mana': props<{
      id: number;
      kind: CharacterKind;
      health: number;
      mana: number;
    }>(),
    'Add xp': props<{ id: number; xp: number }>(),
    'Level up': props<{ id: number }>(),

    'Set quest icon': props<{ codeCharacter: string; questIcon?: QuestIcon }>(),

    'Player use an item': props<{
      updateInfos: PlayerUpdateInfosActionSocket;
    }>(),
    'Player use an item failed': emptyProps(),

    'Player pick an item': props<{
      containerId: number;
      itemCode: string;
      amount: number;
    }>(),
  },
});
