import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Workshop } from '@t12/common/job/interfaces/workshop.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';

export const WorkshopActions = createActionGroup({
  source: 'WORKSHOP',
  events: {
    'Open workshop success': props<{ workshop: Workshop }>(),
    'Open workshop failed wrong job': props<{ jobCode: JobCode }>(),
    'Open workshop failed required xp low': props<{
      xp: number;
      requiredXp: number;
    }>(),
    'Close workshop': emptyProps(),
  },
});
