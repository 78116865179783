import { createReducer, on } from '@ngrx/store';
import { UserDevice } from '@t12/user/enums/user-device.enum';
import { LoadingStatus } from '@t12/utils/enums/loading-status.enum';
import { UserActions } from '../actions/user.actions';
import { initialUserState, UserState } from '../index';

export const UserReducer = createReducer(
  initialUserState,

  on(UserActions.changeMail, (userState: UserState, { email }) => ({
    ...userState,
    email,
  })),

  on(UserActions.changeMusicState, (userState: UserState, { music }) => ({
    ...userState,
    settings: {
      ...userState.settings,
      music,
    },
  })),

  on(UserActions.changeNoisesState, (userState: UserState, { noises }) => ({
    ...userState,
    settings: {
      ...userState.settings,
      noises,
    },
  })),

  on(
    UserActions.changeChatPreviewState,
    (userState: UserState, { chatPreview }) => ({
      ...userState,
      settings: {
        ...userState.settings,
        chatPreview,
      },
    }),
  ),

  on(UserActions.changeLanguage, (userState: UserState, { language }) => ({
    ...userState,
    language,
  })),

  on(UserActions.setDevice, (userState: UserState, { widthScreen }) => {
    const userDevice =
      widthScreen > 1440
        ? UserDevice.DESKTOP
        : widthScreen > 1024
          ? UserDevice.LAPTOP
          : widthScreen > 768
            ? UserDevice.TABLET
            : UserDevice.MOBILE;

    return { ...userState, userDevice };
  }),

  on(UserActions.loginUser, (userState: UserState) => ({
    ...userState,
    loadingStatus: LoadingStatus.IN_PROGRESS,
  })),

  on(UserActions.loginUserSuccess, (userState: UserState, { user }) => ({
    ...userState,
    error: undefined,
    email: user.email,
    settings: user.settings,
    language: user.language,
    loadingStatus: LoadingStatus.LOADED,
  })),

  on(UserActions.registerLoginUserFail, (userState: UserState, { error }) => ({
    ...userState,
    loadingStatus: LoadingStatus.NOT_LOADED,
    error,
  })),

  on(UserActions.loadUser, (userState: UserState, { user }) => ({
    ...userState,
    email: user.email,
    settings: user.settings,
    language: user.language,
    loadingStatus: LoadingStatus.LOADED,
    error: undefined,
  })),

  on(UserActions.logout, (userState: UserState) => ({
    ...initialUserState,
    userDevice: userState.userDevice,
    language: userState.language,
  })),
);
