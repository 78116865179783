import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GroupLeaderModified } from '@t12/common/socials/interfaces/group-leader-modified.interface';
import { PlayerFriendRequest } from '@t12/common/socials/interfaces/player-friend-request.interface';
import { PlayerGroupInfos } from '@t12/common/socials/interfaces/player-group-infos.interface';
import { SocialsGroupActions } from '@t12/socials/store/actions/group/socials-group.actions';
import { filter } from 'rxjs';
import { SocketService } from '../../socket.service';

@Injectable({
  providedIn: 'root',
})
export class SocialsGroupSocketService {
  constructor(
    private readonly _store: Store,
    private readonly _socketService: SocketService,
  ) {}

  public init() {
    this._listenForPlayerSocialsGroupActions();
    this._listenForSocialsGroupAcceptActions();
    this._listenForSocialsGroupChangeLeadActions();
    this._listenForSocialsGroupPlayerLeftActions();
    this._listenForSocialsGroupPlayerKickActions();
  }

  private _listenForPlayerSocialsGroupActions() {
    const socialSubscription = this._socketService
      .fromEvent<PlayerFriendRequest>('group-request')
      .pipe(filter((groupRequest) => !!groupRequest))
      .subscribe((groupRequest) => {
        this._store.dispatch(
          SocialsGroupActions.addGroupRequest({ groupRequest }),
        );
      });
    this._socketService.addSubscription(socialSubscription);
  }

  private _listenForSocialsGroupAcceptActions() {
    const socialSubscription = this._socketService
      .fromEvent<PlayerGroupInfos>('group-request-accepted')
      .pipe(filter((groupRequestAccepted) => !!groupRequestAccepted))
      .pipe()
      .subscribe((player) => {
        this._store.dispatch(SocialsGroupActions.playerJoinGroup({ player }));
      });
    this._socketService.addSubscription(socialSubscription);
  }

  private _listenForSocialsGroupChangeLeadActions() {
    const socialSubscription = this._socketService
      .fromEvent<GroupLeaderModified>('group-leader-changed')
      .pipe(filter((groupLeaderChanged) => !!groupLeaderChanged))
      .subscribe((groupLeaderModified) => {
        this._store.dispatch(
          SocialsGroupActions.setGroupLeaderSuccess({
            leader: { name: groupLeaderModified.leaderName },
          }),
        );
      });
    this._socketService.addSubscription(socialSubscription);
  }

  private _listenForSocialsGroupPlayerLeftActions() {
    const socialSubscription = this._socketService
      .fromEvent<string>('group-leave')
      .pipe(filter((groupLeave) => !!groupLeave))
      .subscribe((name) => {
        this._store.dispatch(
          SocialsGroupActions.playerLeftGroup({
            name,
          }),
        );
      });
    this._socketService.addSubscription(socialSubscription);
  }

  private _listenForSocialsGroupPlayerKickActions() {
    const socialSubscription = this._socketService
      .fromEvent('group-kick')
      .pipe(filter((groupKick) => !!groupKick))
      .subscribe(() => {
        this._store.dispatch(SocialsGroupActions.kickFromGroup());
      });
    this._socketService.addSubscription(socialSubscription);
  }
}
