import { Material } from '@t12/common/container/enums/container-material.enum';

const materialMaxMapping: Partial<Record<Material, number>> = {
  [Material.HardWood]: 1,
  [Material.Grass]: 1,
  [Material.Rock]: 2,
};

export const getOpeningSoundContainer = (
  containerMaterial: Material,
): string => {
  if (!containerMaterial) return '';

  const max = materialMaxMapping[containerMaterial] ?? 0;
  const randomValue = Math.floor(Math.random() * (max + 1));

  return `${containerMaterial}_${randomValue}`;
};
