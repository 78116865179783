import { Injectable } from '@angular/core';
import { allChatCommands } from '@t12/common/chat/constants/chat-commands.const';
import { ChatLogColor } from '@t12/common/chat/enums/chat-log-color.enums';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';

import { ChatLogLayout } from '@t12/common/chat/interfaces/chat-log-layout.interface';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';

@Injectable({
  providedIn: 'root',
})
export class ChatManagerService {
  public createChatLog(
    name: string,
    scope: ChatLogScope,
    text: string,
    kind: ChatLogKind,
    tab: ChatTab = ChatTab.CHAT,
    emit: boolean = false,
    timeDate?: string,
  ): ChatLog {
    if (kind === ChatLogKind.Unknown) return undefined;

    const layout = this.setChatLayout(kind);
    const time =
      timeDate ||
      new Date().toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
      });

    return {
      name,
      scope,
      layout,
      text,
      time,
      kind,
      emit,
      tab,
    };
  }

  public setChatLayout(chatLogKind: ChatLogKind): ChatLogLayout {
    const layout: ChatLogLayout = {
      nameColor: ChatLogColor.Me,
      textColor: ChatLogColor.Default,
    };

    const mappings: Partial<Record<ChatLogKind, Partial<ChatLogLayout>>> = {
      [ChatLogKind.Log]: { textColor: ChatLogColor.Log },
      [ChatLogKind.Roll]: { textColor: ChatLogColor.Roll },
      [ChatLogKind.Me]: { textColor: ChatLogColor.Me },
      [ChatLogKind.Bonus]: { nameColor: ChatLogColor.Bonus },
      [ChatLogKind.Malus]: { nameColor: ChatLogColor.Malus },
      [ChatLogKind.Pm]: {
        nameColor: ChatLogColor.Pm,
        textColor: ChatLogColor.Pm,
      },
      [ChatLogKind.Party]: { nameColor: ChatLogColor.Party },
    };

    return { ...layout, ...mappings[chatLogKind] };
  }

  // Argument : ChatLog complet
  // Résultat : Vérifie si une commande a été saisie
  public getChatLogKindCmd(text: string, scope: ChatLogScope): ChatLogKind {
    if (text.startsWith('/')) {
      const cmd = text.split('/').pop()?.split(' ')[0].toLowerCase();
      const foundKey = Object.keys(allChatCommands).find((key) =>
        allChatCommands[key].includes(cmd),
      );

      if (foundKey) return foundKey as ChatLogKind;
      return ChatLogKind.Unknown;
    }

    switch (scope) {
      case ChatLogScope.Global:
        return ChatLogKind.Global;
      case ChatLogScope.Pm:
        return ChatLogKind.Pm;
      case ChatLogScope.Party:
        return ChatLogKind.Party;
      default:
        return ChatLogKind.Local;
    }
  }

  public getScopeChatByKind(kind: ChatLogKind): ChatLogScope {
    switch (kind) {
      case ChatLogKind.Global:
        return ChatLogScope.Global;
      case ChatLogKind.Pm:
        return ChatLogScope.Pm;
      case ChatLogKind.Party:
        return ChatLogScope.Party;
      default:
        return ChatLogScope.Local;
    }
  }
}
