import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getPlayerName,
  getPlayerID,
  getPlayerPositionInFront,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { InventoryDbService } from '@t12/inventory/services/inventory-db/inventory-db.service';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import { switchMap, take, tap, withLatestFrom, concatMap } from 'rxjs';

@Injectable()
export class InventoryDropEffects {
  private _dropItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.dropItemSuccess),
      withLatestFrom(
        this._store.select(getPlayerID),
        this._store.select(getPlayerName),
        this._store.select(getPlayerPositionInFront),
      ),
      switchMap(([{ item, amount }, playerId, playerName, { x, y }]) =>
        this._inventoryDbService.dropItem(playerId, item.code, amount).pipe(
          take(1),
          tap(() => {
            this._audioService.playSound('miscs', 'drop_leather', 'ogg');
            this._notificationService.addNotification(
              'validation',
              `-${amount} ${item.name}`,
              5000,
              item.img,
            );
          }),
          concatMap(() => [
            WorldActions.addItemTile({
              x,
              y,
              item: {
                ...item,
                amount,
              },
            }),
            ChatActions.addChatLog({
              tab: ChatTab.ACTION,
              name: playerName,
              text: `a déposé au sol l'objet "${item.name}"`,
              kind: ChatLogKind.Malus,
            }),
            InventoryActions.removeItemInInventory({
              itemCode: item.code,
              amount,
            }),
          ]),
        ),
      ),
    ),
  );

  private _dropItemFailedNotEnough$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.dropItemFailedNotEnough),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas cet objet!",
          );
        }),
      ),
    { dispatch: false },
  );

  private _dropItemFailedTileInvalid$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.dropItemFailedTileInvalid),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous ne pouvez pas déposer ça ici!',
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _inventoryDbService: InventoryDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
