import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { EquipmentsDbService } from '@t12/equipments/services/equipments-db/equipments-db.service';
import { EquipmentsActions } from '@t12/equipments/store/actions/equipments.actions';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { PopOverManagerService } from '@t12/overlay/services/pop-over/pop-over-manager.service';
import { VOLUME_NOISES } from '@t12/settings/constants/volume.constant';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import {
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
  catchError,
  of,
} from 'rxjs';

@Injectable()
export class EquipmentsEffects {
  private _equipItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EquipmentsActions.equipItem),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ item, oldEquipment }, playerId]) =>
        this._equipmentDbService.addEquipment(playerId, item.code).pipe(
          take(1),
          tap(() => {
            this._audioService.playSound(
              'interfaces/equipment',
              item.slot,
              'mp3',
              VOLUME_NOISES,
            );
            this._notificationService.addNotification(
              'item',
              `-1 ${item.name}`,
              5000,
              item.img,
            );
          }),
          map(() => EquipmentsActions.equipItemSuccess({ item, oldEquipment })),
          catchError(() => of(EquipmentsActions.equipItemFailed())),
        ),
      ),
    ),
  );

  private _equipItemFailedAlreadyHave$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EquipmentsActions.equipItemFailedAlreadyHave),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous portez déjà cet équipement.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _equipItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EquipmentsActions.equipItemSuccess),
      switchMap(({ item, oldEquipment }) =>
        [
          InventoryActions.removeItemInInventory({
            itemCode: item.code,
            amount: 1,
          }),
          oldEquipment
            ? InventoryActions.addItemInInventory({
                item: oldEquipment,
                amount: 1,
              })
            : null,
        ].filter((action) => !!action),
      ),
    ),
  );

  private _unEquipItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EquipmentsActions.unEquipItem),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ item }, playerId]) =>
        this._equipmentDbService.removeEquipment(playerId, item.code).pipe(
          take(1),
          tap(() => {
            this._audioService.playSound(
              'interfaces/equipment',
              item.slot,
              'mp3',
              VOLUME_NOISES,
            );
            this._notificationService.addNotification(
              'item',
              `+1 ${item.name}`,
              5000,
              item.img,
            );
            this._popoverService.deletePopOver();
          }),
          map(() => EquipmentsActions.unEquipItemSuccess({ item })),
          catchError(() => of(EquipmentsActions.unEquipItemFailed())),
        ),
      ),
    ),
  );

  private _unEquipItemFailedNoPlace$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EquipmentsActions.unEquipItemFailedNoPlace),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas de place dans votre inventaire.",
          );
        }),
      ),
    { dispatch: false },
  );

  private _unEquipItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EquipmentsActions.unEquipItemSuccess),
      map(({ item }) =>
        InventoryActions.addItemInInventory({ item, amount: 1 }),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _equipmentDbService: EquipmentsDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _popoverService: PopOverManagerService,
    private readonly _store: Store,
  ) {}
}
