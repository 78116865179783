import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';
import { isFriend } from '@t12/socials/store/selectors/socials.selectors';
import { switchMap, map, filter } from 'rxjs';
import { SocketService } from '../../socket.service';

@Injectable({
  providedIn: 'root',
})
export class ChatSocketService {
  constructor(
    private readonly _store: Store,
    private readonly _socketService: SocketService,
  ) {}

  init(): void {
    const chatSubscription = this._socketService
      .fromEvent<ChatLog>('chat')
      .pipe(
        filter((chat) => !!chat),
        switchMap((chatLog) =>
          this._store
            .select(isFriend(chatLog.name))
            .pipe(map((isPlayerFriend) => ({ chatLog, isPlayerFriend }))),
        ),
      )
      .subscribe(({ chatLog, isPlayerFriend }) => {
        if (chatLog.scope === ChatLogScope.Pm && !isPlayerFriend) return;
        this._store.dispatch(ChatActions.receiveChatLog({ chatLog }));
      });

    this._socketService.addSubscription(chatSubscription);
  }
}
