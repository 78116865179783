import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharacterCreationManagerService } from '@t12/creation-player/services/character-creation-manager/character-creation-manager.service';
import {
  getGenderPlayerCreation,
  getPlayerCreation,
  getRaceCodePlayerCreation,
} from '@t12/creation-player/store/selectors/creation-player.selectors';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { getNamesByGender } from '@t12/utils/adapters/get-names-by-gender.adapter';
import {
  catchError,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
  filter,
} from 'rxjs';
import { CreationPlayerActions } from '../actions/creation-player.actions';

@Injectable()
export class CreationPlayerEffects {
  private _canCreateNewCharacter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CreationPlayerActions.canCreateNewCharacter),
      switchMap(() =>
        this._characterCreationService.haveEnoughCharactersSlot$().pipe(
          filter((haveEnoughCharactersSlot) => !!haveEnoughCharactersSlot),
          map((haveEnoughCharactersSlot) =>
            CreationPlayerActions.canCreateNewCharacterSuccess(),
          ),
          catchError(() =>
            of(CreationPlayerActions.canCreateNewCharacterFailedMaxReached()),
          ),
        ),
      ),
    ),
  );

  private _redirectToCharacter$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          CreationPlayerActions.createCharacterSuccess,
          CreationPlayerActions.canCreateNewCharacterFailedMaxReached,
        ),
        tap(() => this._router.navigateByUrl('/characters')),
      ),
    { dispatch: false },
  );

  private _canCreateNewCharacterSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CreationPlayerActions.canCreateNewCharacterSuccess),
      switchMap(() =>
        forkJoin({
          races: this._characterCreationService.getAllRaces$(),
          stats: this._characterCreationService.getAllStats$(),
          personalities: this._characterCreationService.getAllPersonalities$(),
        }).pipe(
          take(1), // Appliqué globalement après forkJoin
          map(({ races, stats, personalities }) =>
            CreationPlayerActions.loadItemsForCreationSuccess({
              races,
              stats,
              personalities,
            }),
          ),
          catchError(() =>
            of(CreationPlayerActions.loadItemsForCreationFailed()),
          ),
        ),
      ),
    ),
  );

  private _initNames$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        CreationPlayerActions.initNames,
        CreationPlayerActions.updateActiveRace,
        CreationPlayerActions.setGenderPlayer,
      ),
      withLatestFrom(
        this._store.select(getRaceCodePlayerCreation),
        this._store.select(getGenderPlayerCreation),
      ),
      switchMap(([_, raceCode, gender]) =>
        this._characterCreationService.getRandomNamesByRace$(raceCode).pipe(
          take(1),
          map((randomNames) => {
            if (!randomNames) return CreationPlayerActions.initNamesFailed();

            const { firstNames, lastNames } = getNamesByGender(
              randomNames,
              gender,
            );

            return CreationPlayerActions.initNamesSuccess({
              firstNames,
              lastNames,
            });
          }),
          catchError(() => of(CreationPlayerActions.initNamesFailed())),
        ),
      ),
    ),
  );

  private _createCharacter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CreationPlayerActions.createCharacter),
      withLatestFrom(this._store.select(getPlayerCreation)),
      filter(() => this._authService.isLoggedIn()),
      mergeMap(([_, player]) => {
        return this._characterCreationService.addPlayerCharacter(player).pipe(
          take(1),
          switchMap(() => [
            CreationPlayerActions.init(),
            CreationPlayerActions.createCharacterSuccess(),
          ]),
          catchError(() => of(CreationPlayerActions.createCharacterFailed())),
        );
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _authService: AuthenticationService,
    private readonly _characterCreationService: CharacterCreationManagerService,
    private readonly _router: Router,
    private readonly _store: Store,
  ) {}
}
