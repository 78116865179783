import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { IJobRecipe } from '@t12/common/job/interfaces/recipe.interface';

export const LearnRecipeActions = createActionGroup({
  source: 'LEARN-RECIPE',
  events: {
    'Learn recipe': props<{ item: Item }>(),
    'Learn recipe success': props<{ recipe: IJobRecipe; item: Item }>(),
    'Learn recipe failed': emptyProps(),
    'Learn recipe failed already known': emptyProps(),
    'Learn recipe failed missing job': emptyProps(),
    'Learn recipe failed not enough xp': props<{
      playerJobXp: number;
      requiredXp: number;
    }>(),
  },
});
