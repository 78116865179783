import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { getPlayerName } from '@t12/characters/store/selectors/characters.selectors';
import { ChatCommandsService } from '@t12/chat/services/chat-commands/chat-commands.service';
import { ChatCommandActions } from '@t12/chat/store/actions/chat-commands/chat-commands.actions';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import {
  getPmPlayerName,
  getScopeChat,
} from '@t12/chat/store/selectors/chat.selectors';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import {
  isPlayerInGroup,
  getFriends,
} from '@t12/socials/store/selectors/socials.selectors';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs';

@Injectable()
export class ChatCommandsEffects {
  private _canDispatchChatLog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatCommandActions.canDispatchChatLog),
      map(({ chatLog }) => {
        switch (chatLog.kind) {
          case ChatLogKind.Pm:
            return ChatCommandActions.canDispatchChatLogPm({ chatLog });
          case ChatLogKind.Party:
            return ChatCommandActions.canDispatchChatLogParty({ chatLog });
          default: {
            return ChatCommandActions.canDispatchChatLogSuccess({ chatLog });
          }
        }
      }),
    ),
  );

  private _canDispatchChatLogPm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatCommandActions.canDispatchChatLogPm),
      filter(({ chatLog: { kind } }) => kind === ChatLogKind.Pm),
      withLatestFrom(
        this._store.select(getPmPlayerName),
        this._store.select(getFriends),
      ),
      switchMap(([{ chatLog }, pmName, friends]) => {
        const isCommand = chatLog.text.startsWith('/');
        const playerName = isCommand
          ? chatLog.text.split(' ')[1]?.toLowerCase()
          : null;
        const text = isCommand
          ? chatLog.text.split(' ').slice(2).join(' ').trim()
          : chatLog.text;

        if (!pmName && isCommand) {
          if (!playerName)
            return [ChatCommandActions.canDispatchChatLogPmFailedNoPmName()];

          const friend = friends.find((f) =>
            f.name.toLowerCase().includes(playerName),
          );
          if (!friend)
            return [ChatCommandActions.canDispatchChatLogPmFailedNotFriend()];
          if (!friend.online)
            return [
              ChatCommandActions.canDispatchChatLogPmFailedFriendOffline({
                friendName: friend.name,
              }),
            ];
          if (!text)
            return [ChatCommandActions.canDispatchChatLogPmFailedNoText()];

          return [
            ChatActions.setPmPlayer({ name: friend.name }),
            ChatCommandActions.canDispatchChatLogSuccess({ chatLog }),
          ];
        }

        if (!text)
          return [ChatCommandActions.canDispatchChatLogPmFailedNoText()];

        return [ChatCommandActions.canDispatchChatLogSuccess({ chatLog })];
      }),
    ),
  );

  private _canDispatchChatLogPmFailedNotFriend$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatCommandActions.canDispatchChatLogPmFailedNotFriend),
      tap(() =>
        this._notificationService.addNotification(
          'error',
          "Vous n'avez pas d'amis qui match avec ce nom",
        ),
      ),
      withLatestFrom(this._store.select(getScopeChat)),
      filter(([_, scopeChat]) => scopeChat === ChatLogScope.Pm),
      map(() => ChatActions.removePmPlayer()),
    ),
  );

  private _canDispatchChatLogPmFailedFriendOffline$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatCommandActions.canDispatchChatLogPmFailedFriendOffline),
      map(({ friendName }) =>
        ChatActions.addChatLog({
          tab: ChatTab.CHAT,
          kind: ChatLogKind.Log,
          name: undefined,
          text: `${friendName} est hors-ligne pour le moment.`,
        }),
      ),
    ),
  );

  private _canDispatchChatLogPmFailedNoPmName$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ChatCommandActions.canDispatchChatLogPmFailedNoPmName),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Merci de renseigner le nom du joueur',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _canDispatchChatLogPmFailedNoText$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ChatCommandActions.canDispatchChatLogPmFailedNoText),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Merci d'écrire un message à envoyer",
          ),
        ),
      ),
    { dispatch: false },
  );

  private _canDispatchChatLogParty$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatCommandActions.canDispatchChatLogParty),
      filter(({ chatLog: { kind } }) => kind === ChatLogKind.Party),
      withLatestFrom(this._store.select(isPlayerInGroup)),
      map(([{ chatLog }, isPlayerInGroup]) => {
        if (!isPlayerInGroup)
          return ChatCommandActions.canDispatchChatLogPartyFailedNotInGroup();
        else return ChatCommandActions.canDispatchChatLogSuccess({ chatLog });
      }),
    ),
  );

  private _canDispatchChatLogPartyFailedNotInGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatCommandActions.canDispatchChatLogPartyFailedNotInGroup),
      tap(() =>
        this._notificationService.addNotification(
          'error',
          "Vous n'êtes pas dans un groupe",
        ),
      ),
      withLatestFrom(this._store.select(getScopeChat)),
      filter(([_, scopeChat]) => scopeChat === ChatLogScope.Party),
      map(() => ChatActions.setScopeChat({ scopeChat: ChatLogScope.Local })),
    ),
  );

  private _canDispatchChatLogSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatCommandActions.canDispatchChatLogSuccess),
      withLatestFrom(this._store.select(getScopeChat)),
      switchMap(([{ chatLog }, scopeChat]) => {
        const { text, scope } = chatLog;

        return [
          scope !== scopeChat && text.startsWith('/')
            ? ChatActions.setScopeChat({ scopeChat: scope })
            : null,
          ChatCommandActions.formatChatLog({ chatLog }),
        ].filter(Boolean);
      }),
    ),
  );

  private _formatChatLog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatCommandActions.formatChatLog),
      map(({ chatLog }) => {
        const formatActionsMap: Partial<
          Record<ChatLogKind, (chatLog: ChatLog) => Action>
        > = {
          [ChatLogKind.Global]: (chatLog) =>
            ChatCommandActions.formatChatLogGlobal({ chatLog }),
          [ChatLogKind.Local]: (chatLog) =>
            ChatCommandActions.formatChatLogLocal({ chatLog }),
          [ChatLogKind.Roll]: (chatLog) =>
            ChatCommandActions.formatChatLogRoll({ chatLog }),
          [ChatLogKind.Me]: (chatLog) =>
            ChatCommandActions.formatChatLogMe({ chatLog }),
          [ChatLogKind.Pm]: (chatLog) =>
            ChatCommandActions.formatChatLogPm({ chatLog }),
          [ChatLogKind.Party]: (chatLog) =>
            ChatCommandActions.formatChatLogParty({ chatLog }),
          [ChatLogKind.Log]: (chatLog) =>
            ChatCommandActions.formatChatLogLog({ chatLog }),
          [ChatLogKind.Yell]: (chatLog) =>
            ChatCommandActions.formatChatLogYell({ chatLog }),
          [ChatLogKind.Bonus]: (chatLog) =>
            ChatCommandActions.formatChatLogBonus({ chatLog }),
          [ChatLogKind.Malus]: (chatLog) =>
            ChatCommandActions.formatChatLogMalus({ chatLog }),
        };

        return (
          formatActionsMap[chatLog.kind]?.(chatLog) ||
          ChatCommandActions.formatChatLogFailed()
        );
      }),
    ),
  );

  private _handleFormattedChatLog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        ChatCommandActions.formatChatLogGlobal,
        ChatCommandActions.formatChatLogLocal,
        ChatCommandActions.formatChatLogRoll,
        ChatCommandActions.formatChatLogMe,
        ChatCommandActions.formatChatLogPm,
        ChatCommandActions.formatChatLogParty,
        ChatCommandActions.formatChatLogLog,
        ChatCommandActions.formatChatLogYell,
        ChatCommandActions.formatChatLogBonus,
        ChatCommandActions.formatChatLogMalus,
      ),
      withLatestFrom(
        this._store.select(getPlayerName),
        this._store.select(getPmPlayerName),
      ),
      map(([{ chatLog, type }, playerName, pmName]) => {
        const formatCommandMap: Record<string, (chatLog: ChatLog) => ChatLog> =
          {
            [ChatCommandActions.formatChatLogGlobal.type]: (chatLog) =>
              this._chatCommandsService.formatGlobalCommand(chatLog),
            [ChatCommandActions.formatChatLogLocal.type]: (chatLog) =>
              this._chatCommandsService.formatSayCommand(chatLog),
            [ChatCommandActions.formatChatLogRoll.type]: (chatLog) =>
              this._chatCommandsService.formatRollCommand(chatLog),
            [ChatCommandActions.formatChatLogMe.type]: (chatLog) =>
              this._chatCommandsService.formatMeCommand(chatLog),
            [ChatCommandActions.formatChatLogPm.type]: (chatLog) =>
              this._chatCommandsService.formatPmCommand(
                chatLog,
                playerName,
                pmName,
              ),
            [ChatCommandActions.formatChatLogParty.type]: (chatLog) =>
              this._chatCommandsService.formatPartyCommand(chatLog),
            [ChatCommandActions.formatChatLogLog.type]: (chatLog) =>
              this._chatCommandsService.formatLogMessage(chatLog),
            [ChatCommandActions.formatChatLogYell.type]: (chatLog) =>
              this._chatCommandsService.formatYellCommand(chatLog),
            [ChatCommandActions.formatChatLogBonus.type]: (chatLog) =>
              this._chatCommandsService.formatBonusCommand(chatLog),
            [ChatCommandActions.formatChatLogMalus.type]: (chatLog) =>
              this._chatCommandsService.formatMalusCommand(chatLog),
          };

        const chatLogFormatted = formatCommandMap[type]?.(chatLog) || chatLog;

        return ChatActions.addChatLogSuccess({
          chatLog: chatLogFormatted,
        });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _chatCommandsService: ChatCommandsService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
