import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getPlayerGold,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getFreeInventorySlotAmount } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { ShopDbService } from '@t12/shop/services/shop-db/shop-db.service';
import { ShopActions } from '@t12/shop/store/actions/shop/shop.actions';
import {
  getShopItemByCode,
  getShopActiveItem,
} from '@t12/shop/store/selectors/shop.selectors';
import {
  switchMap,
  catchError,
  of,
  tap,
  map,
  take,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class ShopBuyEffects {
  private _buyItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.buyItem),
      withLatestFrom(this._store.select(getShopActiveItem)),
      switchMap(([{ amount }, item]) =>
        this._store.select(getFreeInventorySlotAmount(item)).pipe(
          take(1),
          withLatestFrom(
            this._store.select(getPlayerGold),
            this._store.select(getShopItemByCode(item.code)),
          ),
          map(([freeAmount, playerGold, shopItem]) => {
            if (!shopItem) return ShopActions.buyItemFailedShopWrongItem();
            else if (freeAmount < amount)
              return ShopActions.buyItemFailedNotEnoughSpace();
            else if (playerGold < item.price * amount)
              return ShopActions.buyItemFailedNotEnoughGold({
                amountMissing: item.price * amount - playerGold,
              });
            else return ShopActions.buyItemSuccess({ item, amount });
          }),
        ),
      ),
    ),
  );

  private _buyItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.buyItemSuccess),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ item, amount }, playerId]) =>
        this._shopDbService.buyItem(playerId, item.code, amount).pipe(
          take(1),
          tap(() => {
            this._audioService.playSound('miscs', 'gold');
            this._notificationService.addNotification(
              'item',
              `+${amount} ${item.name} -> -${item.price} OR`,
              5000,
              item.img,
            );
          }),
          switchMap(() => [
            InventoryActions.addItemInInventory({ item, amount }),
            LocalPlayerActions.addGold({ gold: -item.price * amount }),
          ]),
          catchError(() => of(ShopActions.buyItemFailed())),
        ),
      ),
    ),
  );

  private _buyItemFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.buyItemFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _buyItemFailedShopWrongItem$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.buyItemFailedShopWrongItem),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            `Ce commerçant ne vend pas cet objet`,
          ),
        ),
      ),
    { dispatch: false },
  );

  private _buyItemFailedNotEnoughSpace$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.buyItemFailedNotEnoughSpace),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Vous n'avez plus de place pour ça",
          ),
        ),
      ),
    { dispatch: false },
  );

  private _buyItemFailedNotEnoughGold$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.buyItemFailedNotEnoughGold),
        tap(({ amountMissing }) =>
          this._notificationService.addNotification(
            'error',
            `Il vous manque ${amountMissing} G pour acheter ça`,
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _shopDbService: ShopDbService,
    private readonly _store: Store,
  ) {}
}
