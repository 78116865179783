import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Group } from '@t12/common/socials/interfaces/group.interface';
import { IPlayerFriendRequestDto } from '@t12/common/socials/interfaces/player-friend-request-dto.interface';
import { PlayerFriendRequest } from '@t12/common/socials/interfaces/player-friend-request.interface';
import { PlayerFriend } from '@t12/common/socials/interfaces/player-friend.interface';
import { PlayerGroupChangeLeaderDto } from '@t12/common/socials/interfaces/player-group-change-leader-dto.interface';
import { PlayerGroupInfos } from '@t12/common/socials/interfaces/player-group-infos.interface';
import { IPlayerGroupRequestAcceptDto } from '@t12/common/socials/interfaces/player-group-request-accept-dto.interface';
import { IPlayerGroupRequestDto } from '@t12/common/socials/interfaces/player-group-request-dto.interface';
import { PlayerGroupRequest } from '@t12/common/socials/interfaces/player-group-request.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocialsDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  public getPlayerFriends(playerId: number): Observable<PlayerFriend[]> {
    return this._http.get(`${environment(`socials/friends/${playerId}`).uri}`, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<PlayerFriend[]>;
  }

  public getPlayerFriendRequests(
    playerId: number,
  ): Observable<PlayerFriendRequest[]> {
    return this._http.get(
      `${environment(`socials/friends/requests/${playerId}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<PlayerFriendRequest[]>;
  }

  public sendFriendRequest(
    playerId: number,
    friendPlayerName: string,
  ): Observable<PlayerFriendRequest | PlayerFriend> {
    const body: IPlayerFriendRequestDto = { playerId, friendPlayerName };

    return this._http.post(
      `${environment('socials/friends/request/add').uri}`,
      body,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<PlayerFriendRequest | PlayerFriend>;
  }

  public acceptFriendRequest(
    playerId: number,
    friendPlayerName: string,
  ): Observable<PlayerFriend> {
    const body: IPlayerFriendRequestDto = { playerId, friendPlayerName };

    return this._http.post(
      `${environment('socials/friends/request/accept').uri}`,
      body,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<PlayerFriend>;
  }

  public deletePlayerFriendRequest(
    playerId: number,
    friendPlayerName: string,
  ): Observable<PlayerFriend[]> {
    return this._http.delete(
      `${environment(`socials/friends/requests/${playerId}/delete/${friendPlayerName}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<PlayerFriend[]>;
  }

  public deletePlayerFriend(playerId: number, friendPlayerName: string) {
    return this._http.delete(
      `${environment(`socials/friends/${playerId}/delete/${friendPlayerName}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  public getPlayerGroup(playerId: number): Observable<Group> {
    return this._http.get(`${environment(`socials/group/${playerId}`).uri}`, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<Group>;
  }

  public getPlayerGroupRequests(
    playerId: number,
  ): Observable<PlayerGroupRequest[]> {
    return this._http.get(
      `${environment(`socials/group/requests/${playerId}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<PlayerGroupRequest[]>;
  }

  public sendGroupRequest(
    leaderPlayerId: number,
    invitedPlayerName: string,
  ): Observable<PlayerGroupRequest | PlayerGroupInfos> {
    const body: IPlayerGroupRequestDto = {
      leaderPlayerId,
      invitedPlayerName,
    };

    return this._http.post(
      `${environment('socials/group/request/add').uri}`,
      body,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<PlayerGroupRequest | PlayerGroupInfos>;
  }

  public acceptGroupRequest(
    invitedPlayerId: number,
    leaderPlayerName: string,
  ): Observable<PlayerGroupInfos> {
    const body: IPlayerGroupRequestAcceptDto = {
      invitedPlayerId,
      leaderPlayerName,
    };

    return this._http.post(
      `${environment('socials/group/request/accept').uri}`,
      body,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<PlayerGroupInfos>;
  }

  public deletePlayerGroupRequest(
    playerId: number,
    invitedPlayerName: string,
  ): Observable<PlayerFriend[]> {
    return this._http.delete(
      `${environment(`socials/group/requests/${playerId}/delete/${invitedPlayerName}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<PlayerFriend[]>;
  }

  public changeGroupLeader(
    playerId: number,
    leaderName: string,
  ): Observable<PlayerFriendRequest> {
    const body: PlayerGroupChangeLeaderDto = { playerId, leaderName };

    return this._http.post(`${environment(`socials/group/leader`).uri}`, body, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<PlayerFriendRequest>;
  }

  public leaveGroup(playerId: number): Observable<void> {
    return this._http.delete<void>(
      `${environment(`socials/group/${playerId}/leave`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  public kickGroupPlayer(
    playerId: number,
    playerName: string,
  ): Observable<void> {
    return this._http.delete<void>(
      `${environment(`socials/group/${playerId}/kick/${playerName}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }
}
