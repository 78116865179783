/* istanbul ignore file */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { environment } from '@t12/environment';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { BehaviorSubject, filter, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private _socket: Socket;
  private _socketInitialized$ = new BehaviorSubject<boolean>(false);
  private _subscriptions: Subscription[] = [];

  constructor(private readonly _store: Store) {}

  public initSocket(playerId: number): void {
    const config: SocketIoConfig = {
      url: environment('').ws,
      options: {
        query: {
          playerId: playerId.toString(),
        },
      },
    };

    this._socket = new Socket(config);

    this._socket.on('connect', () => {
      this._socketInitialized$.next(true);
      this._store.dispatch(
        CharactersMoveActions.setCanMove({
          id: playerId,
          kind: CharacterKind.PLAYER,
          canMove: true,
        }),
      );
    });
  }

  public onSocketInitialized(): Observable<boolean> {
    return this._socketInitialized$
      .asObservable()
      .pipe(filter((ready) => ready));
  }

  public emit(event: string, data: any): void {
    if (!this._socket) return;

    this._socket.emit(event, data);
  }

  public fromEvent<T>(event: string): Observable<T> {
    return new Observable<T>((observer) => {
      this._socket.on(event, (data: T) => observer.next(data));
      return () => this._socket.off(event);
    });
  }

  public addSubscription(subscription: Subscription): void {
    this._subscriptions.push(subscription);
  }

  public disconnect(): void {
    this._socket?.disconnect();
    this._socketInitialized$.next(false);
    this.unsubscribeAll();
  }

  public unsubscribeAll() {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    this._subscriptions = [];
  }
}
