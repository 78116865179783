import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PlayerFriendRequest } from '@t12/common/socials/interfaces/player-friend-request.interface';
import { PlayerFriend } from '@t12/common/socials/interfaces/player-friend.interface';
import { SocialsFriendsActions } from '@t12/socials/store/actions/friends/socials-friends.actions';
import { filter } from 'rxjs';
import { SocketService } from '../../socket.service';

@Injectable({
  providedIn: 'root',
})
export class SocialsFriendSocketService {
  constructor(
    private readonly _store: Store,
    private readonly _socketService: SocketService,
  ) {}

  public init() {
    this._listenForPlayerSocialsFriendsActions();
    this._listenForSocialsFriendAcceptActions();
  }

  private _listenForPlayerSocialsFriendsActions() {
    const friendRequest$ = this._socketService
      .fromEvent('friend-request')
      .pipe(filter((friendRequest) => !!friendRequest))
      .subscribe((friendRequest: PlayerFriendRequest) => {
        this._store.dispatch(
          SocialsFriendsActions.addFriendRequest({ friendRequest }),
        );
      });
    this._socketService.addSubscription(friendRequest$);

    const friendRemoved$ = this._socketService
      .fromEvent('friend-removed')
      .pipe(filter((friendRemoved) => !!friendRemoved))
      .subscribe((exFriend: string) => {
        this._store.dispatch(
          SocialsFriendsActions.deleteFriend({
            name: exFriend,
            notification: false,
          }),
        );
      });
    this._socketService.addSubscription(friendRemoved$);
  }

  private _listenForSocialsFriendAcceptActions() {
    const friendRequestAccepted$ = this._socketService
      .fromEvent('friend-request-accepted')
      .pipe(filter((friendRequestAccept) => !!friendRequestAccept))
      .subscribe((playerFriend: PlayerFriend) => {
        this._store.dispatch(SocialsFriendsActions.addFriend({ playerFriend }));
      });
    this._socketService.addSubscription(friendRequestAccepted$);
  }
}
