import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { PlayerLogged } from '@t12/common/socials/interfaces/player-logged.interface';
import { SocialTab } from '../../../enums/social-tab.enum';

export const SocialsActions = createActionGroup({
  source: 'SOCIALS',
  events: {
    'Set tab': props<{ tab: SocialTab }>(),

    'Player logged in': props<{ player: PlayerLogged }>(),
    'Player logged out': props<{ player: PlayerLogged }>(),

    'Send pm': props<{ name: string }>(),

    Reset: emptyProps(),
  },
});
