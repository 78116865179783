import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IJobRecipe } from '@t12/common/job/interfaces/recipe.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';

export const CraftRecipeActions = createActionGroup({
  source: 'CRAFT RECIPE',
  events: {
    'Craft recipe': props<{ recipe: IJobRecipe }>(),
    'Craft recipe success': props<{ recipe: IJobRecipe }>(),
    'Craft recipe critical': emptyProps(),

    'Craft recipe failed': emptyProps(),
    'Craft recipe failed no job': props<{ jobCode: JobCode }>(),
    'Craft recipe failed no workshop': emptyProps(),
    'Craft recipe failed not enough ingredients': emptyProps(),
    'Craft recipe failed not enough space': emptyProps(),
    'Craft recipe failed no product': emptyProps(),
  },
});
