import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { IJobRecipe } from '@t12/common/job/interfaces/recipe.interface';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { RecipeDbService } from '@t12/jobs/services/recipe-db/recipe-db.service';
import { LearnRecipeActions } from '@t12/jobs/store/actions/recipe/learn/learn-recipe.actions';
import { ListRecipesActions } from '@t12/jobs/store/actions/recipe/list/list-recipes.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { ShopActions } from '@t12/shop/store/actions/shop/shop.actions';
import { getShopItems } from '@t12/shop/store/selectors/shop.selectors';
import {
  catchError,
  concatMap,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class LearnRecipeEffects {
  private _learnRecipe$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LearnRecipeActions.learnRecipe),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ item }, playerId]) =>
        this._recipeDb.learnRecipe(playerId, item.code).pipe(
          take(1),
          map((recipe: IJobRecipe) => {
            return LearnRecipeActions.learnRecipeSuccess({ recipe, item });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            if (
              error.statusCode === 400 &&
              error.message === 'RECIPE_ALREADY_KNOWN'
            ) {
              return of(LearnRecipeActions.learnRecipeFailedAlreadyKnown());
            } else {
              return of(LearnRecipeActions.learnRecipeFailed()); // Generic fail action
            }
          }),
        ),
      ),
    ),
  );

  private _learnRecipeSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LearnRecipeActions.learnRecipeSuccess),
      tap(({ item }) => {
        this._notificationService.addNotification(
          'job',
          `Nouvelle recette apprise: ${item.name}`,
        );
      }),
      withLatestFrom(this._store.select(getShopItems)),
      concatMap(
        ([
          {
            item,
            recipe: { products },
          },
          shop,
        ]) => {
          const findRecipeInShop =
            shop?.recipe &&
            shop.recipe.find((shopItem) => shopItem.code === item.code);
          return [
            findRecipeInShop ? ShopActions.removeItemFromShop({ item }) : null,
            InventoryActions.removeItemInInventory({
              itemCode: item.code,
              amount: 1,
            }),
            ListRecipesActions.updateFilters(),
            ListRecipesActions.setActiveRecipeFilter({
              filter: products[0].slot ?? products[0].type,
            }),
          ];
        },
      ),
      filter((action) => !!action),
    ),
  );

  private _learnRecipeFailedAlreadyKnown$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(LearnRecipeActions.learnRecipeFailedAlreadyKnown),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Vous connaissez déjà cette recette`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _learnRecipeFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(LearnRecipeActions.learnRecipeFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _learnRecipeFailedMissingJob$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(LearnRecipeActions.learnRecipeFailedMissingJob),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Vous n'avez pas le métier correspondant!`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _learnRecipeFailedNotEnoughXp$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(LearnRecipeActions.learnRecipeFailedNotEnoughXp),
        tap(({ playerJobXp, requiredXp }) => {
          this._notificationService.addNotification(
            'error',
            `Expérience du métier trop basse! ${playerJobXp}<${requiredXp}XP`,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _recipeDb: RecipeDbService,
    private readonly _store: Store,
  ) {}
}
