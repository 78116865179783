import { Item } from '@t12/common/item/interfaces/item.interface';

export const INVENTORY_FEATURE_KEY = 'inventory';

export type InventoryState = {
  indexInventory?: number;
  items?: Item[];
};

export const inventoryState: InventoryState = {
  indexInventory: -1,
  items: [],
};
