import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Item } from '@t12/common/item/interfaces/item.interface';

export const EquipmentsActions = createActionGroup({
  source: 'EQUIPMENTS',
  events: {
    'Equip item': props<{ item: Item; oldEquipment?: Item }>(),
    'Equip item success': props<{ item: Item; oldEquipment?: Item }>(),
    'Equip item failed already have': emptyProps(),
    'Equip item failed': emptyProps(),

    'Un equip item': props<{ item: Item }>(),
    'Un equip item success': props<{ item: Item }>(),
    'Un equip item failed no place': emptyProps(),
    'Un equip item failed': emptyProps(),
  },
});
