import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { getSellPriceConstant } from '@t12/common/shop/constants/get-sell-price.constant';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getPlayerItemInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { ShopDbService } from '@t12/shop/services/shop-db/shop-db.service';
import { ShopActions } from '@t12/shop/store/actions/shop/shop.actions';
import {
  switchMap,
  map,
  take,
  tap,
  catchError,
  of,
  throttleTime,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class ShopSellEffects {
  private _sellItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.sellItem),
      throttleTime(200),
      switchMap(({ itemCode, amount }) =>
        this._store.select(getPlayerItemInventory(itemCode)).pipe(
          take(1),
          map((item) => {
            if (!item || item.amount < amount)
              return ShopActions.sellItemFailedNotEnoughItem();
            else
              return ShopActions.sellItemSuccess({
                item,
                amount,
              });
          }),
        ),
      ),
    ),
  );

  private _sellItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.sellItemSuccess),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ item, amount }, playerId]) =>
        this._shopDbService.sellItem(playerId, item.code, amount).pipe(
          take(1),
          tap(() => {
            this._audioService.playSound('miscs', 'gold');
            this._notificationService.addNotification(
              'item',
              `-${amount} ${item.name} -> +${getSellPriceConstant(item.price) * amount} OR`,
              5000,
              item.img,
            );
          }),
          switchMap(() => [
            InventoryActions.removeItemInInventory({
              itemCode: item.code,
              amount,
            }),
            LocalPlayerActions.addGold({
              gold: getSellPriceConstant(item.price) * amount,
            }),
          ]),
          catchError(() => of(ShopActions.sellItemFailed())),
        ),
      ),
    ),
  );

  private _sellItemFailedNotEnoughItem$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.sellItemFailedNotEnoughItem),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez d'exemplaire de l'objet",
          ),
        ),
      ),
    { dispatch: false },
  );

  private _sellItemFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.sellItemFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _shopDbService: ShopDbService,
    private readonly _store: Store,
  ) {}
}
