export enum ChatLogKind {
  Unknown = 'unknown',
  Local = 'local',
  Yell = 'yell',
  Roll = 'roll',
  Me = 'me',
  Global = 'global',
  Pm = 'pm',
  Party = 'party',
  Log = 'log',
  Bonus = 'bonus',
  Malus = 'malus',
}
