import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { ILootItemDto } from '@t12/common/container/interfaces/loot-dto.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContainerDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  getContainer(
    containerKind: ContainerKind,
    playerId: number,
  ): Observable<IContainer> {
    return this._http.get<IContainer>(
      `${environment(`container/${containerKind}/${playerId}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  pickContainerItem(
    itemCode: string,
    playerId: number,
    containerKind: ContainerKind,
  ): Observable<Item> {
    const pickItemDto: ILootItemDto = {
      playerId,
      itemCode,
      containerKind,
    };

    return this._http.patch<Item>(
      `${environment(`container/pick`).uri}`,
      pickItemDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }
}
