import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { DEFAULT_HEIGHT_FLAG } from '@t12/ui/constants/default-height-flag.constant';
import { UserActions } from '@t12/user/store/actions/user.actions';
import { getUserLanguage } from '@t12/user/store/selectors/user.selectors';
import { UnSubscriberComponent } from '@t12/utils/component/unsubscriber.component';

@Component({
  selector: 'app-language-select',
  imports: [AsyncPipe],
  templateUrl: './language-select.component.html',
  styleUrl: './language-select.component.less',
})
export class LanguageSelectComponent extends UnSubscriberComponent {
  @Input() height: number = DEFAULT_HEIGHT_FLAG;
  @Input() update: boolean = false;

  public activeLang = this._store.select(getUserLanguage);

  constructor(private readonly _store: Store) {
    super();
  }

  public toggleLang() {
    this._store.dispatch(UserActions.toggleNextLanguage());
  }
}
