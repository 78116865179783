import { createReducer, on } from '@ngrx/store';
import { DialogActions } from '../actions/dialog.actions';
import { DialogState, initialDialogState } from '../index';

export const DialogReducer = createReducer(
  initialDialogState,

  on(
    DialogActions.startConversationSuccess,
    (dialogState: DialogState, { textsDialog, npc }) => ({
      ...dialogState,
      texts: textsDialog,
      character: npc || undefined,
      index: 0,
    }),
  ),

  on(DialogActions.nextMessage, (dialogState: DialogState) => ({
    ...dialogState,
    index: dialogState.index + 1,
  })),

  on(
    DialogActions.nextMessageOption,
    (dialogState: DialogState, { option }) => ({
      ...dialogState,
      texts: [
        {
          text: option.nextTextDialog,
        },
      ],
      index: 0,
    }),
  ),

  on(
    DialogActions.setOptionValidateQuestDisable,
    (dialogState: DialogState, { disable }) => ({
      ...dialogState,
      texts: dialogState.texts.map((text, index) =>
        index === dialogState.index
          ? {
              ...text,
              options: text.options.map((option) =>
                option.validateQuest ? { ...option, disable } : option,
              ),
            }
          : text,
      ),
    }),
  ),

  on(DialogActions.endConversationSuccess, (_: DialogState) => ({
    character: undefined,
    texts: [],
    index: 0,
  })),
);
