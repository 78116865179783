import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { getPlayerName } from '@t12/characters/store/selectors/characters.selectors';
import { ChatManagerService } from '@t12/chat/services/chat-manager/chat-manager.service';
import { ChatCommandActions } from '@t12/chat/store/actions/chat-commands/chat-commands.actions';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { getScopeChat } from '@t12/chat/store/selectors/chat.selectors';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { getHudChat } from '@t12/overlay/store/selectors/hud-display/hud-display.selectors';
import { SocketService } from '@t12/sockets/services/socket.service';
import { getChatPreviewEnabled } from '@t12/user/store/selectors/user.selectors';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs';

@Injectable()
export class ChatEffects {
  private _addMessage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatActions.addMessage),
      withLatestFrom(
        this._store.select(getPlayerName),
        this._store.select(getScopeChat),
      ),
      map(([{ text }, name, scope]) => {
        if (!text) return ChatActions.addMessageFailedEmpty();
        if (text.length < 2) return ChatActions.addMessageFailedTooShort();

        const kind = this._chatService.getChatLogKindCmd(text, scope);
        if (kind === ChatLogKind.Unknown)
          return ChatActions.addMessageFailedUnknownCommand();

        const scopeChat = text.startsWith('/')
          ? this._chatService.getScopeChatByKind(kind)
          : scope;

        let chatLog: ChatLog = this._chatService.createChatLog(
          name,
          scopeChat,
          text,
          kind,
          ChatTab.CHAT,
          true,
        );

        return ChatCommandActions.canDispatchChatLog({ chatLog });
      }),
    ),
  );

  private _addMessageFailedUnknownCommand$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ChatActions.addMessageFailedUnknownCommand),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Cette commande n'existe pas.",
          ),
        ),
      ),
    { dispatch: false },
  );

  private _addMessageFailedEmpty$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ChatActions.addMessageFailedEmpty),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Merci de saisir du texte.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _addMessageFailedTooShort$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ChatActions.addMessageFailedTooShort),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Message trop court.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _addChatLog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatActions.addChatLog),
      map(({ name, text, kind, tab }) => {
        const scope = this._chatService.getScopeChatByKind(kind);

        const chatLog = this._chatService.createChatLog(
          name,
          scope,
          text,
          kind,
          tab,
        );

        return ChatCommandActions.formatChatLog({ chatLog });
      }),
    ),
  );

  private _addChatLogSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ChatActions.addChatLogSuccess),
        filter(
          ({ chatLog }) =>
            chatLog.emit &&
            chatLog.kind !== ChatLogKind.Log &&
            chatLog.kind !== ChatLogKind.Bonus &&
            chatLog.kind !== ChatLogKind.Malus,
        ),
        tap(({ chatLog }) => {
          this._socketService.emit('chat', chatLog);
        }),
      ),
    { dispatch: false },
  );

  private _receiveChatLog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatActions.receiveChatLog),
      withLatestFrom(
        this._store.select(getHudChat),
        this._store.select(getChatPreviewEnabled),
      ),
      switchMap(([{ chatLog }, hudChat, chatPreviewEnabled]) => {
        const actions: Action[] = [];

        actions.push(ChatCommandActions.formatChatLog({ chatLog }));

        if (!hudChat && !chatPreviewEnabled) {
          actions.push(ChatActions.incNewMessage());
        }

        if (
          chatLog.kind !== ChatLogKind.Log &&
          !hudChat &&
          chatPreviewEnabled
        ) {
          actions.push(ChatActions.resetNewMessage());
          actions.push(HudDisplayActions.showHud({ name: 'chatPreview' }));
        }

        return actions;
      }),
    ),
  );

  private _deleteActiveLogs$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ChatActions.deleteActiveLogs),
        tap(() => {
          this._notificationService.addNotification(
            'settings',
            'Historique du chat actif effacé.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _explainChat$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChatActions.explainChat),
      switchMap(({ lastConnexionDate }) => {
        const formattedDate = `Dernière connexion à ${lastConnexionDate.format('HH:mm')}, le ${lastConnexionDate.format('DD MMMM YYYY')}.`;

        return [
          ChatActions.addChatLog({
            tab: ChatTab.CHAT,
            name: '',
            text: formattedDate,
            kind: ChatLogKind.Log,
          }),
          ChatActions.addChatLog({
            tab: ChatTab.CHAT,
            name: '',
            text: '/say ou /s pour parler en local',
            kind: ChatLogKind.Log,
          }),
          ChatActions.addChatLog({
            tab: ChatTab.CHAT,
            name: '',
            text: '/all ou /a pour parler en global',
            kind: ChatLogKind.Log,
          }),
        ];
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _chatService: ChatManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socketService: SocketService,
    private readonly _store: Store,
  ) {}
}
