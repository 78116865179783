import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';

export const CHAT_FEATURE_KEY = 'chat';

export interface ChatState {
  action: ChatLog[];
  activeTab: ChatTab;
  newMessages: number;
  pmPlayerName?: string;
  chat: ChatLog[];
  fight: ChatLog[];
  scopeChat: ChatLogScope;
}

export const initialChatState: ChatState = {
  newMessages: 0,
  chat: [],
  fight: [],
  action: [],
  activeTab: ChatTab.CHAT,
  scopeChat: ChatLogScope.Local,
};
