import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { EquipmentSlotIndex } from '@t12/common/equipments/enums/equipment-slot-index.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { BackgroundSlotItem } from '@t12/common/item/types/background-slot-item.type';
import { SvgGeneratorService } from '../../services/svg-generator/svg-generator.service';

@Component({
  selector: 'app-slot-item[item]',
  templateUrl: './slot-item.component.html',
  styleUrls: ['./slot-item.component.less'],
  imports: [NgClass, NgIf],
})
export class SlotItemComponent implements OnInit {
  @Input() item: Item;
  @Input() active: boolean;
  @Input() amount: number = 0;
  @Input() interaction: boolean = false;
  @Input() equipmentSlot: EquipmentSlotIndex | 'item-bag';
  public backgroundsIconSlot: { [key in BackgroundSlotItem]: SafeHtml };

  constructor(public svgService: SvgGeneratorService) {}

  ngOnInit() {
    this.backgroundsIconSlot = this.svgService.generateIconSlotItem();
  }
}
