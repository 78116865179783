import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';
import { PickItemDto } from '../../../../../../back/src/inventory/dto/pick-item.dto';
import { UseItemDto } from '../../../../../../back/src/inventory/dto/use-item.dto';

@Injectable({
  providedIn: 'root',
})
export class InventoryDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  // Arguments : Item à utiliser
  // Résultat : Permet d'acheter un objet à un PNJ
  public useItem(playerId: number, itemCode: string): Observable<void> {
    const useItemDto: UseItemDto = {
      playerId,
      itemCode,
    };

    return this._http.patch<void>(
      `${environment(`inventory/use/item`).uri}`,
      useItemDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  public dropItem(
    playerId: number,
    itemCode: string,
    amount: number,
  ): Observable<void> {
    const pickItemDto: PickItemDto = {
      playerId,
      itemCode,
      amount,
    };

    return this._http.patch<void>(
      `${environment(`inventory/drop/item`).uri}`,
      pickItemDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  public pickItem(
    playerId: number,
    itemCode: string,
    amount: number,
  ): Observable<void> {
    const pickItemDto: PickItemDto = {
      playerId,
      itemCode,
      amount,
    };

    return this._http.patch<void>(
      `${environment(`inventory/pick/item`).uri}`,
      pickItemDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }
}
