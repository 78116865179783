import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultHarvest } from '@t12/common/job/interfaces/result-harvest.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HarvestDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  public getHarvestResources(playerId: number): Observable<ResultHarvest> {
    return this._http.get(`${environment(`harvest/${playerId}`).uri}`, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<ResultHarvest>;
  }
}
