import { HarvestEffects } from '@t12/jobs/store/effects/harvest/harvest.effects';
import { JobsEffects } from '@t12/jobs/store/effects/job/jobs.effects';
import { KnowledgeEffects } from '@t12/jobs/store/effects/knowledge/knowledge.effects';
import { CraftRecipeEffects } from '@t12/jobs/store/effects/recipe/craft/craft-recipe.effects';
import { LearnRecipeEffects } from '@t12/jobs/store/effects/recipe/learn/learn-recipe-effects';
import { ListRecipesEffects } from '@t12/jobs/store/effects/recipe/list/list-recipes.effects';
import { WorkshopEffects } from '@t12/jobs/store/effects/workshop/workshop.effects';

export const JobCombinedEffects = [
  CraftRecipeEffects,
  HarvestEffects,
  JobsEffects,
  KnowledgeEffects,
  ListRecipesEffects,
  LearnRecipeEffects,
  WorkshopEffects,
];
