import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { HotkeyActions } from '@t12/key-commands/store/hotkey/actions/hotkey.actions';
import { getHotkeys } from '@t12/key-commands/store/hotkey/selectors/hotkey.selectors';
import { UserActions } from '@t12/user/store/actions/user.actions';
import { LocalService } from '@t12/utils/services/local/local.service';
import { tap, withLatestFrom, map, filter } from 'rxjs';

@Injectable()
export class HotkeyEffects {
  private _loadHotkeys$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HotkeyActions.loadHotkeys),
      withLatestFrom(this._store.select(getPlayerID)),
      map(([_, playerId]) => ({
        hotkeys: this._localService.getJsonValue(`hotkeys${playerId}`),
      })),
      filter(({ hotkeys }) => !!hotkeys),
      map(({ hotkeys }) => HotkeyActions.setHotkeys({ hotkeys })),
    ),
  );

  private _setHotkeysStorage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          HotkeyActions.addHotkey,
          HotkeyActions.setHotkeyAt,
          HotkeyActions.removeHotkey,
        ),
        withLatestFrom(
          this._store.select(getPlayerID),
          this._store.select(getHotkeys),
        ),
        tap(([_, playerId, hotkeys]) => {
          if (hotkeys.length === 0)
            this._localService.removeItem(`hotkeys${playerId}`);
          else
            this._localService.setJsonValue(
              `hotkeys${playerId}`,
              JSON.stringify(hotkeys),
            );
        }),
      ),
    { dispatch: false },
  );

  private _deleteHotkeysAtLogout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(HotkeyActions.deleteHotkeys, UserActions.logout),
        withLatestFrom(this._store.select(getPlayerID)),
        tap(([_, playerId]) => {
          this._localService.removeItem(`hotkeys${playerId}`);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _localService: LocalService,
    private readonly _store: Store,
  ) {}
}
