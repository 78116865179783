import { createReducer, on } from '@ngrx/store';
import { maxNewMessageNotification } from '@t12/chat/constants/max-new-message-notification.constant';
import { ChatState, initialChatState } from '@t12/chat/store';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { ChatActions } from '../actions/chat/chat.actions';

export const ChatReducer = createReducer(
  initialChatState,

  on(ChatActions.setTabChat, (chatState: ChatState, { tab }) => ({
    ...chatState,
    activeTab: tab,
  })),

  on(ChatActions.setScopeChat, (chatState: ChatState, { scopeChat }) => ({
    ...chatState,
    scopeChat,
  })),

  on(ChatActions.addChatLogSuccess, (chatState: ChatState, { chatLog }) => ({
    ...chatState,
    [chatLog.tab]: [...chatState[chatLog.tab], chatLog],
  })),

  on(ChatActions.incNewMessage, (chatState: ChatState) => ({
    ...chatState,
    newMessages: Math.min(chatState.newMessages + 1, maxNewMessageNotification),
  })),

  on(ChatActions.resetNewMessage, (chatState: ChatState) => ({
    ...chatState,
    newMessages: 0,
  })),

  on(ChatActions.deleteActiveLogs, (chatState: ChatState) => ({
    ...chatState,
    [chatState.activeTab]: [],
  })),

  on(ChatActions.setPmPlayer, (chatState: ChatState, { name }) => ({
    ...chatState,
    pmPlayerName: name,
    scopeChat: ChatLogScope.Pm,
    activeTab: ChatTab.CHAT,
  })),

  on(ChatActions.removePmPlayer, (chatState: ChatState) => ({
    ...chatState,
    pmPlayerName: undefined,
    scopeChat: ChatLogScope.Local,
  })),
);
