import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerJobs } from '@t12/characters/store/selectors/characters.selectors';
import { JobKind } from '@t12/common/job/enums/job-kind.enum';
import { RecipeLearn } from '@t12/common/job/interfaces/learn-knowlegde.interface';
import { maxCraftsmanship } from '@t12/common/job/max-craftmanship.constant';
import { getKindJobByCode } from '@t12/jobs/constants/get-job-kind-by-code.constant';
import { isRecipe } from '@t12/jobs/constants/is-recipe.constant';
import { isWisdomJob } from '@t12/jobs/constants/is-wisdom-job.constant';
import { JobActions } from '@t12/jobs/store/actions/job/job.actions';
import { KnowledgeActions } from '@t12/jobs/store/actions/knowledge/knowledge.actions';
import { LearnRecipeActions } from '@t12/jobs/store/actions/recipe/learn/learn-recipe.actions';
import { map, filter, withLatestFrom } from 'rxjs';

@Injectable()
export class KnowledgeEffects {
  private _learnKnowledge$ = createEffect(() =>
    this._actions$.pipe(
      ofType(KnowledgeActions.learnKnowledge),
      filter(
        ({ item }) => isWisdomJob(item.use?.learn) || isRecipe(item.use?.learn),
      ),
      map(({ item }) => {
        const {
          code: itemCode,
          use: { learn },
        } = item;

        if (isWisdomJob(learn))
          return KnowledgeActions.learnKnowledgeJob({
            itemCode,
            learn,
          });

        if (isRecipe(learn)) {
          return KnowledgeActions.learnKnowledgeRecipe({
            item,
          });
        }
      }),
    ),
  );

  private _learnKnowledgeJob$ = createEffect(() =>
    this._actions$.pipe(
      ofType(KnowledgeActions.learnKnowledgeJob),
      withLatestFrom(this._store.select(getPlayerJobs)),
      map(([{ itemCode, learn }, playerJobs]) => {
        const hasJob = playerJobs[JobKind.Craftsmanship].some(
          (job) => job.code === learn.code,
        );

        if (hasJob) return JobActions.learnJobFailedAlreadyKnow();

        if (
          learn.kind === JobKind.Craftsmanship &&
          playerJobs[JobKind.Craftsmanship].length >= maxCraftsmanship
        )
          return JobActions.learnJobFailedReachedMax();

        return JobActions.learnJob({ itemCode });
      }),
    ),
  );

  private _learnKnowledgeRecipe$ = createEffect(() =>
    this._actions$.pipe(
      ofType(KnowledgeActions.learnKnowledgeRecipe),
      withLatestFrom(this._store.select(getPlayerJobs)),
      map(([{ item }, playerJobs]) => {
        const learn = item.use.learn as RecipeLearn;
        const kindJob = getKindJobByCode(learn.jobCode);
        const playerJob = playerJobs[kindJob]?.find(
          (job) => job.code === learn.jobCode,
        );

        if (!playerJob) return LearnRecipeActions.learnRecipeFailedMissingJob();

        if (playerJob.xp < learn.requiredXp)
          return LearnRecipeActions.learnRecipeFailedNotEnoughXp({
            playerJobXp: playerJob.xp,
            requiredXp: learn.requiredXp,
          });

        return LearnRecipeActions.learnRecipe({ item });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
