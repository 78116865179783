import { createActionGroup, props } from '@ngrx/store';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { RewardFight } from '@t12/common/fight/interfaces/reward-fight.interface';

export const CharactersFightActions = createActionGroup({
  source: 'CHARACTER FIGHT',
  events: {
    'Reward fight': props<{
      target: { kind: CharacterKind; code: string };
      reward?: RewardFight;
    }>(),
    'Reward xp': props<{ xp: number; notification?: boolean }>(),
    'Reward loot': props<{ container: IContainer }>(),
  },
});
