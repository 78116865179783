import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EquipmentSlot } from '@t12/common/equipments/types/equipment-slot.type';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { Shop } from '@t12/common/shop/interfaces/shop.interface';

export const ShopActions = createActionGroup({
  source: 'SHOP',
  events: {
    'Open shop': props<{ npcCode: string }>(),
    'Open shop success': props<{ shops: Shop }>(),
    'Open shop failed': emptyProps(),

    'Remove item from shop': props<{ item: Item }>(),
    'Set shop': props<{ shop: Shop }>(),

    'Set active item': props<{ item: Item }>(),
    'Update active item amount ': props<{ amount: number }>(),
    'Reset active item': emptyProps(),
    'Inc current page': props<{ inc: 1 | -1 }>(),
    'Set page': props<{ page: number }>(),
    'Set filter': props<{ filter: EquipmentSlot | ItemType }>(),
    'Update items displayed ': emptyProps(),

    'Buy item': props<{ amount: number }>(),
    'Buy item success': props<{ item: Item; amount: number }>(),
    'Buy item failed': emptyProps(),
    'Buy item failed shop wrong item': emptyProps(),
    'Buy item failed not enough space': emptyProps(),
    'Buy item failed not enough gold': props<{ amountMissing: number }>(),

    'Sell item': props<{ itemCode: string; amount: number }>(),
    'Sell item success': props<{ item: Item; amount: number }>(),
    'Sell item failed': emptyProps(),
    'Sell item failed not enough item': emptyProps(),

    'Close shop': emptyProps(),
  },
});
