import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNPCOrMonster } from '@t12/characters/constants/is-npc-or-monster.constant';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { getQuestsInfos } from '@t12/quest/store/selectors/quest.selectors';
import { filter, map, withLatestFrom } from 'rxjs';

@Injectable()
export class CharacterEffects {
  private _setCharacters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.setCharacters),
      withLatestFrom(this._store.select(getQuestsInfos)),
      filter(([_, questsInfos]) => !!questsInfos),
      map(([{ characters }, questsInfos]) => {
        const charactersCode = characters
          .filter(isNPCOrMonster)
          .map((character) => character.code);

        const questsCodes = questsInfos
          .filter(({ goals }) =>
            goals.some(
              (goal) =>
                goal.kind === 'exploration' &&
                goal.amount < goal.amountTotal &&
                charactersCode.includes(goal.entityCode),
            ),
          )
          .map(({ code }) => code);

        return questsCodes.length
          ? QuestActions.updateGoals({
              questsCodes,
              goalKind: 'exploration',
              amount: 1,
            })
          : null;
      }),
      filter(Boolean),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
