import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { getPmPlayerName } from '@t12/chat/store/selectors/chat.selectors';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { SocialsFriendsActions } from '@t12/socials/store/actions/friends/socials-friends.actions';
import {
  catchError,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
  filter,
} from 'rxjs';
import { SocialsDbService } from '../../../services/socials-db/socials-db.service';

@Injectable()
export class FriendsEffects {
  private _getFriends$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsFriendsActions.getFriends),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, playerId]) =>
        this._socialsDbService.getPlayerFriends(playerId).pipe(
          map((friends) => SocialsFriendsActions.setFriends({ friends })),
          catchError(() => of(SocialsFriendsActions.getFriendsFailed())),
        ),
      ),
    ),
  );

  private _getFriendsFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsFriendsActions.getFriendsFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Impossible de récupérer la liste de vos amis.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _updateDisplayedFriends$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        SocialsFriendsActions.setFriends,
        SocialsFriendsActions.incFriendsPage,
        SocialsFriendsActions.addFriend,
        SocialsFriendsActions.deleteFriendSuccess,
      ),
      map(() => SocialsFriendsActions.updateDisplayedFriends()),
    ),
  );

  private _addFriend$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsFriendsActions.addFriend),
        tap(({ playerFriend: { name } }) => {
          this._notificationService.addNotification(
            'settings',
            `Demande de ${name} acceptée`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _deleteFriend$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsFriendsActions.deleteFriend),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name, notification }, playerId]) =>
        this._socialsDbService.deletePlayerFriend(playerId, name).pipe(
          map(() =>
            SocialsFriendsActions.deleteFriendSuccess({ name, notification }),
          ),
          catchError(() => of(SocialsFriendsActions.deleteFriendFailed())),
        ),
      ),
    ),
  );

  private _deleteFriendSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsFriendsActions.deleteFriendSuccess),
      tap(({ name, notification }) => {
        if (notification)
          this._notificationService.addNotification(
            'error',
            `'${name}' a été retiré de vos amis`,
          );
      }),
      withLatestFrom(this._store.select(getPmPlayerName)),
      filter(([{ name }, pmPlayerName]) => name === pmPlayerName),
      map(() => ChatActions.removePmPlayer()),
    ),
  );

  private _deleteFriendFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsFriendsActions.deleteFriendFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur',
          );
        }),
      ),
    { dispatch: false },
  );

  private _friendLoggedOut$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsFriendsActions.friendLoggedOut),
      map(({ name }) =>
        SocialsFriendsActions.setFriendOnlineStatus({
          name,
          online: false,
        }),
      ),
    ),
  );

  private _friendLoggedIn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsFriendsActions.friendLoggedIn),
      switchMap(({ player }) => {
        return [
          ChatActions.addChatLog({
            tab: ChatTab.CHAT,
            name: '',
            text: `${player.name} vient de se connecter! (Lvl ${player.lvl})`,
            kind: ChatLogKind.Log,
          }),
          SocialsFriendsActions.setFriendOnlineStatus({
            name: player.name,
            online: true,
          }),
        ];
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socialsDbService: SocialsDbService,
    private readonly _store: Store,
  ) {}
}
