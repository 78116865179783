import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { getHudSettings } from '@t12/overlay/store/selectors/hud-display/hud-display.selectors';
import { SettingsComponent } from '@t12/settings/components/settings/settings.component';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { UserActions } from '@t12/user/store/actions/user.actions';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [NgIf, RouterOutlet, AsyncPipe, SettingsComponent],
})
export class AppComponent implements OnInit, AfterViewInit {
  public readonly title = 'La tour des 12';
  public isVisibleSettings: Observable<boolean> =
    this._store.select(getHudSettings);

  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _store: Store,
  ) {}

  ngOnInit(): void {
    const userDetails = this._authService.getUserDetails();
    if (userDetails) {
      this._store.dispatch(UserActions.loadUser({ user: userDetails }));
    }
  }

  ngAfterViewInit(): void {
    const widthScreen = window.innerWidth;
    this._store.dispatch(UserActions.setDevice({ widthScreen }));
  }
}
