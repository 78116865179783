import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerName } from '@t12/characters/store/selectors/characters.selectors';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { attackLabel } from '@t12/fight/constants/attack-label.constant';
import { hitLabel } from '@t12/fight/constants/hit-label.constant';
import { FightActions } from '@t12/fight/store/actions/fight.actions';
import { map, filter, withLatestFrom } from 'rxjs';

@Injectable()
export class FightEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}

  private _displayFightLog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FightActions.displayFightLog),
      filter(
        ({ attackResult: { attacker, target } }) => !!attacker && !!target,
      ),
      map(({ attackResult: { attacker, target, hit } }) => {
        const attackerName = attacker.name;
        const targetName = target.name;

        switch (hit.kind) {
          case 'hit': {
            return FightActions.displayFightLogHit({
              attackerName,
              hit: { damage: hit.damage, kind: hit.attackKind },
              targetName,
            });
          }
          case 'miss': {
            return FightActions.displayFightLogMiss({
              attackerName,
              targetName,
            });
          }
          case 'avoid': {
            return FightActions.displayFightLogAvoid({
              attackerName,
              targetName,
            });
          }
          case 'backstab': {
            return FightActions.displayFightLogBackStab({
              attackerName,
              hit: { damage: hit.damage, kind: hit.attackKind },
              targetName,
            });
          }
          case 'crit': {
            return FightActions.displayFightLogCritical({
              attackerName,
              hit: {
                damage: hit.damage,
                kind: hit.attackKind,
                reduceDamage: hit.annexDamage,
              },
              targetName,
            });
          }
          case 'parry': {
            return FightActions.displayFightLogParry({
              attackerName,
              hit: {
                damage: hit.damage,
                kind: hit.attackKind,
                reduceDamage: hit.annexDamage,
              },
              targetName,
            });
          }
          case 'block': {
            return FightActions.displayFightLogBlock({
              attackerName,
              hit: {
                damage: hit.damage,
                kind: hit.attackKind,
                reduceDamage: hit.annexDamage,
              },
              targetName,
            });
          }
          default:
            return FightActions.displayFightLogFailed();
        }
      }),
    ),
  );

  private _displayFightLogHit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FightActions.displayFightLogHit),
      withLatestFrom(this._store.select(getPlayerName)),
      map(([{ attackerName, hit, targetName }, localPlayerName]) => {
        return ChatActions.addChatLog({
          tab: ChatTab.FIGHT,
          name: attackerName,
          text: `a infligé ${hit.damage} dégâts (${attackLabel[hit.kind]}) à ${targetName}.`,
          kind:
            attackerName === localPlayerName
              ? ChatLogKind.Bonus
              : ChatLogKind.Malus,
        });
      }),
    ),
  );

  private _displayFightLogMiss$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FightActions.displayFightLogMiss),
      withLatestFrom(this._store.select(getPlayerName)),
      map(([{ attackerName, targetName }, localPlayerName]) => {
        return ChatActions.addChatLog({
          tab: ChatTab.FIGHT,
          name: attackerName,
          text: `a raté ${targetName}.`,
          kind:
            attackerName === localPlayerName
              ? ChatLogKind.Malus
              : ChatLogKind.Bonus,
        });
      }),
    ),
  );

  private _displayFightLogAvoid$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FightActions.displayFightLogAvoid),
      withLatestFrom(this._store.select(getPlayerName)),
      map(([{ attackerName, targetName }, localPlayerName]) => {
        return ChatActions.addChatLog({
          tab: ChatTab.FIGHT,
          name: targetName,
          text: `a esquivé le coup de ${attackerName}.`,
          kind:
            attackerName === localPlayerName
              ? ChatLogKind.Malus
              : ChatLogKind.Bonus,
        });
      }),
    ),
  );

  private _displayFightLogBackStab$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FightActions.displayFightLogBackStab),
      withLatestFrom(this._store.select(getPlayerName)),
      map(([{ attackerName, hit, targetName }, localPlayerName]) => {
        return ChatActions.addChatLog({
          tab: ChatTab.FIGHT,
          name: attackerName,
          text: `a infligé ${hit.damage} dégâts (${attackLabel[hit.kind]}) à ${targetName} (${hitLabel['backstab']}).`,
          kind:
            attackerName === localPlayerName
              ? ChatLogKind.Bonus
              : ChatLogKind.Malus,
        });
      }),
    ),
  );

  private _displayFightLogCritical$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FightActions.displayFightLogCritical),
      withLatestFrom(this._store.select(getPlayerName)),
      map(([{ attackerName, hit, targetName }, localPlayerName]) => {
        const labelDmg = `a infligé ${hit.damage} dégâts (${attackLabel[hit.kind]}) à ${targetName}`;

        return ChatActions.addChatLog({
          tab: ChatTab.FIGHT,
          name: attackerName,
          text: `${labelDmg} (${hitLabel['crit']}: +${hit.reduceDamage} dégâts).`,
          kind:
            attackerName === localPlayerName
              ? ChatLogKind.Bonus
              : ChatLogKind.Malus,
        });
      }),
    ),
  );

  private _displayFightLogParry$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FightActions.displayFightLogParry),
      withLatestFrom(this._store.select(getPlayerName)),
      map(([{ attackerName, hit, targetName }, localPlayerName]) => {
        const labelDmg = `a infligé ${hit.damage} dégâts (${attackLabel[hit.kind]}) à ${targetName}`;

        return ChatActions.addChatLog({
          tab: ChatTab.FIGHT,
          name: attackerName,
          text: `${labelDmg} (${hitLabel['parry']}: ${hit.reduceDamage} dégâts).`,
          kind:
            attackerName === localPlayerName
              ? ChatLogKind.Bonus
              : ChatLogKind.Malus,
        });
      }),
    ),
  );

  private _displayFightLogBlock$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FightActions.displayFightLogBlock),
      withLatestFrom(this._store.select(getPlayerName)),
      map(([{ attackerName, hit, targetName }, localPlayerName]) => {
        const labelDmg = `a infligé ${hit.damage} dégâts (${attackLabel[hit.kind]}) à ${targetName}`;

        return ChatActions.addChatLog({
          tab: ChatTab.FIGHT,
          name: attackerName,
          text: `${labelDmg} (${hitLabel['block']}: ${hit.reduceDamage} dégâts).`,
          kind:
            attackerName === localPlayerName
              ? ChatLogKind.Bonus
              : ChatLogKind.Malus,
        });
      }),
    ),
  );
}
