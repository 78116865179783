import { InventoryDropEffects } from '@t12/inventory/store/effects/inventory-drop/inventory-drop.effects';
import { InventoryFavoriteEffects } from '@t12/inventory/store/effects/inventory-favorite/inventory-favorite.effects';
import { InventoryPickEffects } from '@t12/inventory/store/effects/inventory-pick/inventory-pick.effects';
import { InventoryUseEffects } from '@t12/inventory/store/effects/inventory-use/inventory-use.effects';
import { InventoryEffects } from '@t12/inventory/store/effects/inventory/inventory.effects';

export const InventoryCombinedEffects = [
  InventoryEffects,
  InventoryPickEffects,
  InventoryDropEffects,
  InventoryUseEffects,
  InventoryFavoriteEffects,
];
