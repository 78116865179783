import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserDevice } from '@t12/user/enums/user-device.enum';
import { UserState } from '../index';

const selectRoot = createFeatureSelector<UserState>('user');

export const getUser = createSelector(selectRoot, (user: UserState) => user);

export const getUserMail = createSelector(selectRoot, ({ email }) => email);

export const getUserSettings = createSelector(
  selectRoot,
  ({ settings }) => settings,
);

export const getChatPreviewEnabled = createSelector(
  selectRoot,
  ({ settings }) => !!settings.chatPreview,
);

export const getUserLanguage = createSelector(
  selectRoot,
  ({ language }) => language,
);

export const isUserDeviceMobile = createSelector(
  selectRoot,
  ({ userDevice }) =>
    userDevice === UserDevice.MOBILE || userDevice === UserDevice.TABLET,
);

export const getUserLoadingStatus = createSelector(
  selectRoot,
  ({ loadingStatus }) => loadingStatus,
);

export const getUserError = createSelector(selectRoot, ({ error }) => error);
