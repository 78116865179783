import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { JobCodeToNameEnum } from '@t12/jobs/enums/job-code-to-name.enum';
import { ListRecipesActions } from '@t12/jobs/store/actions/recipe/list/list-recipes.actions';
import { WorkshopActions } from '@t12/jobs/store/actions/workshop/workshop.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { map, tap, concatMap } from 'rxjs';

@Injectable()
export class WorkshopEffects {
  private _openWorkshopFailedWrongJob$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(WorkshopActions.openWorkshopFailedWrongJob),
        tap(({ jobCode }) => {
          this._notificationService.addNotification(
            'error',
            `Vous n'avez pas le métier requis! (${JobCodeToNameEnum[jobCode]})`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _openWorkshopFailedRequiredXpLow$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(WorkshopActions.openWorkshopFailedRequiredXpLow),
        tap(({ xp, requiredXp }) => {
          this._notificationService.addNotification(
            'error',
            `Expérience du métier trop bas! ${xp}<${requiredXp}XP`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _openWorkshopSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorkshopActions.openWorkshopSuccess),
      concatMap(({ workshop }) => [
        ListRecipesActions.displayedRecipes({ jobCode: workshop.jobCode }),
        HudDisplayActions.showHud({ name: 'jobs' }),
      ]),
    ),
  );

  private _closeWorkshop$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorkshopActions.closeWorkshop),
      map(() => {
        return HudDisplayActions.hideHud({ name: 'jobs' });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
  ) {}
}
