import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAddKnowledge } from '@t12/common/job/interfaces/add-job.interface';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  public addJob(playerId: number, itemCode: string): Observable<PlayerJob> {
    const addKnowledgeDto: IAddKnowledge = {
      playerId,
      itemCode,
    };

    return this._http.post(`${environment(`job/add`).uri}`, addKnowledgeDto, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<PlayerJob>;
  }

  public removeJob(playerId: number, jobCode: JobCode): Observable<PlayerJob> {
    return this._http.delete(
      `${environment(`job/remove/${jobCode}/${playerId}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<PlayerJob>;
  }
}
