import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _router: Router,
  ) {}

  // Arguments : ------
  // Résultat : Vérifie que l'utilisateur est connecté via le service d'authentification
  public canActivate(): boolean {
    const isLoggedIn = this._authService.isLoggedIn();
    if (!isLoggedIn) this._router.navigateByUrl('/');
    return isLoggedIn;
  }
}
