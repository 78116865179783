import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlayerUpdateEquipmentI } from '@t12/common/player/interfaces/player-update-equipment.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EquipmentsDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  public addEquipment(
    playerId: number,
    equipmentCode: string,
  ): Observable<void> {
    const playerUpdateEquipmentDto: PlayerUpdateEquipmentI = {
      playerId,
      equipmentCode,
    };

    return this._http.patch<void>(
      `${environment(`player/equipment/add`).uri}`,
      playerUpdateEquipmentDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  public removeEquipment(
    playerId: number,
    equipmentCode: string,
  ): Observable<void> {
    const playerUpdateEquipmentDto: PlayerUpdateEquipmentI = {
      playerId,
      equipmentCode,
    };

    return this._http.patch<void>(
      `${environment(`player/equipment/remove`).uri}`,
      playerUpdateEquipmentDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }
}
