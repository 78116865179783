import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import { getPlayerWorldCode } from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { SocialsFriendsActions } from '@t12/socials/store/actions/friends/socials-friends.actions';
import { SocialsGroupActions } from '@t12/socials/store/actions/group/socials-group.actions';
import { switchMap, take, mergeMap, withLatestFrom, combineLatest } from 'rxjs';
import { SocialsActions } from '../../actions/socials/socials.actions';
import {
  isFriend,
  isPlayerInGroupByName,
} from '../../selectors/socials.selectors';

@Injectable()
export class PlayerLoggedEffects {
  private _playerLoggedIn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.playerLoggedIn),
      withLatestFrom(this._store.select(getPlayerWorldCode)),
      switchMap(([{ player }, playerWorldCode]) =>
        combineLatest([
          this._store.select(isFriend(player.name)).pipe(take(1)),
          this._store.select(isPlayerInGroupByName(player.name)).pipe(take(1)),
        ]).pipe(
          mergeMap(([isFriend, isInGroup]) => {
            const actions = [];

            if (player.worldCode === playerWorldCode)
              actions.push(
                CharactersActions.addPlayer({
                  id: player.id,
                  position: player.position,
                }),
              );

            if (isFriend)
              actions.push(SocialsFriendsActions.friendLoggedIn({ player }));

            if (isInGroup)
              actions.push(
                SocialsGroupActions.setPlayerGroupStatusOnline({
                  name: player.name,
                  online: true,
                }),
              );

            return actions;
          }),
        ),
      ),
    ),
  );

  private _playerLoggedOut$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.playerLoggedOut),
      withLatestFrom(this._store.select(getPlayerWorldCode)),
      switchMap(([{ player }, playerWorldCode]) =>
        combineLatest([
          this._store.select(isFriend(player.name)).pipe(take(1)),
          this._store.select(isPlayerInGroupByName(player.name)).pipe(take(1)),
        ]).pipe(
          mergeMap(([isFriend, isInGroup]) => {
            const actions = [];
            if (player.worldCode === playerWorldCode) {
              actions.push(
                CharactersActions.removeCharacterById({
                  id: player.id,
                  kind: CharacterKind.PLAYER,
                }),
              );
            }

            if (isFriend) {
              actions.push(
                SocialsFriendsActions.friendLoggedOut({ name: player.name }),
              );
            }

            if (isInGroup) {
              actions.push(
                SocialsGroupActions.setPlayerGroupStatusOnline({
                  name: player.name,
                  online: false,
                }),
              );
            }

            return actions;
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
