import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { getContainerID } from '@t12/container/store/selectors/container.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import {
  catchError,
  map,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
  filter,
} from 'rxjs';

@Injectable()
export class PlayerCharacterEffects {
  private _addPlayer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.addPlayer),
      switchMap(({ id, position }) =>
        this._playerDbService.getMinimalPlayerCharacter(id).pipe(
          take(1),
          map((character) =>
            CharactersActions.addCharacter({
              character: { ...character, position },
            }),
          ),
          catchError(() => of(CharactersActions.addPlayerFailed())),
        ),
      ),
    ),
  );

  private _addPlayerFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersActions.addPlayerFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Impossible de récupérer les informations d'un joueur",
          ),
        ),
      ),
    { dispatch: false },
  );

  private _playerUseAnItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.playerUseAnItem),
      map(({ updateInfos }) => {
        const { id, health, mana } = updateInfos;

        if (!id || (!health && !mana))
          return CharactersActions.playerUseAnItemFailed();

        if (health && mana)
          return CharactersActions.addHealthMana({
            id,
            kind: CharacterKind.PLAYER,
            health,
            mana,
          });
        else if (health)
          return CharactersActions.addHealth({
            id,
            kind: CharacterKind.PLAYER,
            health,
          });
        else if (mana)
          return CharactersActions.addMana({
            id,
            kind: CharacterKind.PLAYER,
            mana,
          });
      }),
    ),
  );

  private _playerPickAnItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.playerPickAnItem),
      withLatestFrom(this._store.select(getContainerID)),
      filter(
        ([{ containerId }, containerIdActive]) =>
          containerId === containerIdActive,
      ),
      map(([{ itemCode, amount }]) =>
        ContainerActions.removeItem({ itemCode, amount }),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _store: Store,
  ) {}
}
