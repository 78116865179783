import { FriendRequestsEffects } from '@t12/socials/store/effects/friend-requests/friend-requests.effects';
import { FriendsEffects } from '@t12/socials/store/effects/friends/friends.effects';
import { GroupRequestsEffects } from '@t12/socials/store/effects/group-requests/group-requests.effects';
import { GroupEffects } from '@t12/socials/store/effects/group/group.effects';
import { PmEffects } from '@t12/socials/store/effects/pm/pm.effects';

export const SocialsCombinedEffects = [
  FriendRequestsEffects,
  FriendsEffects,
  GroupEffects,
  GroupRequestsEffects,
  PmEffects,
];
