import { createReducer, on } from '@ngrx/store';
import { inventoryMaxSize } from '@t12/common/inventory/constants/inventory-size.constant';
import { sortItemsByTypeNameAmount } from '@t12/common/inventory/functions/sort-items/sortItemsByTypeNameAmount.function';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { InventoryState, inventoryState } from '@t12/inventory/store';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';

export const InventoryReducer = createReducer(
  inventoryState,

  on(InventoryActions.setActiveItem, (inventoryState, { index }) => ({
    ...inventoryState,
    indexInventory:
      inventoryState.items[index].code &&
      inventoryState.indexInventory !== index
        ? index
        : -1,
  })),

  on(InventoryActions.resetActiveItem, (inventoryState) => ({
    ...inventoryState,
    indexInventory: -1,
  })),

  on(InventoryActions.setInventory, (inventoryState, { items }) => ({
    ...inventoryState,
    items: items,
  })),

  on(
    InventoryActions.addItemInInventory,
    (inventoryState: InventoryState, { item, amount }) => {
      let updatedInventory = [...inventoryState.items];
      let remainingAmount = amount;

      updatedInventory = updatedInventory.map((slot) => {
        if (remainingAmount > 0 && slot?.code === item.code) {
          const availableSpace = item.max - slot.amount;
          const amountToAdd = Math.min(availableSpace, remainingAmount);

          remainingAmount -= amountToAdd;

          return {
            ...slot,
            amount: slot.amount + amountToAdd,
          };
        }

        return slot;
      });

      while (remainingAmount > 0) {
        const emptySlotIndex = updatedInventory.findIndex(
          (slot) => !slot?.code,
        );

        if (emptySlotIndex === -1) break;

        const amountToAdd = Math.min(item.max, remainingAmount);
        updatedInventory[emptySlotIndex] = { ...item, amount: amountToAdd };

        remainingAmount -= amountToAdd;
      }

      return {
        ...inventoryState,
        items: updatedInventory.sort(sortItemsByTypeNameAmount),
      };
    },
  ),

  on(
    InventoryActions.addItemsInInventory,
    (inventoryState: InventoryState, { items }) => {
      let updatedInventory = [...inventoryState.items];

      items.forEach((item) => {
        let remainingAmount = item.amount;

        updatedInventory = updatedInventory.map((slot) => {
          if (remainingAmount > 0 && slot?.code === item.code) {
            const availableSpace = item.max - slot.amount;
            const amountToAdd = Math.min(availableSpace, remainingAmount);

            remainingAmount -= amountToAdd;

            return {
              ...slot,
              amount: slot.amount + amountToAdd,
            };
          }

          return slot;
        });

        while (remainingAmount > 0) {
          const emptySlotIndex = updatedInventory.findIndex(
            (slot) => !slot?.code,
          );

          if (emptySlotIndex === -1) break;

          const amountToAdd = Math.min(item.max, remainingAmount);
          updatedInventory[emptySlotIndex] = { ...item, amount: amountToAdd };

          remainingAmount -= amountToAdd;
        }
      });

      return {
        ...inventoryState,
        items: updatedInventory.sort(sortItemsByTypeNameAmount),
      };
    },
  ),

  on(
    InventoryActions.removeItemInInventory,
    (inventoryState: InventoryState, { itemCode, amount }) => {
      let updatedInventory = [...inventoryState.items].sort(
        (a, b) => a.amount - b.amount,
      );
      let remainingAmount = amount;

      updatedInventory = updatedInventory.map((slot) => {
        if (remainingAmount > 0 && slot?.code === itemCode) {
          const amountToSubtract = Math.min(slot.amount, remainingAmount);
          remainingAmount -= amountToSubtract;

          const newAmount = slot.amount - amountToSubtract;

          return {
            ...slot,
            amount: newAmount > 0 ? newAmount : 0,
          };
        }

        return slot;
      });

      updatedInventory = updatedInventory.map((slot) =>
        slot?.code === itemCode && slot.amount === 0 ? { amount: 0 } : slot,
      ) as Item[];

      return {
        ...inventoryState,
        items: updatedInventory.sort(sortItemsByTypeNameAmount),
      };
    },
  ),

  on(
    InventoryActions.removeItemsInInventory,
    (inventoryState: InventoryState, { items }) => {
      let updatedInventory = [...inventoryState.items];

      items.forEach(({ code, amount }) => {
        let remainingAmount = amount;

        updatedInventory = updatedInventory.map((slot) => {
          if (remainingAmount > 0 && slot?.code === code) {
            const amountToSubtract = Math.min(slot.amount, remainingAmount);
            remainingAmount -= amountToSubtract;

            const newAmount = slot.amount - amountToSubtract;

            return {
              ...slot,
              amount: newAmount > 0 ? newAmount : 0,
            };
          }

          return slot;
        });
      });

      updatedInventory = updatedInventory.map((slot) =>
        slot?.amount === 0 ? { amount: 0 } : slot,
      ) as Item[];

      return {
        ...inventoryState,
        items: updatedInventory.sort(sortItemsByTypeNameAmount),
      };
    },
  ),

  on(InventoryActions.orderInventory, (inventoryState: InventoryState) => {
    const updatedInventory = [...inventoryState.items];
    const itemMap = new Map<string, Item[]>();

    // Organiser les items par code et qualité
    updatedInventory.forEach((slot) => {
      if (!slot.code) return;

      const uniqueKey = `${slot.code}-${slot.quality}`;
      if (!itemMap.has(uniqueKey)) {
        itemMap.set(uniqueKey, []);
      }
      itemMap.get(uniqueKey)!.push({ ...slot });
    });

    // Fusionner les items en respectant les limites
    const sortedItems: Item[] = [];
    itemMap.forEach((slots) => {
      let remainingItems = [...slots];

      while (remainingItems.length > 0) {
        const currentItem = remainingItems.shift()!;

        // Si max === 1, chaque item reste individuel
        if (currentItem.max === 1) {
          sortedItems.push({ ...currentItem });
          continue;
        }

        // Sinon, fusionner dans des slots existants ou créer un nouveau slot
        const existingSlot = sortedItems.find(
          (item) =>
            item.code === currentItem.code &&
            item.quality === currentItem.quality &&
            item.amount < item.max,
        );

        if (existingSlot) {
          const spaceAvailable = existingSlot.max - existingSlot.amount;
          const amountToAdd = Math.min(spaceAvailable, currentItem.amount);
          existingSlot.amount += amountToAdd;
          currentItem.amount -= amountToAdd;
        }

        if (currentItem.amount > 0) {
          sortedItems.push({ ...currentItem });
        }
      }
    });

    // Compléter avec des slots vides jusqu'à la taille maximale
    while (sortedItems.length < inventoryMaxSize) {
      sortedItems.push({ amount: 0 } as Item);
    }

    return {
      ...inventoryState,
      items: sortedItems.sort(sortItemsByTypeNameAmount),
    };
  }),
);
