import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import {
  getPlayer,
  getPlayerName,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { ItemUseType } from '@t12/common/item/enums/item-use-type.enum';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';
import { getItemUseType } from '@t12/inventory/constants/get-item-use-type.constant';
import { InventoryDbService } from '@t12/inventory/services/inventory-db/inventory-db.service';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { labelInfos } from '@t12/player/constants/label-infos';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
  catchError,
  of,
  concatMap,
} from 'rxjs';

@Injectable()
export class InventoryUseEffects {
  private _useItemFailedDead$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.useItemFailedDead),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous êtes mort...',
          );
        }),
      ),
    { dispatch: false },
  );

  private _useItemFailedNotEnoughLvl$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.useItemFailedNotEnoughLvl),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas le niveau requis!",
          );
        }),
      ),
    { dispatch: false },
  );

  private _useItemFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          InventoryActions.useItemFailed,
          InventoryActions.consumeItemFailed,
        ),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _consumeItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.consumeItem),
      filter(({ item }) => !!getItemUseType(item)),
      withLatestFrom(this._store.select(getPlayer)),
      map(([{ item }, player]) => {
        const itemUseType = getItemUseType(item);
        const isFullHealth = player.health >= maxHealth(player.stats.con);
        const isFullMana = player.mana >= maxMana(player.stats.int);
        const { id } = player;
        switch (itemUseType) {
          case ItemUseType.HEALTH:
            if (isFullHealth) return InventoryActions.consumeItemHealthFailed();
            else return InventoryActions.consumeItemHealth({ item });

          case ItemUseType.MANA:
            if (isFullMana) return InventoryActions.consumeItemManaFailed();
            else return InventoryActions.consumeItemMana({ item });

          case ItemUseType.HEALTH_MANA:
            if (isFullHealth && isFullMana)
              return InventoryActions.consumeItemHealthAndManaFailed();
            else
              return InventoryActions.consumeItemHealthAndMana({
                item,
              });
        }
      }),
      filter((action) => !!action),
    ),
  );

  private _consumeItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        InventoryActions.consumeItemHealth,
        InventoryActions.consumeItemMana,
        InventoryActions.consumeItemHealthAndMana,
      ),
      withLatestFrom(
        this._store.select(getPlayerID),
        this._store.select(getPlayerName),
      ),
      switchMap(([{ item }, playerId, playerName]) =>
        this._inventoryDbService.useItem(playerId, item.code).pipe(
          take(1),
          tap(() => {
            const text = item.use.infos
              .map((useInfo) => `+${useInfo.value} ${labelInfos[useInfo.stat]}`)
              .join(' ');
            this._notificationService.addNotification(
              'item',
              text,
              3000,
              item.img,
            );
          }),
          concatMap(() => [
            InventoryActions.consumeItemSound({ item }),
            ChatActions.addChatLog({
              tab: ChatTab.ACTION,
              name: playerName,
              text: `a utilisé l'objet "${item.name}".`,
              kind: ChatLogKind.Bonus,
            }),
            InventoryActions.removeItemInInventory({
              itemCode: item.code,
              amount: 1,
            }),
          ]),
          catchError(() => of(InventoryActions.consumeItemFailed())),
        ),
      ),
    ),
  );

  private _consumeItemSound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.consumeItemSound),
        tap(({ item }) => {
          const soundMap = {
            [ItemType.Potion]: `gulp_${Math.floor(Math.random() * 2)}`,
            [ItemType.Food]: `munch_${Math.floor(Math.random() * 4)}`,
          };

          const sound = soundMap[item.type];
          if (sound) {
            this._audioService.playSound('miscs', sound, 'wav', 0.35);
          }
        }),
      ),
    { dispatch: false },
  );

  private _consumeItemHealth$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.consumeItemHealth),
      withLatestFrom(this._store.select(getPlayerID)),
      map(([{ item }, id]) => {
        return CharactersActions.addHealth({
          id,
          kind: CharacterKind.PLAYER,
          health: item.use.infos[0].value,
        });
      }),
    ),
  );

  private _consumeItemMana$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.consumeItemMana),
      withLatestFrom(this._store.select(getPlayerID)),
      map(([{ item }, id]) => {
        return CharactersActions.addMana({
          id,
          kind: CharacterKind.PLAYER,
          mana: item.use.infos[0].value,
        });
      }),
    ),
  );

  private _consumeItemHealthAndMana$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.consumeItemHealthAndMana),
      withLatestFrom(this._store.select(getPlayerID)),
      map(([{ item }, id]) => {
        return CharactersActions.addHealthMana({
          id,
          kind: CharacterKind.PLAYER,
          health: item.use.infos[0].value,
          mana: item.use.infos[1].value,
        });
      }),
    ),
  );

  private _consumeItemHealthFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.consumeItemHealthFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Votre vie est déjà au maximum.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _consumeItemManaFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.consumeItemManaFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Votre mana est déjà au maximum.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _consumeItemHealthAndManaFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.consumeItemHealthAndManaFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Votre vie et mana sont déjà au maximum!',
          );
        }),
      ),
    { dispatch: false },
  );

  private _consumeItemWarp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.consumeItemWarp),
      tap(() => {
        this._audioService.playSound('miscs', `teleport_0`, 'wav', 0.5);
      }),
      switchMap(({ itemCode }) => [
        InventoryActions.removeItemInInventory({
          itemCode,
          amount: 1,
        }),
        WorldActions.teleportTo({ scroll: itemCode }),
      ]),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _inventoryDbService: InventoryDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
