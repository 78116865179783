import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getPlayerInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { Hotkey } from '@t12/key-commands/interfaces/hotkey.interface';

const selectRoot = createFeatureSelector<Hotkey[]>('hotkeys');

export const getHotkeys = createSelector(
  selectRoot,
  getPlayerInventory,
  (hotkeys: Hotkey[], inventory) => {
    const counts = hotkeys.map(
      (hotkey) =>
        inventory
          ?.filter((item) => item.code === hotkey.entity.code)
          .reduce((total, item) => total + item.amount, 0) || 0,
    );

    return hotkeys.map((hotkey, index) => ({
      ...hotkey,
      entity: {
        ...hotkey.entity,
        amount: counts[index],
      },
    }));
  },
);

export const getFirstHotkey = createSelector(
  getHotkeys,
  (hotkeys) => hotkeys[0],
);

export const getHotkeyByItemCode = (itemCode: string) =>
  createSelector(getHotkeys, (hotkeys: Hotkey[]) =>
    hotkeys.find((hotkey) => hotkey.entity.code === itemCode),
  );
