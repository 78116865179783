import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { switchMap } from 'rxjs';
import { SocialsActions } from '../../actions/socials/socials.actions';

@Injectable()
export class PmEffects {
  private _sendPm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.sendPm),
      switchMap(({ name }) => [
        HudDisplayActions.hideHud({ name: 'socials' }),
        HudDisplayActions.showHud({ name: 'chat' }),
        ChatActions.setPmPlayer({ name }),
      ]),
    ),
  );

  constructor(private readonly _actions$: Actions) {}
}
