import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getPlayerName,
  getPlayerID,
  getPlayerPositionInFront,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { InventoryDbService } from '@t12/inventory/services/inventory-db/inventory-db.service';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  switchMap,
  take,
  tap,
  withLatestFrom,
  concatMap,
  catchError,
  of,
} from 'rxjs';

@Injectable()
export class InventoryPickEffects {
  private _pickItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.pickItemSuccess),
      withLatestFrom(
        this._store.select(getPlayerID),
        this._store.select(getPlayerName),
        this._store.select(getPlayerPositionInFront),
      ),
      switchMap(([{ item }, playerId, name, { x, y }]) =>
        this._inventoryDbService
          .pickItem(playerId, item.code, item.amount)
          .pipe(
            take(1),
            tap(() => {
              this._audioService.playSound('miscs', 'drop_leather', 'ogg');
              this._notificationService.addNotification(
                'validation',
                `+${item.amount} ${item.name}`,
                5000,
                item.img,
              );
            }),
            concatMap(() => [
              WorldActions.removeItemTile({ x, y, amount: item.amount }),
              ChatActions.addChatLog({
                tab: ChatTab.ACTION,
                name,
                text: `a ramassé l'objet "${item.name}" (x${item.amount})`,
                kind: ChatLogKind.Bonus,
              }),
              InventoryActions.addItemInInventory({
                item,
                amount: item.amount,
              }),
            ]),
            catchError((err) => of(InventoryActions.pickItemFailed())),
          ),
      ),
    ),
  );

  private _pickItemFailedNotEnoughPlace$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.pickItemFailedNotEnoughPlace),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez de place dans votre sac",
          );
        }),
      ),
    { dispatch: false },
  );

  private _pickItemFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.pickItemFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _inventoryDbService: InventoryDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
