import { Injectable } from '@angular/core';
import { getCharacterInFrontOfPlayer } from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';
import { Tile } from '@t12/common/world/interfaces/tile.interface';
import { SidesTiles } from '@t12/common/world/types/sides-tile.type';
import { LoadingStatus } from '@t12/utils/enums/loading-status.enum';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { positionInFrontClose } from '@t12/world/constants/position-in-front-close.constant';
import { getWorldTileAt } from '@t12/world/store/selector/world.selectors';

@Injectable({
  providedIn: 'root',
})
export class WorldGeneratorService {
  constructor(private readonly _utils: UtilsService) {}

  public canMove(
    character: Character,
    direction: Looking,
    worldLoadingStatus: LoadingStatus,
  ): boolean {
    if (
      !character.canMove ||
      character.health <= 0 ||
      worldLoadingStatus !== LoadingStatus.LOADED
    )
      return false;

    return this.canStepOnTile({ ...character, looking: direction });
  }

  public canStepOnTile(character: Character): boolean {
    const {
      position: { x, y },
      looking,
    } = character;
    const currentTile = this._utils.getSelect(getWorldTileAt(x, y));
    if (!currentTile) return false;

    const nextPosition = positionInFrontClose(x, y, looking);
    const nextTile = this._utils.getSelect(
      getWorldTileAt(nextPosition.x, nextPosition.y),
    );
    if (!nextTile) return false;

    return this._canMoveToNextTile(character, currentTile, nextTile);
  }

  private _canMoveToNextTile(
    character: Character,
    currentTile: Tile,
    nextTile: Tile,
  ): boolean {
    const characterFound = this._utils.getSelect(getCharacterInFrontOfPlayer);

    if (
      character.kind === CharacterKind.PLAYER &&
      this._isBlockedForPlayer(nextTile, characterFound)
    ) {
      return false;
    }

    const currentTileSides = this._normalizeSides(currentTile.sides);
    const nextTileSides = this._normalizeSides(nextTile.sides);

    return this._areSidesClear(
      character.looking,
      currentTileSides,
      nextTileSides,
    );
  }

  private _normalizeSides(sides?: SidesTiles): SidesTiles {
    return sides?.length === 1
      ? [true, true, true, true]
      : sides || [false, false, false, false];
  }

  private _isBlockedForPlayer(
    nextTile: Tile,
    characterFound: Character,
  ): boolean {
    return (
      !nextTile ||
      !!nextTile.item ||
      !!nextTile.workshop ||
      !!nextTile.harvestPoint ||
      !!nextTile.container ||
      !!(characterFound && characterFound.kind !== CharacterKind.PLAYER)
    );
  }

  private _areSidesClear(
    direction: Looking,
    currentTileSides: SidesTiles,
    nextTileSides: SidesTiles,
  ): boolean {
    const sideMapping: { [key in Looking]: number[] } = {
      right: [1, 3],
      left: [3, 1],
      up: [0, 2],
      down: [2, 0],
    };
    const [currentSideIndex, nextSideIndex] = sideMapping[direction];

    return !currentTileSides[currentSideIndex] && !nextTileSides[nextSideIndex];
  }
}
