import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';
import { TabJob } from '@t12/jobs/enums/tab-job.enum';

export const JobActions = createActionGroup({
  source: 'JOB',
  events: {
    'Learn job': props<{ itemCode: string }>(),
    'Learn job success': props<{ job: PlayerJob; itemCode: string }>(),
    'Learn job failed': emptyProps(),
    'Learn job failed already know': emptyProps(),
    'Learn job failed reached max': emptyProps(),
    'Forget job': props<{ job: PlayerJob }>(),
    'Forget job failed': emptyProps(),

    'Set active job': props<{ jobCode: JobCode }>(),
    'Set active tab': props<{ tab: TabJob }>(),
  },
});
