import { on } from '@ngrx/store';
import { directionMap } from '@t12/characters/constants/direction-map.constant';
import { CharacterState } from '@t12/characters/store';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import { getLeftCharacter } from '@t12/common/characters/constants/get-left-character.constant';
import { getTopCharacter } from '@t12/common/characters/constants/get-top-character.constant';
import { SpriteAnimationsX } from '@t12/common/characters/enums/sprite-animations-x.enum';
import { SpriteAnimationsY } from '@t12/common/characters/enums/sprite-animations-y.enum';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';
import { maxTilesX } from '@t12/common/world/constants/max-tiles-x.constant';
import { maxTilesY } from '@t12/common/world/constants/max-tiles-y.constant';

export const CharactersMoveHandlers = [
  on(
    CharactersMoveActions.move,
    (charactersState: CharacterState, { id, kind, direction }) =>
      charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? {
              ...character,
              looking: direction,
              spriteY: SpriteAnimationsY[direction.toUpperCase()],
            }
          : character,
      ),
  ),

  on(
    CharactersMoveActions.moveStep,
    (
      charactersState: CharacterState,
      { character: { id, kind }, direction },
    ) => {
      const { left, top, x, y } = directionMap[direction];

      return charactersState.map((character: Character) => {
        if (character.id === id && character.kind === kind) {
          const newX = character.position.x + x;
          const newY = character.position.y + y;

          // Retourne le personnage sans modification si les coordonnées sont invalides
          if (newX < 0 || newX > maxTilesX || newY < 0 || newY > maxTilesY) {
            return character;
          }

          // Applique les mises à jour si les coordonnées sont valides
          return {
            ...character,
            left: character.left + left,
            top: character.top + top,
            position: {
              x: newX,
              y: newY,
            },
            spriteX: character.firstStep
              ? SpriteAnimationsX.FIRST_STEP_X
              : SpriteAnimationsX.SECOND_STEP_X,
            canMove: false,
            firstStep: !character.firstStep,
          };
        }

        return character;
      }) as CharacterState;
    },
  ),

  on(
    CharactersMoveActions.idleStep,
    (charactersState: CharacterState, { id, kind }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        canMove: character.health > 0,
        spriteX: SpriteAnimationsX.IDLE_STEP_X,
      });

      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersMoveActions.setPositionXY,
    (charactersState: CharacterState, { id, kind, x, y }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        position: { x, y },
        top: getTopCharacter(y),
        left: getLeftCharacter(x),
      });
      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersMoveActions.setLooking,
    (charactersState: CharacterState, { id, kind, looking }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        spriteY: SpriteAnimationsY[looking.toUpperCase()],
        looking,
      });

      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersMoveActions.setFaceToPlayer,
    (charactersState: CharacterState, { id, kind }) => {
      const updateCharacter = (character: Character) => {
        const { position: position1 } = charactersState[0];
        const { position: position2 } = character;

        const directions = {
          up: position1.y < position2.y,
          down: position1.y > position2.y,
          left: position1.x < position2.x,
          right: position1.x > position2.x,
        };

        const looking = Object.keys(directions).find(
          (key) => directions[key],
        ) as Looking;

        if (!position1 || !position2) return;

        return {
          ...character,
          spriteY: SpriteAnimationsY[looking.toUpperCase()],
          looking,
        };
      };

      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersMoveActions.setCanMove,
    (charactersState: CharacterState, { id, kind, canMove }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        canMove,
      });
      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),
];
