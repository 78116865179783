import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Shop } from '@t12/common/shop/interfaces/shop.interface';
import { ShopState } from '@t12/shop/store';

const selectRoot = createFeatureSelector<ShopState>('shop');

export const getShopItems = createSelector(
  selectRoot,
  ({ shops }: ShopState) => shops,
);

export const getShopItemByCode = (itemCode: string) =>
  createSelector(getShopItems, (shop: Shop) => {
    for (const key in shop) {
      if (shop.hasOwnProperty(key)) {
        const items = shop[key as keyof Shop];

        if (Array.isArray(items)) {
          const foundItem = items.find((item) => item.code === itemCode);
          if (foundItem) return foundItem;
        }
      }
    }
    return undefined;
  });

export const getShopActiveFilter = createSelector(
  selectRoot,
  ({ activeFilter }: ShopState) => activeFilter,
);

export const getShopFilters = createSelector(
  selectRoot,
  ({ filters }: ShopState) => filters,
);

export const getShopActiveItem = createSelector(
  selectRoot,
  ({ activeItem }: ShopState) => activeItem,
);

export const getShopAmountActiveItem = createSelector(
  selectRoot,
  ({ activeItemAmount }: ShopState) => activeItemAmount,
);

export const getShopDisplayedItems = createSelector(
  selectRoot,
  ({ displayedItems }: ShopState) => displayedItems,
);

export const getShopCurrentPage = createSelector(
  selectRoot,
  ({ currentPage }: ShopState) => currentPage,
);

export const getShopMaxPage = createSelector(
  selectRoot,
  ({ maxPage }: ShopState) => maxPage,
);
