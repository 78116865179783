import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CharactersAttackActions } from '@t12/characters/store/actions/attack/characters-attack.actions';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import { CharacterMoveActionSocket } from '@t12/common/characters/constants/character-move-action-socket.interface';
import { IMonster } from '@t12/common/characters/interfaces/monster.interface';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { AttackCharacterResult } from '@t12/common/fight/interfaces/attack-character.interface';
import { CharacterLookingActionSocket } from '@t12/common/player/constants/character-looking-action-socket.interface';
import { filter } from 'rxjs';
import { SocketService } from '../../socket.service';

@Injectable({
  providedIn: 'root',
})
export class CharacterSocketService {
  constructor(
    private readonly _store: Store,
    private readonly _socketService: SocketService,
  ) {}

  public init() {
    this._listenForCharacterMoveActions();
    this._listenForCharacterLookingActions();
    this._listenForCharacterAttackActions();
    this._listenForCharacterRespawnActions();
  }

  private _listenForCharacterMoveActions() {
    const characterMove$ = this._socketService
      .fromEvent<CharacterMoveActionSocket>('character-move')
      .pipe(filter((characterMove) => !!characterMove))
      .subscribe(({ id, direction, kind, position: { x, y } }) => {
        this._store.dispatch(
          CharactersMoveActions.setPositionXY({
            id,
            kind,
            x,
            y,
          }),
        );
        this._store.dispatch(
          CharactersMoveActions.move({
            id,
            kind,
            direction,
          }),
        );
      });
    this._socketService.addSubscription(characterMove$);
  }

  private _listenForCharacterLookingActions() {
    const characterLooking$ = this._socketService
      .fromEvent<CharacterLookingActionSocket>('character-looking')
      .pipe(filter((characterLooking) => !!characterLooking))
      .subscribe(({ id, looking, kind }) => {
        this._store.dispatch(
          CharactersMoveActions.setLooking({
            id,
            kind,
            looking,
          }),
        );
      });
    this._socketService.addSubscription(characterLooking$);
  }

  private _listenForCharacterAttackActions() {
    const chatSubscription = this._socketService
      .fromEvent<AttackCharacterResult>('character-attack')
      .pipe(filter((characterAttack) => !!characterAttack))
      .subscribe((attackCharacterResult) => {
        this._store.dispatch(
          CharactersAttackActions.characterAttack({ attackCharacterResult }),
        );
      });
    this._socketService.addSubscription(chatSubscription);
  }

  private _listenForCharacterRespawnActions() {
    const socialSubscription = this._socketService
      .fromEvent<INPC | IMonster>('respawn-character')
      .pipe(filter((respawnCharacter) => !!respawnCharacter))
      .subscribe((character) => {
        this._store.dispatch(CharactersActions.addCharacter({ character }));
      });
    this._socketService.addSubscription(socialSubscription);
  }
}
