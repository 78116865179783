import { createReducer, on } from '@ngrx/store';
import { CharactersAttackHandlers } from '@t12/characters/store/reducers/attack/characters-attack.reducer';
import { CharactersMoveHandlers } from '@t12/characters/store/reducers/move/characters-move.reducer';
import { getLeftCharacter } from '@t12/common/characters/constants/get-left-character.constant';
import { getTopCharacter } from '@t12/common/characters/constants/get-top-character.constant';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { SpriteAnimationsX } from '@t12/common/characters/enums/sprite-animations-x.enum';
import { SpriteAnimationsY } from '@t12/common/characters/enums/sprite-animations-y.enum';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';
import { calculateXpForNextLevel } from '@t12/common/player/constants/next-level-xp.constant';
import { LocalPlayerHandlers } from '@t12/player/store/reducers/local-player.reducer';
import { CharactersActions } from '../../actions/characters/characters.actions';
import { CharacterState, initialCharactersState } from '../../index';

export const CharactersReducer = createReducer(
  initialCharactersState,

  ...LocalPlayerHandlers,
  ...CharactersMoveHandlers,
  ...CharactersAttackHandlers,

  on(
    CharactersActions.addCharacter,
    (charactersState: CharacterState, { character }) => {
      return [
        ...charactersState,
        {
          ...character,
          canMove: true,
          top: getTopCharacter(character.position.y),
          left: getLeftCharacter(character.position.x),
          spriteX: SpriteAnimationsX.IDLE_STEP_X,
          spriteY: SpriteAnimationsY[character.looking.toUpperCase()],
          firstStep: false,
          kind: character.kind,
        },
      ] as CharacterState;
    },
  ),

  on(
    CharactersActions.removeCharacterById,
    (charactersState: CharacterState, { id, kind }) => {
      return charactersState.filter(
        (character) => !(character.id === id && character.kind === kind),
      ) as CharacterState;
    },
  ),

  on(
    CharactersActions.setCharacters,
    (charactersState: CharacterState, { characters }) => {
      const player = charactersState[0];

      return [player, ...characters] as CharacterState;
    },
  ),

  on(CharactersActions.resetCharacters, (charactersState: CharacterState) => [
    charactersState[0],
  ]),

  on(CharactersActions.deleteAllCharacters, () => []),

  on(
    CharactersActions.characterIsDead,
    (charactersState: CharacterState, { id, kind }) =>
      charactersState.map((character) =>
        character.id === id && kind === character.kind
          ? {
              ...character,
              health: 0,
            }
          : character,
      ),
  ),

  on(
    CharactersActions.addHealth,
    (charactersState: CharacterState, { id, kind, health }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        health:
          character.health + health < 0
            ? 0
            : Math.min(
                character.health + health,
                maxHealth(character.stats.con),
              ),
      });
      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addMana,
    (charactersState: CharacterState, { id, kind, mana }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        mana:
          character.mana + mana < 0
            ? 0
            : Math.min(character.mana + mana, maxMana(character.stats.int)),
      });
      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addHealthMana,
    (charactersState: CharacterState, { id, kind, health, mana }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        health:
          character.health + health < 0
            ? 0
            : Math.min(
                character.health + health,
                maxHealth(character.stats.con),
              ),
        mana:
          character.mana + mana < 0
            ? 0
            : Math.min(character.mana + mana, maxMana(character.stats.int)),
      });
      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(CharactersActions.levelUp, (charactersState: CharacterState, { id }) => {
    const updateCharacter = ({ lvl, stats, ...character }: IPlayer) => ({
      ...character,
      lvl: lvl + 1,
      stats: {
        ...stats,
        for: stats.for + 1,
        con: stats.con + 1,
        dex: stats.dex + 1,
        int: stats.int + 1,
        sag: stats.sag + 1,
      },
      health: maxHealth(stats.con + 1),
      mana: maxMana(stats.int + 1),
    });

    return charactersState.map((character) =>
      character.id === id && character.kind === CharacterKind.PLAYER
        ? updateCharacter(character)
        : character,
    );
  }),

  on(CharactersActions.addXp, (charactersState: CharacterState, { id, xp }) =>
    charactersState.map((character) =>
      character.id === id && character.kind === CharacterKind.PLAYER
        ? {
            ...character,
            xp: (character.xp + xp) % calculateXpForNextLevel(character.lvl),
          }
        : character,
    ),
  ),

  on(
    CharactersActions.setQuestIcon,
    (charactersState: CharacterState, { codeCharacter, questIcon }) => {
      const updateCharacter = (character) => ({
        ...character,
        questIcon,
      });
      const updatedState = charactersState.map((character) =>
        character.code === codeCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),
);
