import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { SocialsDbService } from '../../../services/socials-db/socials-db.service';
import { SocialsFriendsActions } from '../../actions/friends/socials-friends.actions';

@Injectable()
export class FriendRequestsEffects {
  private _getFriendRequests$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsFriendsActions.getFriendRequests),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([, playerId]) =>
        this._socialsDbService.getPlayerFriendRequests(playerId).pipe(
          map((friendRequests) =>
            SocialsFriendsActions.setFriendRequests({ friendRequests }),
          ),
          catchError(() => of(SocialsFriendsActions.getFriendsFailed())),
        ),
      ),
    ),
  );

  private _updateDisplayedFriendRequests$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        SocialsFriendsActions.setFriendRequests,
        SocialsFriendsActions.addFriendRequest,
        SocialsFriendsActions.removeFriendRequest,
      ),
      map(() => SocialsFriendsActions.updateDisplayedFriendRequests()),
    ),
  );

  private _sendFriendRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsFriendsActions.sendFriendRequest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) => {
        if (name.length < 2)
          return of(
            SocialsFriendsActions.sendFriendRequestFailedNameTooShort(),
          );

        return this._socialsDbService.sendFriendRequest(playerId, name).pipe(
          switchMap((response) => {
            if ('lastConnexion' in response)
              return [
                SocialsFriendsActions.removeFriendRequest({
                  name: response.name,
                }),
                SocialsFriendsActions.addFriend({ playerFriend: response }),
              ];
            else
              return of(
                SocialsFriendsActions.sendFriendRequestSuccess({
                  name: response.name,
                }),
              );
          }),
          catchError(({ error }: HttpErrorResponse) => {
            let action: Action;
            switch (error?.message) {
              case 'NOT_FOUND':
                action =
                  SocialsFriendsActions.sendFriendRequestFailedNotFound();
                break;
              case 'ALREADY_FRIEND':
                action =
                  SocialsFriendsActions.sendFriendRequestFailedAlreadyFriend();
                break;
              case 'ALREADY_IN_REQUEST':
                action =
                  SocialsFriendsActions.sendFriendRequestFailedAlreadyRequested();
                break;
              default:
                action = SocialsFriendsActions.sendFriendRequestFailed();
            }
            return of(action);
          }),
        );
      }),
    ),
  );

  private _sendFriendRequestSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsFriendsActions.sendFriendRequestSuccess),
        tap(({ name }) => {
          this._notificationService.addNotification(
            'settings',
            `Demande d'ami envoyée à ${name}`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendFriendRequestFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          SocialsFriendsActions.sendFriendRequestFailed,
          SocialsFriendsActions.acceptFriendRequestFailed,
          SocialsFriendsActions.deniedFriendRequestFailed,
        ),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendFriendRequestFailedNameTooShort$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsFriendsActions.sendFriendRequestFailedNameTooShort),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Le nom du joueur est trop court.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendFriendRequestFailedNotFound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsFriendsActions.sendFriendRequestFailedNotFound),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Aucun joueur trouvé...`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendFriendRequestFailedAlreadyFriend$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsFriendsActions.sendFriendRequestFailedAlreadyFriend),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Vous êtes déjà ami avec ce personnage.`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendFriendRequestFailedAlreadyRequested$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsFriendsActions.sendFriendRequestFailedAlreadyRequested),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Une demande est déjà en cours.`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _addFriendRequest$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsFriendsActions.addFriendRequest),
        tap(({ friendRequest }) => {
          this._notificationService.addNotification(
            'settings',
            `Vous avez reçu une demande d'ami de ${friendRequest.name}`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _acceptFriendRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsFriendsActions.acceptFriendRequest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) =>
        this._socialsDbService.acceptFriendRequest(playerId, name).pipe(
          switchMap((playerFriend) => [
            SocialsFriendsActions.removeFriendRequest({
              name: playerFriend.name,
            }),
            SocialsFriendsActions.addFriend({ playerFriend }),
          ]),
          catchError(() =>
            of(SocialsFriendsActions.acceptFriendRequestFailed({ name })),
          ),
        ),
      ),
    ),
  );

  private _deniedFriendRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsFriendsActions.deniedFriendRequest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) =>
        this._socialsDbService.deletePlayerFriendRequest(playerId, name).pipe(
          map(() => SocialsFriendsActions.removeFriendRequest({ name })),
          catchError(() =>
            of(SocialsFriendsActions.deniedFriendRequestFailed()),
          ),
        ),
      ),
    ),
  );

  private _removeFriendRequest$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsFriendsActions.removeFriendRequest),
        tap(({ name }) => {
          this._notificationService.addNotification(
            'error',
            `Demande de ${name} refusée`,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socialsDbService: SocialsDbService,
    private readonly _store: Store,
  ) {}
}
