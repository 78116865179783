import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import {
  getPlayerID,
  getPlayer,
} from '@t12/characters/store/selectors/characters.selectors';
import { playerToPlayerGroupInfos } from '@t12/common/socials/adapters/player-to-player-group-infos.constant';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import {
  catchError,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
  filter,
} from 'rxjs';
import { SocialsDbService } from '../../../services/socials-db/socials-db.service';
import { SocialsGroupActions } from '../../actions/group/socials-group.actions';

@Injectable()
export class GroupRequestsEffects {
  private _getGroupRequests$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.getGroupRequests),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([, playerId]) =>
        this._socialsDbService.getPlayerGroupRequests(playerId).pipe(
          filter((groupRequests) => !!groupRequests.length),
          map((groupRequests) =>
            SocialsGroupActions.setGroupRequests({ groupRequests }),
          ),
          catchError(() => of(SocialsGroupActions.getGroupRequestsFailed())),
        ),
      ),
    ),
  );

  private _sendGroupRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.sendGroupRequest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) => {
        if (name.length < 2)
          return of(SocialsGroupActions.sendGroupRequestFailedNameTooShort());

        return this._socialsDbService.sendGroupRequest(playerId, name).pipe(
          switchMap((player) =>
            of(
              SocialsGroupActions.sendGroupRequestSuccess({
                name: player.name,
              }),
            ),
          ),
          catchError(({ error }: HttpErrorResponse) => {
            let action: Action;
            switch (error?.message) {
              case 'NOT_LEADER_GROUP':
                action = SocialsGroupActions.failedNotLeader();
                break;
              case 'NOT_FOUND':
                action = SocialsGroupActions.sendGroupRequestFailedNotFound();
                break;
              case 'ALREADY_IN_GROUP':
                action =
                  SocialsGroupActions.sendGroupRequestFailedAlreadyInGroup();
                break;
              case 'MAX_SIZE_GROUP':
                action =
                  SocialsGroupActions.sendGroupRequestFailedMaxSizeGroup();
                break;
              case 'ALREADY_REQUESTED':
                action =
                  SocialsGroupActions.sendGroupRequestFailedAlreadyRequested();
                break;
              default:
                action = SocialsGroupActions.sendGroupRequestFailed();
            }

            return of(action);
          }),
        );
      }),
    ),
  );

  private _sendGroupRequestSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.sendGroupRequestSuccess),
        tap(({ name }) => {
          this._notificationService.addNotification(
            'settings',
            `Invitation envoyée à ${name}`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailedNameTooShort$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.sendGroupRequestFailedNameTooShort),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Le nom du joueur est trop court.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailedNotFound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.sendGroupRequestFailedNotFound),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Aucun joueur trouvé...`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailedAlreadyInGroup$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.sendGroupRequestFailedAlreadyInGroup),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Ce joueur est déjà dans un groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailedMaxSizeGroup$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.sendGroupRequestFailedMaxSizeGroup),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Limite de taille de groupe atteinte`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailedAlreadyRequested$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.sendGroupRequestFailedAlreadyRequested),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Une demande est déjà en cours.`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          SocialsGroupActions.sendGroupRequestFailed,
          SocialsGroupActions.createGroupFailed,
          SocialsGroupActions.deniedGroupRequestFailed,
        ),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _addGroupRequest$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.addGroupRequest),
        tap(({ groupRequest }) => {
          this._notificationService.addNotification(
            'settings',
            `Vous avez reçu une invitation de ${groupRequest.name}`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _acceptGroupRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.acceptGroupRequest),
      withLatestFrom(this._store.select(getPlayer)),
      switchMap(([{ name }, player]) =>
        this._socialsDbService.acceptGroupRequest(player.id, name).pipe(
          map((playerGroup) =>
            SocialsGroupActions.createGroup({
              localPlayer: playerToPlayerGroupInfos(player),
              playerGroup,
            }),
          ),
          catchError(() => of(SocialsGroupActions.createGroupFailed())),
        ),
      ),
    ),
  );

  private _deniedGroupRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.deniedGroupRequest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) =>
        this._socialsDbService.deletePlayerGroupRequest(playerId, name).pipe(
          map(() => SocialsGroupActions.removeGroupRequest({ name })),
          catchError(() => of(SocialsGroupActions.deniedGroupRequestFailed())),
        ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socialsDbService: SocialsDbService,
    private readonly _store: Store,
  ) {}
}
