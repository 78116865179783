import { Looking } from '@t12/common/characters/types/looking.type';
import { AttackKind } from '@t12/common/fight/types/attack-kind.type';

export const moveKey: Record<Looking, string[]> = {
  up: ['z', 'arrowup'],
  right: ['d', 'arrowright'],
  down: ['s', 'arrowdown'],
  left: ['q', 'arrowleft'],
};

export const attackKey: Record<AttackKind, string[]> = {
  physic: ['a'],
  magic: ['e'],
};

export const interactionKey = {
  interaction: [' '],
};
