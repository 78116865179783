import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNPC } from '@t12/characters/constants/is-npc.constant';
import {
  getPlayerPositionInFront,
  getCharacterInFrontOfPlayer,
  getPlayer,
} from '@t12/characters/store/selectors/characters.selectors';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import {
  getHudDialog,
  getHudContainer,
} from '@t12/overlay/store/selectors/hud-display/hud-display.selectors';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import { getWorldTileAt } from '@t12/world/store/selector/world.selectors';
import {
  map,
  switchMap,
  filter,
  take,
  withLatestFrom,
  throttleTime,
} from 'rxjs';

@Injectable()
export class InteractionEffects {
  private _interaction$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.interaction),
      withLatestFrom(
        this._store.select(getPlayer),
        this._store.select(getPlayerPositionInFront),
        this._store.select(getCharacterInFrontOfPlayer),
        this._store.select(getHudDialog),
      ),
      filter(([_, player]) => player?.health > 0),
      switchMap(([_, __, position, character, dialogOpened]) =>
        this._store.select(getWorldTileAt(position.x, position.y)).pipe(
          take(1),
          map((tile) => ({
            tile,
            character,
            dialogOpened,
          })),
        ),
      ),
      map(({ tile, character, dialogOpened }) => {
        if (dialogOpened) return DialogActions.continueConversation();
        else if (
          tile.item ||
          tile.container ||
          tile.harvestPoint ||
          tile.workshop
        )
          return LocalPlayerActions.interactTile({ tile });
        else if (character)
          return LocalPlayerActions.interactCharacter({ character });
        return null;
      }),
      filter(Boolean),
    ),
  );

  private _interactTile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.interactTile),
      map(({ tile }) => {
        if (tile.item) {
          return LocalPlayerActions.pickItem({
            item: tile.item,
          });
        } else if (tile.container) {
          return LocalPlayerActions.interactContainer({
            containerKind: tile.container.kind,
          });
        } else if (tile.harvestPoint) {
          return LocalPlayerActions.harvest({
            harvestPoint: tile.harvestPoint,
          });
        } else if (tile.workshop) {
          return LocalPlayerActions.openWorkshop({
            workshop: tile.workshop,
          });
        } else {
          return null;
        }
      }),
      filter(Boolean),
    ),
  );

  private _interactCharacter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.interactCharacter),
      filter(
        ({ character }) => isNPC(character) && character.state !== 'fight',
      ),
      map(({ character }) =>
        DialogActions.startConversation({ npc: character as INPC }),
      ),
    ),
  );

  private _interactContainer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalPlayerActions.interactContainer),
      throttleTime(200),
      withLatestFrom(this._store.select(getHudContainer)),
      map(([{ containerKind }, isVisibleLootBox]) => {
        if (!isVisibleLootBox) {
          return ContainerActions.openContainer({ containerKind });
        } else return ContainerActions.pickItemContainer({ index: 0 });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
