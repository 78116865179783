import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';

export const ChatCommandActions = createActionGroup({
  source: 'CHAT COMMAND',
  events: {
    'Can dispatch chat log': props<{ chatLog: ChatLog }>(),
    'Can dispatch chat log pm': props<{ chatLog: ChatLog }>(),
    'Can dispatch chat log party': props<{ chatLog: ChatLog }>(),
    'Can dispatch chat log success': props<{ chatLog: ChatLog }>(),
    'Can dispatch chat log party failed not in group': emptyProps(),
    'Can dispatch chat log pm failed not friend': emptyProps(),
    'Can dispatch chat log pm failed friend offline': props<{
      friendName: string;
    }>(),
    'Can dispatch chat log pm failed no pm name': emptyProps(),
    'Can dispatch chat log pm failed no text': emptyProps(),

    'Format chat log': props<{
      chatLog: ChatLog;
    }>(),
    'Format chat log local': props<{
      chatLog: ChatLog;
    }>(),
    'Format chat log global': props<{
      chatLog: ChatLog;
    }>(),
    'Format chat log roll': props<{
      chatLog: ChatLog;
    }>(),
    'Format chat log me': props<{
      chatLog: ChatLog;
    }>(),
    'Format chat log pm': props<{
      chatLog: ChatLog;
    }>(),
    'Format chat log party': props<{
      chatLog: ChatLog;
    }>(),
    'Format chat log log': props<{
      chatLog: ChatLog;
    }>(),
    'Format chat log yell': props<{
      chatLog: ChatLog;
    }>(),
    'Format chat log bonus': props<{
      chatLog: ChatLog;
    }>(),
    'Format chat log malus': props<{
      chatLog: ChatLog;
    }>(),
    'Format chat log failed': emptyProps(),
  },
});
