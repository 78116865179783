import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BankDbService } from '@t12/bank/services/bank-db/bank-db.service';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import {
  getBankGold,
  getCountItemBank,
  getDepositGold,
  getDepositItem,
} from '@t12/bank/store/selectors/bank.selectors';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getFreeInventorySlotAmount } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import {
  catchError,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class BankDepositFromEffects {
  private _depositFrom$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositFrom),
      withLatestFrom(this._store.select(getDepositItem)),
      switchMap(([_, depositItem]) =>
        this._store.select(getFreeInventorySlotAmount(depositItem)).pipe(
          take(1),
          withLatestFrom(
            this._store.select(getBankGold),
            this._store.select(getCountItemBank(depositItem.code)),
            this._store.select(getDepositGold),
          ),
          map(([freeSlot, bankGold, amountBank, depositGold]) => {
            if (depositItem.code && freeSlot <= 0)
              return BankActions.depositFromFailedInventoryFull();
            else if (depositItem.code && amountBank < depositItem.amount)
              return BankActions.depositFromFailedNotEnoughItem();
            else if (depositGold && bankGold < depositGold)
              return BankActions.depositFromFailedNotEnoughGold();
            else {
              return BankActions.depositFromSuccess({
                item: depositItem,
                gold: depositGold,
              });
            }
          }),
        ),
      ),
    ),
  );

  private _depositFromSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositFromSuccess),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ item, gold }, playerId]) =>
        this._bankDbService.depositFromBank(playerId, item, gold).pipe(
          take(1),
          map(() =>
            [
              item.amount > 0
                ? BankActions.depositFromItemSuccess({ item })
                : null,
              gold > 0 ? BankActions.depositFromGoldSuccess({ gold }) : null,
            ].filter((action) => !!action),
          ),
          mergeMap((actions) => actions),
          catchError(() => of(BankActions.depositFail)),
        ),
      ),
    ),
  );

  private _depositFromItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositFromItemSuccess),
      tap(({ item }) => {
        this._notificationService.addNotification(
          'item',
          `+${item.amount} ${item.name}`,
          5000,
          item.img,
        );
      }),
      map(({ item }) =>
        InventoryActions.addItemInInventory({
          item,
          amount: item.amount,
        }),
      ),
    ),
  );

  private _depositFromGoldSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositFromGoldSuccess),
      tap(({ gold }) => {
        this._notificationService.addNotification(
          'item',
          `Vous avez récupéré ${gold}G`,
          5000,
        );
      }),
      map(({ gold }) => LocalPlayerActions.addGold({ gold })),
    ),
  );

  private _depositFromFailedInventoryFull$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositFromFailedInventoryFull),
      tap(() =>
        this._notificationService.addNotification(
          'error',
          "Vous n'avez la place nécessaire",
        ),
      ),
    ),
  );

  private _depositFromFailedNotEnoughGold$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BankActions.depositFromFailedNotEnoughGold),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez d'or en réserve.",
          ),
        ),
      ),
    { dispatch: false },
  );

  private _depositFromFailedNotEnoughItem$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BankActions.depositFromFailedNotEnoughItem),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez d'exemplaire de l'objet",
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _bankDbService: BankDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
