import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuySellItem } from '@t12/common/shop/interfaces/buy-sell-item.interface';
import { Shop } from '@t12/common/shop/interfaces/shop.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShopDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  public getShopNPC(playerId: number, npcCode: string): Observable<Shop> {
    return this._http.get(
      `${environment(`shop/${npcCode}/player/${playerId}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<Shop>;
  }

  public buyItem(playerId: number, itemCode: string, amount: number) {
    const buyItemDto: BuySellItem = {
      playerId,
      itemCode,
      amount,
    };

    return this._http.patch(`${environment(`shop/buy`).uri}`, buyItemDto, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    });
  }

  public sellItem(playerId: number, itemCode: string, amount: number) {
    const buyItemDto: BuySellItem = {
      playerId,
      itemCode,
      amount,
    };

    return this._http.patch(`${environment(`shop/sell`).uri}`, buyItemDto, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    });
  }
}
