import { DayTime } from '@t12/common/world/enums/day-time.enum';
import { WeatherKind } from '@t12/common/world/enums/weather-kind.enum';
import { Tile } from '@t12/common/world/interfaces/tile.interface';
import { LoadingStatus } from '@t12/utils/enums/loading-status.enum';

export const WORLD_FEATURE_KEY = 'world';

export interface WorldState {
  code?: string;
  enableWeather: boolean;
  weather?: WeatherKind;
  time: DayTime;
  loadingStatus: LoadingStatus;
  music?: string;
  name?: string;
  tiles?: Tile[][];
}

export const initialWorldState: WorldState = {
  enableWeather: true,
  time: DayTime.DAY,
  loadingStatus: LoadingStatus.NOT_LOADED,
};
