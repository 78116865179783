import { on } from '@ngrx/store';
import { CharacterState } from '@t12/characters/store';
import { getLeftCharacter } from '@t12/common/characters/constants/get-left-character.constant';
import { getTopCharacter } from '@t12/common/characters/constants/get-top-character.constant';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { SpriteAnimationsX } from '@t12/common/characters/enums/sprite-animations-x.enum';
import { SpriteAnimationsY } from '@t12/common/characters/enums/sprite-animations-y.enum';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { EquipmentSlotIndex } from '@t12/common/equipments/enums/equipment-slot-index.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';
import { calculateXpForNextLevel } from '@t12/common/player/constants/next-level-xp.constant';
import { EquipmentsActions } from '@t12/equipments/store/actions/equipments.actions';
import { getKindJobByCode } from '@t12/jobs/constants/get-job-kind-by-code.constant';
import { defaultDeathPlayerState } from '@t12/player/constants/default-death-player-state.constant';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';

export const LocalPlayerHandlers: any = [
  on(LocalPlayerActions.loadPlayerSuccess, (_, { player }) => {
    const { inventory, ...playerWithoutInventory } = player;

    const playerCharacter: IPlayer = {
      ...playerWithoutInventory,
      canMove: false,
      top: getTopCharacter(player.position.y),
      left: getLeftCharacter(player.position.x),
      spriteX: SpriteAnimationsX.IDLE_STEP_X,
      spriteY: SpriteAnimationsY[player.looking.toUpperCase()],
      firstStep: false,
      kind: CharacterKind.PLAYER,
    };

    return [playerCharacter];
  }),

  on(LocalPlayerActions.levelUp, (charactersState: CharacterState, { xp }) => {
    const updateCharacter = ({
      lvl,
      stats,
      xp: currentXp,
      ...character
    }: IPlayer) => ({
      ...character,
      lvl: lvl + 1,
      stats: {
        ...stats,
        for: stats.for + 1,
        con: stats.con + 1,
        dex: stats.dex + 1,
        int: stats.int + 1,
        sag: stats.sag + 1,
      },
      health: maxHealth(stats.con + 1),
      mana: maxMana(stats.int + 1),
      xp: (currentXp + xp) % calculateXpForNextLevel(lvl),
    });

    return charactersState.map((character: Character, index: number) =>
      index === 0 && character.kind === CharacterKind.PLAYER
        ? updateCharacter(character)
        : character,
    );
  }),

  on(
    LocalPlayerActions.applyDeathSanction,
    (charactersState: CharacterState, { worldPosition }) => {
      const { worldCode, position } = worldPosition;

      return charactersState.map((character: Character, index: number) =>
        index === 0 && character.kind === CharacterKind.PLAYER
          ? ({
              ...character,
              ...defaultDeathPlayerState(character, worldCode, position),
            } as IPlayer)
          : character,
      );
    },
  ),

  on(
    LocalPlayerActions.addGold,
    (charactersState: CharacterState, { gold }) => {
      const updatedState = charactersState.map(
        (character: Character, index: number) =>
          index === 0
            ? { ...character, gold: character.gold + gold }
            : character,
      );

      return [...updatedState];
    },
  ),

  on(
    EquipmentsActions.equipItemSuccess,
    (charactersState: CharacterState, { item, oldEquipment }) => {
      const { stats = {} } = item;
      const { stats: oldStats = {} } = oldEquipment || {};

      return charactersState.map((character: Character, index: number) => {
        if (index !== 0 || character.kind !== CharacterKind.PLAYER) {
          return character;
        }

        const player = character as IPlayer;
        const equipments = [...player.equipments];
        equipments[EquipmentSlotIndex[item.slot]] = { ...item, amount: 1 };

        const adjustStat = (stat: keyof typeof stats, multiplier: number) =>
          (stats[stat] || 0) * multiplier - (oldStats[stat] || 0) * multiplier;

        return {
          ...player,
          equipments,
          health: player.health + adjustStat('con', 4),
          mana: player.mana + adjustStat('int', 3),
          stats: Object.keys(player.stats).reduce(
            (updatedStats, key) => {
              const statKey = key as keyof typeof player.stats;
              updatedStats[statKey] =
                player.stats[statKey] +
                (stats[statKey] || 0) -
                (oldStats[statKey] || 0);
              return updatedStats;
            },
            { ...player.stats },
          ),
        };
      });
    },
  ),

  on(
    EquipmentsActions.unEquipItemSuccess,
    (charactersState: CharacterState, { item }) => {
      const { stats = {} } = item;

      return charactersState.map((character: Character, index: number) => {
        if (index !== 0 || character.kind !== CharacterKind.PLAYER) {
          return character;
        }

        const player = character as IPlayer;
        const equipments = [...player.equipments];
        equipments[EquipmentSlotIndex[item.slot]] = { amount: 0 } as Item;

        const adjustValue = (
          current: number,
          stat: number | undefined,
          multiplier: number,
          min: number = 0,
        ) => Math.max(current - (stat || 0) * multiplier, min);

        const updatedStats = Object.fromEntries(
          Object.entries(player.stats).map(([key, value]) => [
            key,
            value - (stats[key as keyof typeof stats] || 0),
          ]),
        );

        return {
          ...player,
          equipments,
          health: adjustValue(player.health, stats.con, 4, 1),
          mana: adjustValue(player.mana, stats.int, 3, 0),
          stats: updatedStats,
        };
      });
    },
  ),

  on(LocalPlayerActions.addNewJob, (charactersState: CharacterState, { job }) =>
    charactersState.map((character, index) =>
      index === 0 && character.kind === CharacterKind.PLAYER
        ? {
            ...character,
            jobs: {
              ...character.jobs,
              [job.kind]: [...(character.jobs[job.kind] ?? []), job],
            },
          }
        : character,
    ),
  ),

  on(
    LocalPlayerActions.removeJob,
    (charactersState: CharacterState, { job }) => {
      return charactersState.map((character: Character, index: number) =>
        index === 0 && character.kind === CharacterKind.PLAYER
          ? {
              ...character,
              jobs: {
                ...character.jobs,
                [job.kind]: character.jobs[job.kind].filter(
                  (jobItem: PlayerJob) => jobItem.code !== job.code,
                ),
              },
            }
          : character,
      );
    },
  ),

  on(
    LocalPlayerActions.setJobs,
    (charactersState: CharacterState, { jobs }) => {
      return charactersState.map((character: Character, index: number) =>
        index === 0 && character.kind === CharacterKind.PLAYER
          ? { ...character, jobs }
          : character,
      );
    },
  ),

  on(
    LocalPlayerActions.incJobXP,
    (charactersState: CharacterState, { jobCode }) => {
      return charactersState.map((character: Character, index: number) =>
        index === 0 &&
        character.kind === CharacterKind.PLAYER &&
        'jobs' in character
          ? {
              ...character,
              jobs: {
                ...character.jobs,
                [getKindJobByCode(jobCode)]: character.jobs[
                  getKindJobByCode(jobCode)
                ].map((job) =>
                  job.code === jobCode ? { ...job, xp: job.xp + 1 } : job,
                ),
              },
            }
          : character,
      );
    },
  ),
];
