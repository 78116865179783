/* istanbul ignore file */
import { Directive, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MonoTypeOperatorFunction } from 'rxjs';

@Directive()
export abstract class UnSubscriberComponent {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  takeUntilDestroyed<T>(): MonoTypeOperatorFunction<T> {
    return takeUntilDestroyed(this.destroyRef);
  }
}
