import { createActionGroup, props } from '@ngrx/store';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { IHarvestPoint } from '@t12/common/job/interfaces/harvest-point.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';

export const HarvestActions = createActionGroup({
  source: 'HARVEST',
  events: {
    'Harvest success': props<{ harvestPoint: IHarvestPoint }>(),
    'Harvest failed': props<{ position: Position }>(),
    'Harvest failed wrong job': props<{ jobCode: JobCode }>(),
    'Harvest failed required xp low': props<{
      xp: number;
      requiredXp: number;
    }>(),
  },
});
