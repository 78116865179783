export enum ChatLogColor {
  Me = '#e6ac12',
  Bonus = '#28b436',
  Default = 'white',
  Event = '#ff9021',
  Log = '#ffe100',
  Malus = '#ff4141',
  Roll = 'pink',
  Pm = '#02D5D5FF',
  Party = '#269cff',
  Yell = '#da540a',
}
