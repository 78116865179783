import { createReducer, on } from '@ngrx/store';
import { hotkeyKeysIndex } from '@t12/key-commands/constants/hotkey-keys-index.constant';
import { Hotkey } from '@t12/key-commands/interfaces/hotkey.interface';
import { HotkeyActions } from '../actions/hotkey.actions';
import { initialHotkeyState } from '../index';

export const HotkeyReducer = createReducer(
  initialHotkeyState,

  on(HotkeyActions.addHotkey, (hotkeyState: Hotkey[], { hotkeyEntity }) => {
    const alreadyAdded = hotkeyState.some(
      (hotkeyItem) => hotkeyItem.entity.code === hotkeyEntity.code,
    );
    if (hotkeyState.length >= hotkeyKeysIndex.length || alreadyAdded)
      return hotkeyState;

    const hotkey: Hotkey = {
      key: hotkeyKeysIndex[hotkeyState.length],
      entity: hotkeyEntity,
    };
    return [...hotkeyState, hotkey];
  }),

  on(
    HotkeyActions.setHotkeyAt,
    (hotkeyState: Hotkey[], { hotkeyEntity, index }) =>
      hotkeyState.map((currentHotkey, i) =>
        i === index
          ? { key: hotkeyKeysIndex[index], entity: hotkeyEntity }
          : currentHotkey,
      ),
  ),

  on(HotkeyActions.removeHotkey, (hotkeyState: Hotkey[], { code }) => {
    return hotkeyState
      .filter((hotkey) => hotkey.entity.code !== code)
      .map((hotkey, index) => ({
        ...hotkey,
        key: hotkeyKeysIndex[index],
      }));
  }),

  on(HotkeyActions.setHotkeys, (_, { hotkeys }) => hotkeys),

  on(HotkeyActions.resetHotkeys, (_) => []),

  on(HotkeyActions.deleteHotkeys, (_) => []),
);
