import { createReducer, on } from '@ngrx/store';
import { sortFriends } from '@t12/socials/constants/sort-friends.constant';
import { SocialTab } from '@t12/socials/enums/social-tab.enum';
import { SocialsFriendsActions } from '@t12/socials/store/actions/friends/socials-friends.actions';
import { SocialsGroupActions } from '@t12/socials/store/actions/group/socials-group.actions';
import { nbFriendsPage } from '../../constants/nb-friend-page.constant';
import { nbFriendRequestsPage } from '../../constants/nb-friend-requests-page.constant';
import { SocialsActions } from '../actions/socials/socials.actions';
import { initialSocialsState, SocialsState } from '../index';

export const SocialsReducer = createReducer(
  initialSocialsState,

  on(SocialsActions.reset, (_) => ({
    friendRequests: {
      list: [],
      displayed: [],
    },
    friends: {
      list: [],
      displayed: [],
      currentPage: 1,
      maxPage: 1,
    },
    groupRequests: [],
    group: {
      leaderName: '',
      playersInfos: [],
    },
    activeTab: SocialTab.Friend,
  })),

  on(SocialsActions.setTab, (socialsState: SocialsState, { tab }) => {
    return {
      ...socialsState,
      activeTab: tab,
    };
  }),

  on(
    SocialsFriendsActions.setFriends,
    (socialsState: SocialsState, { friends }) => {
      return {
        ...socialsState,
        friends: {
          ...socialsState.friends,
          list: friends,
        },
      };
    },
  ),

  on(
    SocialsFriendsActions.setFriendRequests,
    (socialsState: SocialsState, { friendRequests }) => {
      return {
        ...socialsState,
        friendRequests: {
          ...socialsState.friendRequests,
          list: friendRequests,
        },
      };
    },
  ),

  on(
    SocialsFriendsActions.incFriendsPage,
    (socialsState: SocialsState, { inc }) => {
      const newPage = Math.min(
        Math.max(socialsState.friends.currentPage + inc, 1),
        socialsState.friends.maxPage,
      );

      return {
        ...socialsState,
        friends: {
          ...socialsState.friends,
          currentPage: newPage,
        },
      };
    },
  ),

  on(
    SocialsFriendsActions.updateDisplayedFriends,
    (socialsState: SocialsState) => {
      const startIndex = (socialsState.friends.currentPage - 1) * nbFriendsPage;

      return {
        ...socialsState,
        friends: {
          ...socialsState.friends,
          displayed: socialsState.friends.list.slice(
            startIndex,
            startIndex + nbFriendsPage,
          ),
        },
      };
    },
  ),

  on(
    SocialsFriendsActions.updateDisplayedFriendRequests,
    (socialsState: SocialsState) => {
      return {
        ...socialsState,
        friendRequests: {
          ...socialsState.friendRequests,
          displayed: socialsState.friendRequests.list.slice(
            0,
            nbFriendRequestsPage,
          ),
        },
      };
    },
  ),

  on(
    SocialsFriendsActions.addFriendRequest,
    (socialsState: SocialsState, { friendRequest }) => ({
      ...socialsState,
      friendRequests: {
        ...socialsState.friendRequests,
        list: [
          ...socialsState.friendRequests.list.filter(
            (request) => request.name !== friendRequest.name,
          ),
          friendRequest,
        ],
      },
    }),
  ),

  on(
    SocialsFriendsActions.removeFriendRequest,
    (socialsState: SocialsState, { name }) => {
      return {
        ...socialsState,
        friendRequests: {
          ...socialsState.friendRequests,
          list: socialsState.friendRequests.list.filter(
            (friend) => friend.name !== name,
          ),
        },
      };
    },
  ),

  on(
    SocialsFriendsActions.addFriend,
    (socialsState: SocialsState, { playerFriend }) => ({
      ...socialsState,
      friends: {
        ...socialsState.friends,
        list: sortFriends([
          playerFriend,
          ...socialsState.friends.list.filter(
            (friend) => friend.name !== playerFriend.name,
          ),
        ]),
      },
    }),
  ),

  on(
    SocialsFriendsActions.deleteFriendSuccess,
    (socialsState: SocialsState, { name }) => {
      return {
        ...socialsState,
        friends: {
          ...socialsState.friends,
          list: sortFriends(
            socialsState.friends.list.filter((friend) => friend.name !== name),
          ),
        },
      };
    },
  ),

  on(
    SocialsFriendsActions.setFriendOnlineStatus,
    (socialsState: SocialsState, { name, online }) => {
      return {
        ...socialsState,
        friends: {
          ...socialsState.friends,
          list: sortFriends(
            socialsState.friends.list.map((friend) =>
              friend.name === name ? { ...friend, online } : friend,
            ),
          ),
          displayed: sortFriends(
            socialsState.friends.displayed.map((friend) =>
              friend.name === name ? { ...friend, online } : friend,
            ),
          ),
        },
      };
    },
  ),

  on(
    SocialsGroupActions.setGroupRequests,
    (socialsState: SocialsState, { groupRequests }) => {
      return {
        ...socialsState,
        groupRequests,
      };
    },
  ),

  on(
    SocialsGroupActions.addGroupRequest,
    (socialsState: SocialsState, { groupRequest }) => {
      return {
        ...socialsState,
        groupRequests: [...socialsState.groupRequests, groupRequest],
      };
    },
  ),

  on(
    SocialsGroupActions.removeGroupRequest,
    (socialsState: SocialsState, { name }) => {
      return {
        ...socialsState,
        groupRequests: socialsState.groupRequests.filter(
          (groupR) => groupR.name !== name,
        ),
      };
    },
  ),

  on(SocialsGroupActions.setGroup, (socialsState: SocialsState, { group }) => {
    return {
      ...socialsState,
      groupRequests: [],
      group: {
        ...socialsState.group,
        ...group,
      },
    };
  }),

  on(
    SocialsGroupActions.createGroup,
    (socialsState: SocialsState, { localPlayer, playerGroup }) => {
      return {
        ...socialsState,
        groupRequests: [],
        group: {
          ...socialsState.group,
          leaderName: playerGroup.name,
          playersInfos: [localPlayer, playerGroup],
        },
      };
    },
  ),

  on(
    SocialsGroupActions.setGroupLeaderSuccess,
    (socialsState: SocialsState, { leader: { name: leaderName } }) => {
      return {
        ...socialsState,
        group: {
          ...socialsState.group,
          leaderName,
        },
      };
    },
  ),

  on(
    SocialsGroupActions.kickGroupPlayerSuccess,
    (socialsState: SocialsState, { name }) => {
      return {
        ...socialsState,
        group: {
          ...socialsState.group,
          playersInfos: socialsState.group.playersInfos.filter(
            (playerInfo) => playerInfo.name !== name,
          ),
        },
      };
    },
  ),

  on(
    SocialsGroupActions.playerLeftGroupSuccess,
    (socialsState: SocialsState, { name }) => {
      return {
        ...socialsState,
        group: {
          ...socialsState.group,
          playersInfos: socialsState.group.playersInfos.filter(
            (playerInfo) => playerInfo.name !== name,
          ),
        },
      };
    },
  ),

  on(SocialsGroupActions.groupDisband, (socialsState: SocialsState) => {
    return {
      ...socialsState,
      group: {
        leaderName: undefined,
        playersInfos: [],
      },
    };
  }),

  on(
    SocialsGroupActions.setPlayerGroupStatusOnline,
    (socialsState: SocialsState, { name, online }) => {
      return {
        ...socialsState,
        group: {
          ...socialsState.group,
          playersInfos: socialsState.group.playersInfos.map((playerInfo) =>
            playerInfo.name === name ? { ...playerInfo, online } : playerInfo,
          ),
        },
      };
    },
  ),
);
