import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { getQuestsInfosByKindAndCode } from '@t12/quest/store/selectors/quest.selectors';
import { map, switchMap, take, filter } from 'rxjs';

@Injectable()
export class InventoryEffects {
  private _orderInventory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        InventoryActions.addItemInInventory,
        InventoryActions.removeItemInInventory,
        InventoryActions.setInventory,
      ),
      map(() => InventoryActions.orderInventory()),
    ),
  );

  private _addItemInInventory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.addItemInInventory),
      switchMap(({ item: { code }, amount }) =>
        this._store.select(getQuestsInfosByKindAndCode('collect', code)).pipe(
          take(1),
          map((questsInfos) => ({
            questsCodes: questsInfos.map((quest) => quest.code),
            amount,
          })),
        ),
      ),
      filter(({ questsCodes }) => questsCodes.length > 0),
      map(({ questsCodes, amount }) =>
        QuestActions.updateGoals({
          questsCodes,
          amount,
          goalKind: 'collect',
        }),
      ),
    ),
  );

  private _removeItemInInventory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.removeItemInInventory),
      switchMap(({ itemCode, amount }) =>
        this._store
          .select(getQuestsInfosByKindAndCode('collect', itemCode))
          .pipe(
            take(1),
            map((questsInfos) => ({
              questsCodes: questsInfos.map((quest) => quest.code),
              amount,
            })),
          ),
      ),
      filter(({ questsCodes }) => questsCodes.length > 0),
      map(({ questsCodes, amount }) =>
        QuestActions.updateGoals({
          questsCodes,
          amount: -amount,
          goalKind: 'collect',
        }),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
