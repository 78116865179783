import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { IJobRecipe } from '@t12/common/job/interfaces/recipe.interface';
import { RecipeDbService } from '@t12/jobs/services/recipe-db/recipe-db.service';
import { JobActions } from '@t12/jobs/store/actions/job/job.actions';
import { ListRecipesActions } from '@t12/jobs/store/actions/recipe/list/list-recipes.actions';
import { getRecipes } from '@t12/jobs/store/selectors/job.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import {
  catchError,
  concatMap,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class ListRecipesEffects {
  private _displayedRecipes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ListRecipesActions.displayedRecipes),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ jobCode }, playerId]) =>
        this._recipeDb.getPlayerJobRecipes(playerId, jobCode).pipe(
          take(1),
          concatMap((recipes: IJobRecipe[]) => [
            JobActions.setActiveJob({ jobCode }),
            ListRecipesActions.setRecipes({ recipes }),
          ]),
          catchError(() => of(ListRecipesActions.setRecipesFailed())),
        ),
      ),
    ),
  );

  private _setRecipesFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ListRecipesActions.setRecipesFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Impossible de récupérer les recettes connues.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _orderedRecipes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        ListRecipesActions.incCurrentPage,
        ListRecipesActions.setActiveRecipeFilter,
        ListRecipesActions.setRecipes,
      ),
      withLatestFrom(this._store.select(getRecipes)),
      filter(([_, recipes]) => recipes.length > 0),
      map(() => ListRecipesActions.orderedRecipes()),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _recipeDb: RecipeDbService,
    private readonly _store: Store,
  ) {}
}
