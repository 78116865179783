import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import { getItemsBank } from '@t12/bank/store/selectors/bank.selectors';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { DayTime } from '@t12/common/world/enums/day-time.enum';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { getContainerItems } from '@t12/container/store/selectors/container.selectors';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { getDialogTexts } from '@t12/dialog/store/selectors/dialog.selectors';
import { EventsActions } from '@t12/events/store/actions/events.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { getHudInventory } from '@t12/overlay/store/selectors/hud-display/hud-display.selectors';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { getQuestsInfos } from '@t12/quest/store/selectors/quest.selectors';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { ShopActions } from '@t12/shop/store/actions/shop/shop.actions';
import { getShopItems } from '@t12/shop/store/selectors/shop.selectors';
import { LocalPlayerSocketService } from '@t12/sockets/services/emitters/local-player-socket/local-player-socket.service';
import { defaultMusic } from '@t12/user/constants/default-music-name.constant';
import { delayResetBlackout } from '@t12/world/constants/delay-reset-blackout.constant';
import { WorldDbService } from '@t12/world/services/world-db/world-db.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  catchError,
  map,
  switchMap,
  mergeMap,
  of,
  take,
  tap,
  withLatestFrom,
  filter,
  delay,
  concatMap,
} from 'rxjs';

@Injectable()
export class WorldEffects {
  private _loadWorld$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorld),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, playerId]) =>
        this._worldDbService.getWorld$(playerId).pipe(
          take(1),
          map((world) => WorldActions.loadWorldSuccess({ world })),
          catchError((error) => of(WorldActions.loadWorldFailed())),
        ),
      ),
    ),
  );

  private _loadWorldFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(WorldActions.loadWorldFailed, WorldActions.teleportToFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Impossible de charger le monde, contactez un administrateur.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _loadWorldSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorldSuccess),
      tap(({ world }) => {
        this._audioService.playBackgroundMusic(world.music || defaultMusic);
      }),
      switchMap(({ world: { characters, eventSteps, time, weather } }) => {
        const actions: Action[] = [
          CharactersActions.setCharacters({ characters }),
        ];

        if (eventSteps)
          actions.push(EventsActions.setEventSteps({ eventSteps }));

        return of(actions);
      }),
      mergeMap((actions) => actions),
    ),
  );

  private _goalWorldExploration$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorldSuccess),
      withLatestFrom(this._store.select(getQuestsInfos)),
      map(([{ world }, questsInfos]) =>
        questsInfos
          .filter(({ goals }) =>
            goals.some(
              ({ kind, entityCode, entityKind, amount, amountTotal }) =>
                kind === 'exploration' &&
                entityCode === world.code &&
                entityKind === 'world' &&
                amount !== amountTotal,
            ),
          )
          .map(({ code }) => code),
      ),
      filter((questsCodes) => !!questsCodes.length),
      map((questsCodes) =>
        QuestActions.updateGoals({
          questsCodes,
          goalKind: 'exploration',
          amount: 1,
        }),
      ),
    ),
  );

  private _teleportTo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.teleportTo),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ scroll }, id]) =>
        this._worldDbService.goToNextWorld(id, scroll).pipe(
          take(1),
          switchMap(({ world, warp }) => [
            WorldActions.teleportToSuccess({ world, warp }),
            scroll
              ? CharactersMoveActions.setLooking({
                  id,
                  kind: CharacterKind.PLAYER,
                  looking: 'down',
                })
              : null,
          ]),
          filter(Boolean),
          catchError(() => of(WorldActions.teleportToFailed())),
        ),
      ),
    ),
  );

  private _teleportToSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.teleportToSuccess),
      withLatestFrom(this._store.select(getPlayerID)),
      tap(([{ world, warp }, playerId]) => {
        this._localPlayerSocketService.playerLeaveWorldAction(
          playerId,
          world.code,
          {
            x: warp.position.x,
            y: warp.position.y,
          },
        );
      }),
      concatMap(([{ world, warp }, id]) => [
        WorldActions.loadWorldSuccess({ world }),
        CharactersMoveActions.setPositionXY({
          id,
          kind: CharacterKind.PLAYER,
          x: warp.position.x,
          y: warp.position.y,
        }),
        WorldActions.teleportCloseHud(),
      ]),
    ),
  );

  private _teleportCloseHud$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.teleportCloseHud),
      withLatestFrom(
        this._store.select(getShopItems),
        this._store.select(getDialogTexts),
        this._store.select(getItemsBank),
        this._store.select(getContainerItems),
        this._store.select(getHudInventory),
      ),
      switchMap(([_, shop, dialog, bank, container, hudInventory]) =>
        [
          shop ? ShopActions.closeShop() : null,
          dialog.length ? DialogActions.endConversation() : null,
          bank.length ? BankActions.closeBank() : null,
          container.length ? ContainerActions.closeContainer() : null,
          hudInventory
            ? HudDisplayActions.hideHud({ name: 'inventory' })
            : null,
        ].filter(Boolean),
      ),
    ),
  );

  private _resetWorld$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorld, WorldActions.teleportTo),
      map(() => CharactersActions.resetCharacters()),
    ),
  );

  private _resetBlackout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.setTime),
      filter(({ time }) => time === DayTime.BLACKOUT),
      delay(delayResetBlackout),
      map(() => {
        return WorldActions.setTime({ time: DayTime.DAY });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _localPlayerSocketService: LocalPlayerSocketService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
    private readonly _worldDbService: WorldDbService,
  ) {}
}
