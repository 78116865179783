import { Player } from '../../../back/src/player/entities/player.entity';
import { IPlayer } from '../../characters/interfaces/player.interface';
import { PlayerGroupInfos } from '../interfaces/player-group-infos.interface';

export const playerToPlayerGroupInfos = (player: IPlayer | Player): PlayerGroupInfos => {
  return {
    id: player.id,
    name: player.name,
    health: player.health,
    mana: player.mana,
    lvl: player.lvl,
    skin: player.skin,
    raceCode: player.raceCode,
    gender: player.gender,
    stats: player.stats,
    online: true,
  };
};
