import { createReducer, on } from '@ngrx/store';
import { sortItemsByTypeNameAmount } from '@t12/common/inventory/functions/sort-items/sortItemsByTypeNameAmount.function';
import { nbItemsPageContainer } from '@t12/container/constants/max-items-page-container.constant';
import { ContainerState, initialContainerState } from '@t12/container/store';
import { ContainerActions } from '@t12/container/store/actions/container.actions';

export const ContainerReducer = createReducer(
  initialContainerState,

  on(ContainerActions.openContainerSuccess, (_, { container }) => ({
    ...container,
    items: [...container.items].sort(sortItemsByTypeNameAmount),
    currentPage: 1,
    maxPage: 1,
  })),

  on(
    ContainerActions.updateItemsDisplayed,
    (containerState: ContainerState) => {
      const maxPage = Math.ceil(
        containerState.items.length / nbItemsPageContainer,
      );
      const currentPage = Math.min(containerState.currentPage, maxPage);
      const startIndex = (currentPage - 1) * nbItemsPageContainer;

      return {
        ...containerState,
        displayedItems: containerState.items
          .slice(startIndex, startIndex + nbItemsPageContainer)
          .sort(sortItemsByTypeNameAmount),
        maxPage,
        currentPage,
      };
    },
  ),

  on(
    ContainerActions.incCurrentPage,
    (containerState: ContainerState, { inc }) => {
      const newPage = Math.min(
        Math.max(containerState.currentPage + inc, 1),
        containerState.maxPage,
      );

      return {
        ...containerState,
        currentPage: newPage,
      };
    },
  ),

  on(
    ContainerActions.removeItem,
    (containerState: ContainerState, { itemCode, amount }) => ({
      ...containerState,
      items: containerState.items
        .map((item) =>
          item.code === itemCode
            ? { ...item, amount: item.amount - amount }
            : item,
        )
        .filter((item) => item.amount > 0)
        .sort(sortItemsByTypeNameAmount),
    }),
  ),

  on(
    ContainerActions.closeContainer,
    (_) =>
      ({
        items: [],
        displayedItems: [],
        currentPage: 1,
        maxPage: 0,
      }) as ContainerState,
  ),
);
