import { createActionGroup, props } from '@ngrx/store';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { Character } from '@t12/common/characters/types/character.type';
import { AttackCharacterResult } from '@t12/common/fight/interfaces/attack-character.interface';
import { AttackKind } from '@t12/common/fight/types/attack-kind.type';
import { HitKind } from '@t12/common/fight/types/hit-kind.type';

export const CharactersAttackActions = createActionGroup({
  source: 'CHARACTER ATTACK',
  events: {
    Attack: props<{
      id: number;
      characterKind: CharacterKind;
      attackKind: AttackKind;
      emitDmg?: boolean;
    }>(),
    'Character attack': props<{
      attackCharacterResult: AttackCharacterResult;
    }>(),

    'Attack physic': props<{
      character: Character;
      emitDmg?: boolean;
    }>(),
    'Attack magic': props<{
      character: Character;
      emitDmg?: boolean;
    }>(),
    'Attack magic failed not enough mana': props<{
      id: number;
      costMana: number;
    }>(),

    'Attack first step': props<{
      character: Character;
      attackKind: AttackKind;
      emitDmg?: boolean;
    }>(),
    'Attack second step': props<{
      character: Character;
      attackKind: AttackKind;
      emitDmg?: boolean;
    }>(),
    'Attack third step': props<{
      character: Character;
      attackKind: AttackKind;
    }>(),

    'Attack try to hit': props<{
      attacker: Character;
      target: Character;
      attackKind: AttackKind;
    }>(),
    'Attack sound': props<{
      attackKind: AttackKind;
      hit: HitKind;
    }>(),

    'Attack hit': props<{
      attacker: Character;
      target: Character;
      attackKind: AttackKind;
      hit: HitKind;
    }>(),
    'Attack deal damage': props<{
      targetId: number;
      targetKind: CharacterKind;
      damage: number;
    }>(),
  },
});
