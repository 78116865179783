import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EquipmentSlot } from '@t12/common/equipments/types/equipment-slot.type';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { IJobRecipe } from '@t12/common/job/interfaces/recipe.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';

export const ListRecipesActions = createActionGroup({
  source: 'LIST-RECIPES',
  events: {
    'Displayed recipes': props<{ jobCode: JobCode }>(),
    'Set recipes': props<{ recipes: IJobRecipe[] }>(),
    'Set recipes failed': emptyProps(),
    'Ordered recipes': emptyProps(),
    'Set active recipe filter': props<{ filter: EquipmentSlot | ItemType }>(),

    'Update filters': emptyProps(),
    'Inc current page': props<{ inc: 1 | -1 }>(),
    'Set max page': props<{ maxPage: number }>(),
  },
});
