import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { AttackCharacterResult } from '@t12/common/fight/interfaces/attack-character.interface';
import { AttackKind } from '@t12/common/fight/types/attack-kind.type';

export const FightActions = createActionGroup({
  source: 'FIGHT',
  events: {
    'Display fight log': props<{ attackResult: AttackCharacterResult }>(),
    'Display fight log block': props<{
      attackerName: string;
      targetName: string;
      hit: { damage: number; kind: AttackKind; reduceDamage: number };
    }>(),
    'Display fight log parry': props<{
      attackerName: string;
      targetName: string;
      hit: { damage: number; kind: AttackKind; reduceDamage: number };
    }>(),
    'Display fight log critical': props<{
      attackerName: string;
      targetName: string;
      hit: { damage: number; kind: AttackKind; reduceDamage: number };
    }>(),
    'Display fight log back stab': props<{
      attackerName: string;
      targetName: string;
      hit: { damage: number; kind: AttackKind };
    }>(),
    'Display fight log hit': props<{
      attackerName: string;
      targetName: string;
      hit: { damage: number; kind: AttackKind };
    }>(),
    'Display fight log miss': props<{
      attackerName: string;
      targetName: string;
    }>(),
    'Display fight log avoid': props<{
      attackerName: string;
      targetName: string;
    }>(),
    'Display fight log failed': emptyProps(),
  },
});
