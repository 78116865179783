import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IMonster } from '@t12/common/characters/interfaces/monster.interface';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { OptionDialog } from '@t12/common/dialog/interfaces/option-dialog.interface';
import { TextDialog } from '@t12/common/dialog/interfaces/text-dialog.interface';

export const DialogActions = createActionGroup({
  source: 'DIALOG',
  events: {
    'Start conversation': props<{ npc: INPC }>(),
    'Start conversation success': props<{
      textsDialog: TextDialog[];
      npc?: INPC | IMonster;
    }>(),
    'Start conversation failed': emptyProps(),

    'Continue conversation': emptyProps(),

    'Choose option': props<{ option: OptionDialog }>(),
    'Set option validate quest disable': props<{ disable: boolean }>(),

    'End conversation': emptyProps(),
    'End conversation success': emptyProps(),

    'Next message': emptyProps(),
    'Next message option': props<{ option: OptionDialog }>(),
  },
});
