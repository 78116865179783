import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@t12/environment';
import { TokenPayload, TokenResponse } from '@t12/user/interfaces/userdetails';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { LocalService } from '@t12/utils/services/local/local.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationDbService {
  constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _localService: LocalService,
  ) {}

  // Arguments : Token
  // Résultat : Crée un compte avec le token reçu
  public register$(user: TokenPayload): Observable<any> {
    return this._request$('post', 'register', user);
  }

  // Arguments : Token
  // Résultat : Connexion avec le token reçu
  public login$(user: TokenPayload): Observable<any> {
    return this._request$('post', 'login', user);
  }

  // Arguments : TokenPayload
  // Résultat : Met à jour le compte renseigné
  public updateUserDetails$(user: TokenPayload): Observable<any> {
    return this._request$('patch', 'update', user);
  }

  public delete$() {
    return this._http.delete(`${environment(`user`).uri}`, {
      headers: {
        authorization: `Bearer ${this._authenticationService.getToken()}`,
      },
    });
  }

  // Arguments : Méthode d'envoi de données, le type de requête et un token si existant.
  // Résultat : Exécute la requête passée en argument
  private _request$(
    method: 'post' | 'get' | 'patch',
    type: 'login' | 'register' | 'update',
    user?: TokenPayload,
  ): Observable<TokenResponse> {
    let base;
    if (method === 'post') {
      base = this._http.post(`${environment(`user/${type}`).uri}`, user, {
        headers: {
          authorization: `Bearer ${this._authenticationService.getToken()}`,
        },
      });
    } else if (method === 'patch') {
      base = this._http.patch(`${environment(`user/${type}`).uri}`, user, {
        headers: {
          authorization: `Bearer ${this._authenticationService.getToken()}`,
        },
      });
    }

    return base.pipe(
      map((data: TokenResponse) => {
        const { access_token: token } = data;
        if (typeof token === 'string')
          this._localService.setJsonValue('t12-token', JSON.stringify(token));
        return data;
      }),
    );
  }
}
