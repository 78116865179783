import { provideHttpClient } from '@angular/common/http';
import {
  ApplicationConfig,
  isDevMode,
  provideAppInitializer,
  inject,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { KeyHandlerService } from '@t12/key-commands/services/key-handler.service';
import { routes } from './app-routing.module';
import { storeOptions, storeReducers } from './store-config';
import { storeEffects } from './store-effect';
import { TranslocoHttpLoader } from './transloco-loader';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(async () => inject(KeyHandlerService)),
    provideAnimations(),
    provideRouter(routes),
    provideHttpClient(),
    provideStore(storeReducers, storeOptions),
    provideStoreDevtools({
      maxAge: 40,
      logOnly: !isDevMode(),
      connectInZone: true,
    }),
    provideEffects(storeEffects),
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: ['fr', 'en'],
        defaultLang: 'fr',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ],
};
