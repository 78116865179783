import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';

export const CharactersMoveActions = createActionGroup({
  source: 'CHARACTER MOVE',
  events: {
    Move: props<{
      id: number;
      kind: CharacterKind;
      direction: Looking;
      force?: true;
    }>(),
    'Move failed': emptyProps(),
    'Move step': props<{
      character: Character;
      direction: Looking;
    }>(),
    'Idle step': props<{ id: number; kind: CharacterKind }>(),

    'Set position x y': props<{
      id: number;
      kind: CharacterKind;
      y: number;
      x: number;
    }>(),
    'Set looking': props<{
      id: number;
      kind: CharacterKind;
      looking: Looking;
    }>(),

    'Set face to player': props<{ id: number; kind: CharacterKind }>(),
    'Set can move': props<{
      id: number;
      kind: CharacterKind;
      canMove: boolean;
    }>(),
  },
});
