import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getPlayerCountItemInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { getHudShop } from '@t12/overlay/store/selectors/hud-display/hud-display.selectors';
import { ShopDbService } from '@t12/shop/services/shop-db/shop-db.service';
import { ShopActions } from '@t12/shop/store/actions/shop/shop.actions';
import {
  switchMap,
  catchError,
  of,
  tap,
  map,
  take,
  filter,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class ShopEffects {
  private _openShop$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.openShop),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ npcCode }, playerId]) =>
        this._shopDbService.getShopNPC(playerId, npcCode).pipe(
          switchMap((shops) => [
            ShopActions.openShopSuccess({ shops }),
            HudDisplayActions.hideHud({ name: 'dialog' }),
            HudDisplayActions.showHud({ name: 'shop' }),
          ]),
          catchError(() => of(ShopActions.openShopFailed())),
        ),
      ),
    ),
  );

  private _openShopFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ShopActions.openShopFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Impossible de récupérer les informations de la boutique',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _updateActiveItemAmount$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        ShopActions.setActiveItem,
        InventoryActions.addItemInInventory,
        InventoryActions.removeItemInInventory,
      ),
      switchMap((action) =>
        this._store.select(getHudShop).pipe(
          take(1),
          filter((hudShop) => hudShop),
          switchMap(() => {
            const itemCode =
              'item' in action ? action.item.code : action.itemCode;

            return this._store
              .select(getPlayerCountItemInventory(itemCode))
              .pipe(
                take(1),
                map((amount) => ShopActions.updateActiveItemAmount({ amount })),
              );
          }),
        ),
      ),
    ),
  );

  private _displayedItems$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        ShopActions.openShopSuccess,
        ShopActions.incCurrentPage,
        ShopActions.setPage,
        ShopActions.setFilter,
        ShopActions.removeItemFromShop,
      ),
      map(() => ShopActions.updateItemsDisplayed()),
    ),
  );

  private _removeItemFromShop$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.removeItemFromShop),
      map(() => ShopActions.resetActiveItem()),
    ),
  );

  private _closeShop$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.closeShop),
      map(() => HudDisplayActions.hideHud({ name: 'shop' })),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _shopDbService: ShopDbService,
    private readonly _store: Store,
  ) {}
}
