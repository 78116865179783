import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TextDialog } from '@t12/common/dialog/interfaces/text-dialog.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  // Argument : ID dialog
  // Résultat : Retourne les infos d'un personnage stocké en base de donnée
  public getDialog(playerId: number, code: string): Observable<TextDialog[]> {
    return this._http.get(
      `${environment(`dialog/${code}/player/${playerId}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<TextDialog[]>;
  }

  // Argument : ID dialog
  // Résultat : Retourne les infos d'un personnage stocké en base de donnée
  public getDialogQuest(
    playerId: number,
    questCode: string,
    npcCode: string,
  ): Observable<TextDialog[]> {
    return this._http.get(
      `${
        environment(`dialog/${questCode}/npc/${npcCode}/player/${playerId}`).uri
      }`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<TextDialog[]>;
  }
}
