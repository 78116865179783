import { ChatLogKind } from '../enums/chat-log-kind.enums';

export const allChatCommands = {
  [ChatLogKind.Roll]: ['roll', 'r'],
  [ChatLogKind.Me]: ['action', 'me'],
  [ChatLogKind.Global]: ['all', 'a', 'ooc', 'g'],
  [ChatLogKind.Local]: ['say', 's', 'l'],
  [ChatLogKind.Pm]: ['mp', 'w', 'pm'],
  [ChatLogKind.Party]: ['g', 'party', 'p'],
  [ChatLogKind.Yell]: ['y', 'yell'],
};
