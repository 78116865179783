import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getPlayerPositionInFront } from '@t12/characters/store/selectors/characters.selectors';
import { Tile } from '@t12/common/world/interfaces/tile.interface';
import { WorldState } from '@t12/world/store';

const selectRoot = createFeatureSelector<WorldState>('world');

export const getWorldWeather = createSelector(
  selectRoot,
  ({ weather }: WorldState) => weather,
);

export const getWorldEnableWeather = createSelector(
  selectRoot,
  ({ enableWeather }: WorldState) => enableWeather,
);

export const getWorldTime = createSelector(
  selectRoot,
  ({ time }: WorldState) => time,
);

export const getWorldLoadingStatus = createSelector(
  selectRoot,
  ({ loadingStatus }: WorldState) => loadingStatus,
);

export const getWorldMusic = createSelector(
  selectRoot,
  ({ music }: WorldState) => music,
);

export const getWorldTiles = createSelector(
  selectRoot,
  ({ tiles }: WorldState) => tiles,
);

export const getWorldTileAt = (x: number, y: number) =>
  createSelector(getWorldTiles, (tiles: Tile[][] | undefined) => {
    if (!tiles) return undefined;
    if (tiles[y]?.[x]) return tiles[y][x];

    return undefined;
  });

export const getTileInFrontOfPlayer = createSelector(
  getWorldTiles,
  getPlayerPositionInFront,
  (worldTiles, position) => {
    return worldTiles?.[position?.y]?.[position?.x];
  },
);
