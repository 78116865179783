import { Injectable } from '@angular/core';
import {
  partyPrefix,
  globalPrefix,
} from '@t12/chat/constants/chat-prefix.constant';
import { ChatLogColor } from '@t12/common/chat/enums/chat-log-color.enums';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';

@Injectable({
  providedIn: 'root',
})
export class ChatCommandsService {
  constructor(private _notificationService: NotificationManagerService) {}

  public formatRollCommand(chat: ChatLog): ChatLog {
    const max = parseInt(chat.text.split(' ')[1], 10) || 100;
    const rand = Math.floor(Math.random() * max);

    return {
      ...chat,
      text: ` a fait un roll de ${rand} sur ${max}.`,
      layout: {
        ...chat.layout,
        textColor: ChatLogColor.Roll,
      },
    };
  }

  public formatMeCommand(chat: ChatLog): ChatLog {
    return {
      ...chat,
      text: chat.text.startsWith('/')
        ? this.cleanFormatText(chat.text.split(' ').slice(1).join(' '), false)
        : this.cleanFormatText(chat.text),
      layout: {
        nameColor: ChatLogColor.Me,
        textColor: ChatLogColor.Me,
      },
    };
  }

  public formatGlobalCommand(chat: ChatLog): ChatLog {
    const updatedChat: ChatLog = {
      ...chat,
      text: chat.text.startsWith('/')
        ? chat.text.split(' ').slice(1).join(' ').trim()
        : chat.text,
      name: `${globalPrefix} ${chat.name}`,
    };

    updatedChat.text = this.cleanFormatText(updatedChat.text);
    return updatedChat;
  }

  public formatSayCommand(chat: ChatLog): ChatLog {
    const updatedChat: ChatLog = {
      ...chat,
      text: chat.text.startsWith('/')
        ? chat.text.split(' ').slice(1).join(' ').trim()
        : chat.text,
    };

    updatedChat.text = this.cleanFormatText(updatedChat.text);
    return updatedChat;
  }

  public formatPmCommand(
    chat: ChatLog,
    localPlayerName: string,
    pmPlayerName: string,
  ): ChatLog {
    const text = chat.text.startsWith('/')
      ? chat.text.split(' ').slice(2).join(' ').trim()
      : chat.text;

    return {
      ...chat,
      name:
        localPlayerName === chat.name ? `À ${pmPlayerName}` : `De ${chat.name}`,
      pmName: pmPlayerName,
      text: this.cleanFormatText(text),
    };
  }

  public formatPartyCommand(chat: ChatLog): ChatLog {
    const updatedChat: ChatLog = {
      ...chat,
      name: `${partyPrefix} ${chat.name}`,
      text: chat.text.startsWith('/')
        ? chat.text.split(' ').slice(1).join(' ').trim()
        : chat.text,
    };

    updatedChat.text = this.cleanFormatText(updatedChat.text);
    return updatedChat;
  }

  public formatLogMessage(chat: ChatLog): ChatLog {
    return {
      ...chat,
      name: ``,
      text: this.cleanFormatText(chat.text),
      layout: {
        nameColor: ChatLogColor.Log,
        textColor: ChatLogColor.Log,
      },
    };
  }

  public formatYellCommand(chat: ChatLog): ChatLog {
    const text = chat.text.split(' ').slice(1).join(' ').trim();

    return {
      ...chat,
      layout: {
        ...chat.layout,
        textColor: ChatLogColor.Yell,
      },
      text: this.cleanFormatText(text.toUpperCase()),
    };
  }

  public formatBonusCommand(chat: ChatLog): ChatLog {
    return {
      ...chat,
      layout: {
        ...chat.layout,
        nameColor: ChatLogColor.Bonus,
      },
      text: this.cleanFormatText(chat.text, false),
    };
  }

  public formatMalusCommand(chat: ChatLog): ChatLog {
    return {
      ...chat,
      layout: {
        ...chat.layout,
        nameColor: ChatLogColor.Malus,
      },
      text: this.cleanFormatText(chat.text, false),
    };
  }

  public cleanFormatText(textChat: string, uppercase: boolean = true): string {
    let formatTextChat = uppercase
      ? textChat.charAt(0).toUpperCase() + textChat.slice(1)
      : textChat;
    const lastCharacter = formatTextChat.charAt(formatTextChat.length - 1);
    const endCharacter = ['?', '!', '.'];

    if (!endCharacter.includes(lastCharacter)) {
      formatTextChat = `${formatTextChat}.`;
    }

    return formatTextChat.split(/\s+/).join(' ');
  }
}
