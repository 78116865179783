import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';
import { Dayjs } from 'dayjs';

export const ChatActions = createActionGroup({
  source: 'CHAT',
  events: {
    'Set tab chat': props<{ tab: ChatTab }>(),
    'Set scope chat': props<{ scopeChat: ChatLogScope }>(),
    'Set pm player': props<{ name: string }>(),
    'Remove pm player': emptyProps(),

    'Add message': props<{ text: string }>(),
    'Add message failed empty': emptyProps(),
    'Add message failed too short': emptyProps(),
    'Add message failed unknown command': emptyProps(),

    'Add chat log': props<{
      tab: ChatTab;
      kind: ChatLogKind;
      name: string;
      text: string;
    }>(),
    'Add chat log success': props<{
      chatLog: ChatLog;
    }>(),
    'Receive chat log': props<{
      chatLog: ChatLog;
    }>(),

    'Inc new message': emptyProps(),
    'Reset new message': emptyProps(),

    'Delete active logs': emptyProps(),

    'Explain chat': props<{ lastConnexionDate: Dayjs }>(),
  },
});
