import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Item } from '@t12/common/item/interfaces/item.interface';

export const InventoryActions = createActionGroup({
  source: 'INVENTORY',
  events: {
    'Set active item': props<{ index: number }>(),
    'Reset active item': emptyProps(),
    'Set inventory': props<{ items: Item[] }>(),
    'Order inventory': emptyProps(),

    'Add item in inventory': props<{
      item: Item;
      amount: number;
    }>(),
    'Add items in inventory': props<{
      items: Item[];
    }>(),

    'Remove item in inventory': props<{
      itemCode: string;
      amount: number;
    }>(),
    'Remove items in inventory': props<{
      items: Item[];
    }>(),

    'Pick item success': props<{ item: Item }>(),
    'Pick item failed not enough place': emptyProps(),
    'Pick item failed': emptyProps(),

    'Drop item success': props<{ item: Item; amount: number }>(),
    'Drop item failed not enough': emptyProps(),
    'Drop item failed tile invalid': emptyProps(),

    'Use item failed': emptyProps(),
    'Use item failed dead': emptyProps(),
    'Use item failed no item': emptyProps(),
    'Use item failed not enough lvl': emptyProps(),

    'Consume item': props<{ item: Item }>(),
    'Consume item health': props<{
      item: Item;
    }>(),
    'Consume item health failed': emptyProps(),
    'Consume item mana': props<{
      item: Item;
    }>(),
    'Consume item mana failed': emptyProps(),
    'Consume item health and mana': props<{
      item: Item;
    }>(),
    'Consume item health and mana failed': emptyProps(),
    'Consume item warp': props<{
      itemCode: string;
    }>(),
    'Consume item sound': props<{ item: Item }>(),
    'Consume item failed': emptyProps(),

    'Set favorite': props<{ itemCode: string }>(),
    'Set favorite failed no item': emptyProps(),
    'Set favorite failed no more slot': emptyProps(),
    'Set favorite failed wrong kind': emptyProps(),
  },
});
