import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import {
  getPlayerID,
  getPlayerName,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { getScopeChat } from '@t12/chat/store/selectors/chat.selectors';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { getPlayersGroupSize } from '@t12/socials/store/selectors/socials.selectors';
import {
  catchError,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
  take,
  filter,
} from 'rxjs';
import { SocialsDbService } from '../../../services/socials-db/socials-db.service';
import { SocialsGroupActions } from '../../actions/group/socials-group.actions';

@Injectable()
export class GroupEffects {
  private _getGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.getGroup),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, playerId]) =>
        this._socialsDbService.getPlayerGroup(playerId).pipe(
          take(1),
          map((group) => SocialsGroupActions.setGroup({ group })),
          catchError(({ error }: HttpErrorResponse) =>
            of(SocialsGroupActions.getGroupFailed()),
          ),
        ),
      ),
    ),
  );

  private _getGroupFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.getGroupFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Impossible de récupérer les infos de votre groupe.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _setGroupLeader$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.setGroupLeader),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ leaderName }, playerId]) =>
        this._socialsDbService.changeGroupLeader(playerId, leaderName).pipe(
          take(1),
          map((leader) =>
            SocialsGroupActions.setGroupLeaderSuccess({
              leader: { name: leader.name },
            }),
          ),
          catchError(({ error }: HttpErrorResponse) => {
            let action: Action;
            switch (error?.message) {
              case 'NOT_IN_GROUP':
                action = SocialsGroupActions.failedNotInGroup();
                break;
              case 'NOT_LEADER_GROUP':
                action = SocialsGroupActions.failedNotLeader();
                break;
              case 'ALREADY_LEADER_GROUP':
                action =
                  SocialsGroupActions.setGroupLeaderFailedAlreadyLeader();
                break;
              default:
                action = SocialsGroupActions.setGroupLeaderFailed();
            }
            return of(action);
          }),
        ),
      ),
    ),
  );

  private _setGroupLeaderSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.setGroupLeaderSuccess),
        tap(({ leader }) => {
          this._notificationService.addNotification(
            'settings',
            `${leader.name} est chef de groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _failedNotInGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.failedNotInGroup),
      tap(() => {
        this._notificationService.addNotification(
          'error',
          `Vous n'êtes pas dans un groupe`,
        );
      }),
      map(() => SocialsGroupActions.groupDisband()),
    ),
  );

  private _failedNotLeader$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.failedNotLeader),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Vous n'êtes pas le chef du groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _setGroupLeaderFailedAlreadyLeader$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.setGroupLeaderFailedAlreadyLeader),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Ce joueur est déjà chef du groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _setGroupLeaderFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.setGroupLeaderFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _leaveGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.leaveGroup),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, playerId]) =>
        this._socialsDbService.leaveGroup(playerId).pipe(
          map(() => SocialsGroupActions.leaveGroupSuccess()),
          catchError(({ error }: HttpErrorResponse) =>
            of(
              error?.message === 'NOT_IN_GROUP'
                ? SocialsGroupActions.failedNotInGroup()
                : SocialsGroupActions.leaveGroupFailed(),
            ),
          ),
        ),
      ),
    ),
  );

  private _leaveGroupFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.leaveGroupFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _leaveGroupSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.leaveGroupSuccess),
      tap(() => {
        this._notificationService.addNotification(
          'settings',
          'Vous avez quitté le groupe',
        );
      }),
      map(() => SocialsGroupActions.groupDisband()),
    ),
  );

  private _playerJoinGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.playerJoinGroup),
      map(() => SocialsGroupActions.getGroup()),
    ),
  );

  private _playerJoinGroupNotification$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          SocialsGroupActions.createGroup,
          SocialsGroupActions.playerJoinGroup,
        ),
        withLatestFrom(this._store.select(getPlayerName)),
        tap(([action, localPlayerName]) => {
          let playerName: string;
          if (action.type === SocialsGroupActions.createGroup.type) {
            playerName = action.localPlayer.name;
          } else playerName = action.player.name;

          const text =
            playerName === localPlayerName
              ? 'Vous avez rejoint le groupe'
              : `${playerName} a rejoint le groupe`;

          this._notificationService.addNotification('settings', text);
        }),
      ),
    { dispatch: false },
  );

  private _playerLeftGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.playerLeftGroup),
      withLatestFrom(this._store.select(getPlayersGroupSize)),
      map(([{ name }, groupSize]) => {
        if (groupSize <= 2) return SocialsGroupActions.groupDisband();
        else return SocialsGroupActions.playerLeftGroupSuccess({ name });
      }),
    ),
  );

  private _playerLeftGroupSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.playerLeftGroupSuccess),
        tap(({ name }) => {
          this._notificationService.addNotification(
            'settings',
            `${name} a quitté le groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _kickGroupPlayer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.kickGroupPlayer),
      withLatestFrom(
        this._store.select(getPlayerID),
        this._store.select(getPlayersGroupSize),
      ),
      switchMap(([{ name }, playerId, groupSize]) =>
        this._socialsDbService.kickGroupPlayer(playerId, name).pipe(
          map(() => {
            if (groupSize <= 2) return SocialsGroupActions.groupDisband();
            else return SocialsGroupActions.kickGroupPlayerSuccess({ name });
          }),
          catchError(({ error }: HttpErrorResponse) =>
            of(
              error?.message === 'NOT_IN_GROUP'
                ? SocialsGroupActions.failedNotInGroup()
                : error?.message === 'NOT_LEADER_GROUP'
                  ? SocialsGroupActions.failedNotLeader()
                  : SocialsGroupActions.kickGroupPlayerFailed(),
            ),
          ),
        ),
      ),
    ),
  );

  private _kickGroupPlayerSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.kickGroupPlayerSuccess),
        tap(({ name }) => {
          this._notificationService.addNotification(
            'settings',
            `${name} a été exclu du groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _kickFromGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.kickFromGroup),
      tap(() => {
        this._notificationService.addNotification(
          'error',
          `Vous avez retiré du groupe.`,
        );
      }),
      map(() => SocialsGroupActions.groupDisband()),
    ),
  );

  private _groupDisband$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsGroupActions.groupDisband),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Le groupe a été dissous.`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _groupDisbandSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsGroupActions.groupDisband),
      withLatestFrom(this._store.select(getScopeChat)),
      filter(([_, scopeChat]) => scopeChat === ChatLogScope.Party),
      map(() => ChatActions.setScopeChat({ scopeChat: ChatLogScope.Local })),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socialsDbService: SocialsDbService,
    private readonly _store: Store,
  ) {}
}
