import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { EquipmentSlot } from '@t12/common/equipments/types/equipment-slot.type';
import { UserSettings } from '@t12/user/store';
import { getUserSettings } from '@t12/user/store/selectors/user.selectors';
import { Howl } from 'howler';
import { Subject, takeUntil, fromEvent, withLatestFrom } from 'rxjs';
import { VOLUME_MUSIC, VOLUME_NOISES } from '../../constants/volume.constant';

// TODO Actions PlaySound Store ?? Action dédié pour chaque type de son (interface, musique, sfx....)
@Injectable({
  providedIn: 'root',
})
export class AudioManagerService implements OnDestroy {
  private _backgroundSound: Howl;
  private _weatherSound: Howl;
  private _settings: Partial<UserSettings> = {
    music: false,
    noises: false,
  };
  private _isMusicPlaying = false;
  private _musicName: string;
  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(private readonly _store: Store) {
    this._initAudio();
  }

  private _initAudio() {
    this._store
      .select(getUserSettings)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((settings) => {
        this._settings = settings;
      });

    fromEvent(document, 'visibilitychange')
      .pipe(withLatestFrom(this._store.select(getUserSettings)))
      .subscribe(([event, settings]) => {
        this._onChangeVisibility(event, settings);
      });
  }

  public ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  // Arguments : Nom de la musique, forcer le lancement de la musique
  // Résultat : Execute une musique d'ambiance si celle-ci n'est pas déjà lancée
  public playBackgroundMusic(newMusicName?: string): void {
    const canPlayMusic = this._settings.music && !!newMusicName;
    const newMusic = this._musicName !== newMusicName;

    if (canPlayMusic && (!this._isMusicPlaying || newMusic)) {
      if (newMusic) {
        this.changeMusic(newMusicName);
      }
      this._isMusicPlaying = true;
      this._backgroundSound.play();
    }
  }

  // Arguments : Nom de la nouvelle musique
  // Résultat : Stop la musique initiale et charge la prochaine
  public changeMusic(newMusicName: string): void {
    this.stopBackgroundMusic();
    if (this._backgroundSound) this._backgroundSound.unload();

    this._musicName = newMusicName;
    this._backgroundSound = new Howl({
      src: [`assets/sounds/musics/${this._musicName}.mp3`],
      autoplay: true,
      loop: true,
      volume: VOLUME_MUSIC,
    });
  }

  public playWeatherSound(name: string): void {
    if (!this._settings.noises) return;
    this._weatherSound = new Howl({
      src: [`assets/sounds/weathers/${name}.mp3`],
      volume: VOLUME_NOISES,
      loop: true,
    });
    this._weatherSound.play();
  }

  public stopWeatherSound(): void {
    if (!this._weatherSound) return;
    this._weatherSound.stop();
    this._weatherSound.unload();
  }

  // Arguments : Dossier contenant le son, nom du son, type de fichier, volume
  // Résultat : Active un son précis
  public playSound(
    folder: string,
    name: string,
    ext = 'mp3',
    volume = VOLUME_NOISES,
    loop: boolean = false,
  ): void {
    if (!this._settings.noises) return;
    const sound = new Howl({
      src: [`assets/sounds/${folder}/${name}.${ext}`],
      volume,
      loop,
    });
    sound.play();
  }

  // Argument : Type de l'équipement
  // Résultat : Joue un son différent selon l'équipement choisis.
  public playSoundEquip(slot: EquipmentSlot): void {
    this.playSound('interfaces/equipment', slot, 'mp3', VOLUME_NOISES);
  }

  // Argument : ------
  // Résultat : Met en pause la musique
  public stopBackgroundMusic(): void {
    if (this._backgroundSound) {
      this._backgroundSound.pause();
      this._isMusicPlaying = false;
    }
  }

  // Argument : ------
  // Résultat : Met en pause la musique et bloque tous les sons
  public muteAndPause(): void {
    this._settings = {
      music: false,
      noises: false,
    };
    this.stopBackgroundMusic();
  }

  // Argument : Event
  // Résultat : Met en pause ou relance la musique si le focus de la page change
  private _onChangeVisibility(evt, settings: UserSettings): void {
    const evtMap = {
      focus: false,
      focusin: false,
      pageshow: false,
      blur: true,
      focusout: true,
      pagehide: true,
    };

    const windowHidden =
      evt.type in evtMap ? evtMap[evt.type] : document.hidden;

    if (windowHidden) {
      this.muteAndPause();
    } else {
      this._settings = settings;
      if (this._musicName && this._settings.music) {
        this.playBackgroundMusic(this._musicName);
      }
    }
  }
}
