import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BankDbService } from '@t12/bank/services/bank-db/bank-db.service';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import {
  getDepositGold,
  getDepositItem,
  getFreeBankSlotAmount,
  getItemsBank,
} from '@t12/bank/store/selectors/bank.selectors';
import {
  getPlayerGold,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { bankMaxSize } from '@t12/common/bank/constants/bank-max-size.constant';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getPlayerCountItemInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import {
  catchError,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class BankDepositToEffects {
  private _depositTo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositTo),
      switchMap(() =>
        this._store.select(getDepositItem).pipe(
          take(1),
          switchMap((item) =>
            this._store.select(getFreeBankSlotAmount(item)).pipe(
              take(1),

              withLatestFrom(
                this._store.select(getPlayerGold).pipe(take(1)),
                this._store.select(getItemsBank).pipe(take(1)),
                this._store
                  .select(getPlayerCountItemInventory(item.code))
                  .pipe(take(1)),
                this._store.select(getDepositGold).pipe(take(1)),
              ),
              map(([freeSlot, playerGold, bank, amountInv, depositGold]) => {
                if (amountInv < item.amount) {
                  return BankActions.depositToFailedNotEnoughItem();
                } else if (
                  freeSlot < item.amount ||
                  bank.length >= bankMaxSize
                ) {
                  return BankActions.depositToFailedBankFull();
                } else if (playerGold < depositGold) {
                  return BankActions.depositToFailedNotEnoughGold();
                } else {
                  return BankActions.depositToSuccess({
                    item,
                    gold: depositGold,
                  });
                }
              }),
            ),
          ),
        ),
      ),
    ),
  );

  private _depositToSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositToSuccess),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ item, gold }, playerId]) =>
        this._bankDbService.depositToBank(playerId, item, gold).pipe(
          take(1),
          map(() => {
            return [
              item?.amount > 0
                ? BankActions.depositToItemSuccess({ item })
                : null,
              gold > 0 ? BankActions.depositToGoldSuccess({ gold }) : null,
            ].filter((action) => !!action);
          }),
          mergeMap((actions) => actions),
          catchError(() => of(BankActions.depositFail)),
        ),
      ),
    ),
  );

  private _depositToItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositToItemSuccess),
      tap(({ item }) => {
        this._notificationService.addNotification(
          'item',
          `-${item.amount} ${item.name}`,
          5000,
          item.img,
        );
      }),
      map(({ item }) =>
        InventoryActions.removeItemInInventory({
          itemCode: item.code,
          amount: item.amount,
        }),
      ),
    ),
  );

  private _depositToGoldSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.depositToGoldSuccess),
      tap(({ gold }) => {
        this._notificationService.addNotification(
          'item',
          `Vous avez déposé ${gold}G`,
          5000,
        );
      }),
      map(({ gold }) => LocalPlayerActions.addGold({ gold: -gold })),
    ),
  );

  private _depositToFailedBankFull$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BankActions.depositToFailedBankFull),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Votre banque est pleine.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _depositToFailedNotEnoughGold$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BankActions.depositToFailedNotEnoughGold),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez d'or.",
          ),
        ),
      ),
    { dispatch: false },
  );

  private _depositToFailedNotEnoughItem$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BankActions.depositToFailedNotEnoughItem),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez d'exemplaire de l'objet",
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _bankDbService: BankDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
