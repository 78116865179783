import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuestGoalKind } from '@t12/common/quest/types/quest-goal-kind.type';
import { QuestState } from '@t12/quest/store';

const selectRoot = createFeatureSelector<QuestState>('quest');

export const getQuestsInfos = createSelector(
  selectRoot,
  ({ quests }: QuestState) => quests,
);

export const getQuestsInfosByCode = (questCode: string) =>
  createSelector(getQuestsInfos, (quests) =>
    quests.find((quest) => quest.code === questCode),
  );

export const getQuestsInfosByKindAndCode = (
  goalKind: QuestGoalKind,
  entityCode: string,
) =>
  createSelector(getQuestsInfos, (quests) =>
    quests.filter(
      (quest) =>
        quest.goals.some((goal) => goal.entityCode === entityCode) &&
        quest.goals.some((goal) => goal.kind === goalKind),
    ),
  );

export const areQuestGoalsDone = (questCode: string) =>
  createSelector(
    getQuestsInfos,
    (quests) =>
      !!quests.find(
        (quest) =>
          quest.code === questCode &&
          quest.goals
            .filter((goal) => !goal.optional)
            .every((goal) => goal.amount === goal.amountTotal),
      ),
  );
