import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { delayActionAnimation } from '@t12/characters/constants/delay-animation.constant';
import {
  getPlayerID,
  getPlayerCanMove,
  getPlayer,
} from '@t12/characters/store/selectors/characters.selectors';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { getEventsProgressStatus } from '@t12/events/store/selectors/events.selectors';
import { getHotkeys } from '@t12/key-commands/store/hotkey/selectors/hotkey.selectors';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import { FullscreenManagerService } from '@t12/settings/services/fullscreen/fullscreen-manager.service';
import { ProgressStatus } from '@t12/utils/enums/progress-status.enum';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import {
  filter,
  fromEvent,
  switchMap,
  take,
  tap,
  withLatestFrom,
  throttleTime,
} from 'rxjs';
import { map } from 'rxjs/operators';
import {
  attackKey,
  moveKey,
  interactionKey,
} from '../constants/key-bindings.constant';
import { actionKey } from '../types/action-key.type';

@Injectable({ providedIn: 'root' })
export class KeyHandlerService {
  private _intervalTickInput: NodeJS.Timeout;

  constructor(
    private readonly _fullscreenService: FullscreenManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {
    this.initCommands();
  }

  public initCommands() {
    console.log('tuile');
    fromEvent<MouseEvent>(document, 'contextmenu').subscribe((event) => {
      event.preventDefault();
    });

    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        throttleTime(138),
        filter(() => {
          const activeElement = document.activeElement as HTMLElement;
          return (
            !['INPUT', 'TEXTAREA'].includes(activeElement?.tagName) &&
            activeElement?.getAttribute('contenteditable') !== 'true'
          );
        }),
        map((event: KeyboardEvent) => ({
          key: event.key.toLowerCase(),
          event,
        })),
        withLatestFrom(
          this._store.select(getPlayerID),
          this._store.select(getEventsProgressStatus),
        ),
        filter(
          ([{ key }, _, eventStatus]) =>
            key === ' ' || eventStatus !== ProgressStatus.IN_PROGRESS,
        ),
        tap(([{ key, event }, playerId]) => {
          // In-game logic
          if (playerId) {
            if (key === 'i') {
              this._store.dispatch(
                HudDisplayActions.toggleHud({ name: 'inventory' }),
              );
            } else if (key === 'l') {
              this._store.dispatch(
                HudDisplayActions.toggleHud({ name: 'quests' }),
              );
              this._store.dispatch(
                HudDisplayActions.hideHud({ name: 'inventory' }),
              );
            } else if (key === 'j') {
              this._store.dispatch(
                HudDisplayActions.toggleHud({ name: 'jobs' }),
              );
              this._store.dispatch(
                HudDisplayActions.hideHud({ name: 'playerInfos' }),
              );
            } else if (key === 'c') {
              this._store.dispatch(
                HudDisplayActions.toggleHud({ name: 'playerInfos' }),
              );
              this._store.dispatch(HudDisplayActions.hideHud({ name: 'jobs' }));
            } else if (key === 'p') {
              this._store.dispatch(
                HudDisplayActions.toggleHud({ name: 'socials' }),
              );
            } else if (key === 'escape') {
              this._store.dispatch(DialogActions.endConversation());
              this._store.dispatch(HudDisplayActions.closeAllHud());
            } else if (key === 'y') {
              event.preventDefault();
              this._store.dispatch(HudDisplayActions.showHud({ name: 'chat' }));
            } else {
              const action = this.identifyAction(key);
              if (action) {
                const interval =
                  action[0] === 'hotkey' ? 3000 : delayActionAnimation;

                if (!this._intervalTickInput) {
                  this.executeKey(action);

                  if (key !== ' ') {
                    this._intervalTickInput = setInterval(
                      () => this.executeKey(action),
                      interval,
                    );
                  }
                }
              }
            }
          }

          // Everywhere logic
          if (key === 'f11') {
            event.preventDefault();
            this._fullscreenService.switchFullscreen();
          }
        }),
      )
      .subscribe();

    fromEvent<KeyboardEvent>(document, 'keyup')
      .pipe(
        throttleTime(138),
        filter(() => {
          const activeElement = document.activeElement as HTMLElement;
          return (
            !['INPUT', 'TEXTAREA'].includes(activeElement?.tagName) &&
            activeElement?.getAttribute('contenteditable') !== 'true'
          );
        }),
        withLatestFrom(this._store.select(getPlayer)),
        filter(([_, player]) => !!player),
        tap(() => this._clearTimer()),
        map(([event, player]) => ({
          action: this.identifyAction(event.key.toLowerCase()),
          player,
        })),
        filter(({ action }) => action?.[0] === 'move'),
        switchMap(({ player }) =>
          this._playerDbService.updatePlayer(player).pipe(take(1)),
        ),
      )
      .subscribe();
  }

  // Argument : Touche de clavier
  // Résultat : Retourne l'action selon la touche saisie et la direction ou le type d'attaque
  public identifyAction(key: string): actionKey {
    const direction = this._getActionByKey(moveKey, key);
    if (direction) return ['move', direction];

    const attack = this._getActionByKey(attackKey, key);
    if (attack) return ['attack', attack];

    const interaction = this._getActionByKey(interactionKey, key);
    if (interaction) return ['interaction'];

    const hotkeys = this._utils.getSelect(getHotkeys);
    const hotkey = hotkeys.find((hotkeyItem) => hotkeyItem.key === key);
    if (hotkey) return ['hotkey', hotkey];

    return null;
  }

  // Argument : Touche de clavier
  // Résultat : Execute une action selon la touche de clavier renseignée
  public executeKey(action: actionKey): void {
    const playerCanMove = this._utils.getSelect(getPlayerCanMove);
    if (action[0] === 'move' && playerCanMove) {
      this._store.dispatch(
        LocalPlayerActions.localPlayerMove({ direction: action[1] }),
      );
    } else if (action[0] === 'attack' && playerCanMove) {
      this._store.dispatch(
        LocalPlayerActions.localPlayerAttack({
          attackKind: action[1],
        }),
      );
    } else if (action[0] === 'interaction') {
      this._store.dispatch(LocalPlayerActions.interaction());
    } else if (action[0] === 'hotkey' && playerCanMove) {
      this._store.dispatch(
        LocalPlayerActions.useItem({ itemCode: action[1].entity.code }),
      );
    }
  }

  // Argument : Touche de clavier
  // Résultat : Retourne la direction de déplacement ou le type d'attaque selon la touche saisie
  private _getActionByKey<T extends string>(
    keyBinds: Record<T, string[]>,
    key: string,
  ): T | undefined {
    return Object.keys(keyBinds).find((keyObj) =>
      keyBinds[keyObj as T].includes(key),
    ) as T | undefined;
  }

  // Arguments : ------
  // Résultat : Supprime l'intervalle de tick des  inputs
  private _clearTimer(): void {
    clearInterval(this._intervalTickInput);
    this._intervalTickInput = undefined;
  }
}
