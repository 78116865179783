import { createActionGroup, props } from '@ngrx/store';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { JobLearn } from '@t12/common/job/interfaces/learn-knowlegde.interface';

export const KnowledgeActions = createActionGroup({
  source: 'KNOWLEDGE',
  events: {
    'Learn knowledge': props<{ item: Item }>(),
    'Learn knowledge job ': props<{ itemCode: string; learn: JobLearn }>(),
    'Learn knowledge recipe': props<{ item: Item }>(),
  },
});
