import { AttackCharacterEffects } from '@t12/characters/store/effects/attack/attack-character.effects';
import { CharacterEffects } from '@t12/characters/store/effects/characters/character.effects';
import { FightCharacterEffects } from '@t12/characters/store/effects/fight/fight-character.effects';
import { MoveCharacterEffects } from '@t12/characters/store/effects/move/move-character.effects';
import { PlayerCharacterEffects } from '@t12/characters/store/effects/player/player-character.effects';
import { InventoryFavoriteEffects } from '@t12/inventory/store/effects/inventory-favorite/inventory-favorite.effects';

export const CharactersCombinedEffects = [
  AttackCharacterEffects,
  CharacterEffects,
  FightCharacterEffects,
  MoveCharacterEffects,
  PlayerCharacterEffects,
  InventoryFavoriteEffects,
];
