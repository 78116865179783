import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { JobDbService } from '@t12/jobs/services/job-db/job-db.service';
import { JobActions } from '@t12/jobs/store/actions/job/job.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import {
  map,
  switchMap,
  tap,
  take,
  catchError,
  of,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class JobsEffects {
  private _learnJob$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.learnJob),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ itemCode }, playerId]) =>
        this._jobDbService.addJob(playerId, itemCode).pipe(
          take(1),
          map((job: PlayerJob) =>
            JobActions.learnJobSuccess({ job, itemCode }),
          ),
          catchError(() => of(JobActions.learnJobFailed())),
        ),
      ),
    ),
  );

  private _learnJobSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.learnJobSuccess),
      tap(({ job }) => {
        this._notificationService.addNotification(
          'job',
          `Nouveau métier appris: ${job.name}`,
        );
      }),
      switchMap(({ job, itemCode }) => [
        InventoryActions.removeItemInInventory({ itemCode, amount: 1 }),
        LocalPlayerActions.addNewJob({ job }),
      ]),
    ),
  );

  private _learnJobFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.learnJobFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _learnJobFailedReachedMax$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.learnJobFailedReachedMax),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous ne pouvez apprendre que deux métiers d'artisanat.",
          );
        }),
      ),
    { dispatch: false },
  );

  private _learnJobFailedAlreadyKnow$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.learnJobFailedAlreadyKnow),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous connaissez déjà ce métier.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _forgetJob$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.forgetJob),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ job }, playerId]) =>
        this._jobDbService.removeJob(playerId, job.code).pipe(
          take(1),
          map(() => {
            this._notificationService.addNotification(
              'job',
              `Vous avez oublié le métier: ${job.name}`,
            );
            return LocalPlayerActions.removeJob({ job });
          }),
          catchError(() => of(JobActions.forgetJobFailed())),
        ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _jobDbService: JobDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
