import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { delayExpirationLogged } from '@t12/user/constants/delay-expiration-logged.constant';
import { UserState } from '@t12/user/store';
import { UserActions } from '@t12/user/store/actions/user.actions';
import { LocalService } from '@t12/utils/services/local/local.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private readonly _localService: LocalService,
    private readonly _store: Store,
  ) {}

  public getUserDetails(): UserState {
    const token = this.getToken();
    let payload;

    if (token) {
      payload = window.atob(token.split('.')[1]);
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
    if (user?.exp > Date.now() / delayExpirationLogged) {
      return true;
    } else {
      this._store.dispatch(UserActions.logout());
      return false;
    }
  }

  public getToken(): string {
    return this._localService.getJsonValue('t12-token');
  }
}
