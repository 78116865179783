import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { SocketPickItem } from '@t12/common/container/interfaces/socket-pick-item.interface';
import { SocketRemoveContainer } from '@t12/common/container/interfaces/socket-remove-container.interface';
import { PlayerActionLeaveWorld } from '@t12/common/player/constants/player-action-leave-world-socket.interface';
import { PlayerLookingActionSocket } from '@t12/common/player/constants/player-looking-action-socket.interface';
import { PlayerMoveActionSocket } from '@t12/common/player/constants/player-move-action-socket.interface';
import { PlayerUpdateInfosActionSocket } from '@t12/common/player/constants/player-update-infos-action-socket.interface';
import { PlayerLogged } from '@t12/common/socials/interfaces/player-logged.interface';
import { SocialsActions } from '@t12/socials/store/actions/socials/socials.actions';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import { filter } from 'rxjs';
import { SocketService } from '../../socket.service';

@Injectable({
  providedIn: 'root',
})
export class PlayerSocketService {
  constructor(
    private readonly _store: Store,
    private readonly _socketService: SocketService,
  ) {}

  public init() {
    this._listenForPlayerMoveActions();
    this._listenForPlayerLookingActions();
    this._listenForPlayerLoggedInActions();
    this._listenForPlayerLoggedOutActions();
    this._listenForPlayerEnterWorldInActions();
    this._listenForPlayerLeftWorldInActions();
    this._listenForContainerPlayerActions();
    this._listenForPlayerUpdateInfosActions();
    this._listenForPlayerLvlUpActions();
  }

  private _listenForPlayerMoveActions() {
    const playerMove$ = this._socketService
      .fromEvent<PlayerMoveActionSocket>('player-move')
      .pipe(filter((playerMove) => !!playerMove))
      .subscribe(({ id, direction, position: { x, y } }) => {
        this._store.dispatch(
          CharactersMoveActions.setPositionXY({
            id,
            kind: CharacterKind.PLAYER,
            x,
            y,
          }),
        );
        this._store.dispatch(
          CharactersMoveActions.move({
            id,
            kind: CharacterKind.PLAYER,
            direction,
            force: true,
          }),
        );
      });

    this._socketService.addSubscription(playerMove$);
  }

  private _listenForPlayerLookingActions() {
    const playerLooking$ = this._socketService
      .fromEvent<PlayerLookingActionSocket>('player-looking')
      .pipe(filter((playerLooking) => !!playerLooking))
      .subscribe(({ id, looking }) => {
        this._store.dispatch(
          CharactersMoveActions.setLooking({
            id,
            kind: CharacterKind.PLAYER,
            looking,
          }),
        );
      });
    this._socketService.addSubscription(playerLooking$);
  }

  private _listenForPlayerLoggedInActions() {
    const playerLoggedIn$ = this._socketService
      .fromEvent<PlayerLogged>('player-logged-in')
      .pipe(filter((playerLoggedIn) => !!playerLoggedIn))
      .subscribe((player) => {
        this._store.dispatch(SocialsActions.playerLoggedIn({ player }));
      });
    this._socketService.addSubscription(playerLoggedIn$);
  }

  private _listenForPlayerLoggedOutActions() {
    const playerLoggedOut$ = this._socketService
      .fromEvent<PlayerLogged>('player-logged-out')
      .pipe(filter((playerLoggedOut) => !!playerLoggedOut))
      .subscribe((player) => {
        this._store.dispatch(SocialsActions.playerLoggedOut({ player }));
      });
    this._socketService.addSubscription(playerLoggedOut$);
  }

  private _listenForPlayerEnterWorldInActions() {
    const playerEnterWorld$ = this._socketService
      .fromEvent<PlayerActionLeaveWorld>('player-enter-world')
      .pipe(filter((playerEnterWorld) => !!playerEnterWorld))
      .subscribe(({ id, position }) => {
        this._store.dispatch(CharactersActions.addPlayer({ id, position }));
      });
    this._socketService.addSubscription(playerEnterWorld$);
  }

  private _listenForPlayerLeftWorldInActions() {
    const playerLeftWorld$ = this._socketService
      .fromEvent<PlayerActionLeaveWorld>('player-leave-world')
      .pipe(filter((playerLeaveWorld) => !!playerLeaveWorld))
      .subscribe(({ id }) => {
        this._store.dispatch(
          CharactersActions.removeCharacterById({
            id,
            kind: CharacterKind.PLAYER,
          }),
        );
      });
    this._socketService.addSubscription(playerLeftWorld$);
  }

  private _listenForContainerPlayerActions() {
    const playerPickContainer$ = this._socketService
      .fromEvent<SocketPickItem>('player-container-pick')
      .pipe(filter((socketPickItem) => !!socketPickItem))
      .subscribe((socketPickItem) => {
        this._store.dispatch(
          CharactersActions.playerPickAnItem({ ...socketPickItem }),
        );
      });
    this._socketService.addSubscription(playerPickContainer$);

    const containerRemove$ = this._socketService
      .fromEvent<SocketRemoveContainer>('player-container-remove')
      .pipe(filter((socketRemoveContainer) => !!socketRemoveContainer))
      .subscribe(({ x, y }) => {
        this._store.dispatch(
          WorldActions.removeEntity({ x, y, entity: 'container' }),
        );
      });
    this._socketService.addSubscription(containerRemove$);
  }

  private _listenForPlayerUpdateInfosActions() {
    const playerUpdateInfos$ = this._socketService
      .fromEvent<PlayerUpdateInfosActionSocket>('player-update-infos')
      .pipe(filter((playerUpdateInfos) => !!playerUpdateInfos))
      .subscribe((updateInfos) => {
        this._store.dispatch(
          CharactersActions.playerUseAnItem({ updateInfos }),
        );
      });
    this._socketService.addSubscription(playerUpdateInfos$);
  }

  private _listenForPlayerLvlUpActions() {
    const socialSubscription = this._socketService
      .fromEvent<number>('player-level-up')
      .pipe(filter((playerLevelUp) => !!playerLevelUp))
      .subscribe((playerId) => {
        this._store.dispatch(
          CharactersActions.levelUp({
            id: playerId,
          }),
        );
      });
    this._socketService.addSubscription(socialSubscription);
  }
}
