import { createReducer, on } from '@ngrx/store';
import { bankMaxSize } from '@t12/common/bank/constants/bank-max-size.constant';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { dedupe } from '@t12/common/utils/constants/dedupe.constant';
import { nbRecipesPage } from '@t12/jobs/constants/nb-recipes-page.constant';
import { TabJob } from '@t12/jobs/enums/tab-job.enum';
import { JobActions } from '@t12/jobs/store/actions/job/job.actions';
import { LearnRecipeActions } from '@t12/jobs/store/actions/recipe/learn/learn-recipe.actions';
import { ListRecipesActions } from '@t12/jobs/store/actions/recipe/list/list-recipes.actions';
import { WorkshopActions } from '@t12/jobs/store/actions/workshop/workshop.actions';
import { initialJobState, JobState } from '../index';

export const JobReducer = createReducer(
  initialJobState,

  on(JobActions.setActiveJob, (jobState: JobState, { jobCode }) => ({
    ...jobState,
    activeJobCode: jobCode,
  })),

  on(JobActions.setActiveTab, (jobState: JobState, { tab }) => ({
    ...jobState,
    activeTab: tab,
  })),

  on(
    LearnRecipeActions.learnRecipeSuccess,
    (jobState: JobState, { recipe }) => {
      const recipes =
        jobState.activeJobCode === recipe.jobCode
          ? [...jobState.recipes, recipe]
          : jobState.recipes;

      return {
        ...jobState,
        recipes: recipes.sort((a, b) => b.requiredXp - a.requiredXp),
      };
    },
  ),

  on(
    ListRecipesActions.setActiveRecipeFilter,
    (jobState: JobState, { filter }) => {
      return {
        ...jobState,
        recipeFilter: filter,
      };
    },
  ),

  on(ListRecipesActions.setRecipes, (jobState: JobState, { recipes }) => {
    const recipeFilters = dedupe(
      recipes.map(
        (recipe) => recipe.products[0].slot ?? recipe.products[0].type,
      ),
    ).sort();

    const recipeFilter = recipeFilters.find(
      (filter) => filter === ItemType.Miscellaneous || recipeFilters[0],
    );

    return {
      ...jobState,
      recipes: [...recipes].sort((a, b) => b.requiredXp - a.requiredXp),
      recipeFilter,
      recipeFilters,
      activeTab: TabJob.Recipes,
      currentPage: 1,
    };
  }),

  on(ListRecipesActions.orderedRecipes, (jobState: JobState) => {
    const currentPage = Math.min(jobState.currentPage, bankMaxSize);
    const startIndex = (currentPage - 1) * nbRecipesPage;

    const activeFilter =
      jobState.recipes[0].products[0].slot ||
      jobState.recipes[0].products[0].type;

    const recipes = [...jobState.recipes].sort(
      (a, b) => b.requiredXp - a.requiredXp,
    );

    const displayedRecipes = recipes.filter(({ products: [{ type, slot }] }) =>
      [slot, type].includes(jobState.recipeFilter || activeFilter),
    );

    return {
      ...jobState,
      recipes,
      displayedRecipes: displayedRecipes.slice(
        startIndex,
        startIndex + nbRecipesPage,
      ),
      maxPage: Math.ceil(displayedRecipes.length / nbRecipesPage),
    };
  }),

  on(ListRecipesActions.updateFilters, (jobState: JobState) => {
    const recipeFilters = dedupe(
      jobState.recipes.map(
        (recipe) => recipe.products[0].slot ?? recipe.products[0].type,
      ),
    ).sort();

    return {
      ...jobState,
      recipeFilters,
    };
  }),

  on(ListRecipesActions.incCurrentPage, (jobState: JobState, { inc }) => {
    const newPage = Math.min(
      Math.max(jobState.currentPage + inc, 1),
      jobState.maxPage,
    );

    return {
      ...jobState,
      currentPage: newPage,
    };
  }),

  on(ListRecipesActions.setMaxPage, (jobState: JobState, { maxPage }) => ({
    ...jobState,
    maxPage,
  })),

  on(
    WorkshopActions.openWorkshopSuccess,
    (jobState: JobState, { workshop }) => ({
      ...jobState,
      workshop,
      activeTab: TabJob.Recipes,
      activeJobCode: workshop.jobCode,
    }),
  ),

  on(WorkshopActions.closeWorkshop, (jobState: JobState) => ({
    ...jobState,
    workshop: undefined,
    activeTab: TabJob.Jobs,
    activeJobCode: undefined,
  })),
);
