import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delayActionAnimation } from '@t12/characters/constants/delay-animation.constant';
import { CharactersAttackActions } from '@t12/characters/store/actions/attack/characters-attack.actions';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import {
  getCharacterByCode,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { DialogDbService } from '@t12/dialog/services/dialog-db/dialog-db.service';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  concat,
  concatMap,
  delay,
  from,
  map,
  of,
  switchMap,
  take,
  timer,
  withLatestFrom,
  catchError,
  filter,
} from 'rxjs';
import { EventsActions } from '../actions/events.actions';
import { getCurrentEventStep } from '../selectors/events.selectors';

@Injectable()
export class EventsEffects {
  private _setEventSteps$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.setEventSteps),
      switchMap(() => [
        HudDisplayActions.hideHud({ name: 'barInfos' }),
        EventsActions.startEvent(),
      ]),
    ),
  );

  private _startEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEvent, EventsActions.nextEvent),
      withLatestFrom(this._store.select(getCurrentEventStep)),
      map(([_, eventStep]) => {
        switch (eventStep?.type) {
          case 'weather':
            return EventsActions.startEventWeather({ event: eventStep });
          case 'dialog':
            return EventsActions.startEventDialog({ event: eventStep });
          case 'character':
            return EventsActions.startEventCharacter({ event: eventStep });
          case 'popup':
            return EventsActions.startEventPopup({ event: eventStep });
          default:
            return EventsActions.endEvent();
        }
      }),
    ),
  );

  private _startEventWeather$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventWeather),
      switchMap(({ event }) =>
        concat(
          of(
            ...(event.dayTime
              ? [WorldActions.setTime({ time: event.dayTime })]
              : []),
            ...(event.weather
              ? [WorldActions.setWeather({ weather: event.weather })]
              : []),
          ),
          of(EventsActions.nextEvent()).pipe(delay(4000)),
        ),
      ),
    ),
  );

  private _startEventDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventDialog),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ event }, playerId]) =>
        this._store.select(getCharacterByCode(event.characterCode)).pipe(
          take(1),
          switchMap((npc) =>
            this._dialogDbService.getDialog(playerId, event.dialogCode).pipe(
              take(1),
              map((textsDialog) =>
                DialogActions.startConversationSuccess({
                  textsDialog,
                  npc,
                }),
              ),
            ),
          ),
          catchError(() => of(EventsActions.startEventDialogFailed())),
        ),
      ),
    ),
  );

  private _startEventCharacter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventCharacter),
      switchMap(({ event }) =>
        this._store.select(getCharacterByCode(event.characterCode)).pipe(
          take(1),
          switchMap((character) =>
            from(event.actions).pipe(
              filter(
                (action) =>
                  'attackKind' in action ||
                  'looking' in action ||
                  'direction' in action,
              ),
              concatMap((action) => {
                if ('attackKind' in action) {
                  const { attackKind } = action;
                  return timer(delayActionAnimation).pipe(
                    map(() =>
                      CharactersAttackActions.attack({
                        id: character.id,
                        characterKind: character.kind,
                        attackKind,
                      }),
                    ),
                  );
                } else if ('looking' in action) {
                  const { looking } = action;
                  return timer(delayActionAnimation).pipe(
                    map(() =>
                      CharactersMoveActions.setLooking({
                        id: character.id,
                        kind: character.kind,
                        looking,
                      }),
                    ),
                  );
                } else if ('direction' in action) {
                  const { direction } = action;
                  return timer(delayActionAnimation).pipe(
                    map(() =>
                      CharactersMoveActions.move({
                        id: character.id,
                        kind: character.kind,
                        direction,
                        force: true,
                      }),
                    ),
                  );
                }
              }),
            ),
          ),
        ),
      ),
    ),
  );

  private _startEventPopup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventPopup),
      map(({ event }) => HudDisplayActions.showHud({ name: event.hudCode })),
    ),
  );

  private _freezePlayerStartEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEvent),
      withLatestFrom(this._store.select(getPlayerID)),
      map(([_, id]) =>
        CharactersMoveActions.setCanMove({
          id,
          kind: CharacterKind.PLAYER,
          canMove: false,
        }),
      ),
    ),
  );

  private _unfreezePlayerStartEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.endEvent),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, id]) => [
        CharactersMoveActions.setCanMove({
          id,
          kind: CharacterKind.PLAYER,
          canMove: true,
        }),
        HudDisplayActions.showHud({ name: 'barInfos' }),
      ]),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _dialogDbService: DialogDbService,
    private readonly _store: Store,
  ) {}
}
