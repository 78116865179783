import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuestInfos } from '@t12/common/quest/interfaces/infos-quest.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  public initQuestPlayer(
    playerId: number,
    questCode: string,
    npcCode: string,
  ): Observable<QuestInfos> {
    const body = { playerId, questCode, npcCode };

    return this._http.post(`${environment('quest/init').uri}`, body, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<QuestInfos>;
  }

  public getQuestsPlayer(playerId: number): Observable<QuestInfos[]> {
    return this._http.get(`${environment(`quest/player/${playerId}`).uri}`, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<QuestInfos[]>;
  }

  public getAvailableQuestsNpc(
    playerId: number,
    npcCode: string,
  ): Observable<QuestInfos> {
    return this._http.get(
      `${environment(`quest/npc/${npcCode}/player/${playerId}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<QuestInfos>;
  }

  public validateQuest(playerId: number, questCode: string) {
    return this._http.get(
      `${environment(`quest/player/${playerId}/validate/${questCode}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  public abandonQuest(
    playerId: number,
    questCode: string,
  ): Observable<QuestInfos[]> {
    return this._http.get(
      `${environment(`quest/player/${playerId}/quit/${questCode}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<QuestInfos[]>;
  }
}
