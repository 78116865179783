import { Injectable } from '@angular/core';
import { SocketService } from '../../socket.service';
import { CharacterSocketService } from '../character-socket/character-socket.service';
import { ChatSocketService } from '../chat-socket/chat-socket.service';
import { PlayerSocketService } from '../player-socket/player-socket.service';
import { SocialsFriendSocketService } from '../socials-friend-socket/socials-friend-socket.service';
import { SocialsGroupSocketService } from '../socials-group-socket/socials-group-socket.service';
import { WeatherSocketService } from '../weather-socket/weather-socket.service';

@Injectable({
  providedIn: 'root',
})
export class SocketManagerService {
  constructor(
    private readonly _characterSocketService: CharacterSocketService,
    private readonly _chatSocketService: ChatSocketService,
    private readonly _playerSocketService: PlayerSocketService,
    private readonly _socialsFriendSocketService: SocialsFriendSocketService,
    private readonly _socialsGroupSocketService: SocialsGroupSocketService,
    private readonly _socketService: SocketService,
    private readonly _weatherSocketService: WeatherSocketService,
  ) {}

  init(): void {
    this._socketService.onSocketInitialized().subscribe(() => {
      this._characterSocketService.init();
      this._chatSocketService.init();
      this._playerSocketService.init();
      this._socialsFriendSocketService.init();
      this._socialsGroupSocketService.init();
      this._weatherSocketService.init();
    });
  }
}
