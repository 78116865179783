import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';

export const ContainerActions = createActionGroup({
  source: 'CONTAINER',
  events: {
    'Open container': props<{ containerKind: ContainerKind }>(),
    'Open container success': props<{ container: IContainer }>(),
    'Open container failed': props<{
      containerKind: ContainerKind;
      position: Position;
    }>(),
    'Remove container unavailable': emptyProps(),

    'Pick item container': props<{ index: number }>(),
    'Pick item success': props<{ item: Item; amount: number }>(),
    'Pick item failed no item': props<{ itemCode?: string; amount?: number }>(),
    'Pick item failed not enough place': emptyProps(),
    'Pick item failed': emptyProps(),

    'Remove item': props<{ itemCode: string; amount: number }>(),

    'Inc current page': props<{ inc: 1 | -1 }>(),
    'Update items displayed': emptyProps(),

    'Close container': emptyProps(),
  },
});
