import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { CharacterCreationManagerService } from '@t12/creation-player/services/character-creation-manager/character-creation-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { LANGUAGES } from '@t12/settings/constants/language.constant';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { FullscreenManagerService } from '@t12/settings/services/fullscreen/fullscreen-manager.service';
import { Languages } from '@t12/settings/types/languages.type';
import { defaultMusic } from '@t12/user/constants/default-music-name.constant';
import { AuthenticationDbService } from '@t12/user/services/authentification-db/authentication-db.service';
import { AuthenticationService } from '@t12/user/services/authentification/authentication.service';
import {
  getUserLanguage,
  getUserMail,
} from '@t12/user/store/selectors/user.selectors';
import { LocalService } from '@t12/utils/services/local/local.service';
import {
  getWorldMusic,
  getWorldWeather,
} from '@t12/world/store/selector/world.selectors';
import {
  catchError,
  map,
  switchMap,
  of,
  take,
  tap,
  withLatestFrom,
  filter,
} from 'rxjs';
import { SocketService } from '../../../sockets/services/socket.service';
import { UserActions } from '../actions/user.actions';

@Injectable()
export class UserEffects {
  private _loginUser = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.loginUser),
      switchMap(({ token }) =>
        this._authDbService.login$(token).pipe(
          take(1),
          map(() =>
            UserActions.loginUserSuccess({
              user: this._authService.getUserDetails(),
            }),
          ),
          catchError((error) =>
            of(UserActions.registerLoginUserFail({ error })),
          ),
        ),
      ),
    ),
  );

  private _loginUserSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UserActions.loginUserSuccess),
        switchMap(({ user }) =>
          this._characterCreationService.getPlayerCharacters$().pipe(
            take(1),
            tap((characters: IPlayer[]) => {
              this._audioService.playBackgroundMusic(defaultMusic);
              const targetRoute =
                characters.length !== 0 ? '/characters' : '/characters/new';
              this._router.navigateByUrl(targetRoute);
            }),
            catchError(() => of(UserActions.getPlayerCharactersFailed())),
          ),
        ),
      ),
    { dispatch: false },
  );

  private _registerUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.registerUser),
      withLatestFrom(this._store.select(getUserLanguage)),
      switchMap(([{ token }, language]) =>
        this._authDbService.register$({ ...token, language }).pipe(
          take(1),
          map(() =>
            UserActions.registerUserSuccess({
              user: this._authService.getUserDetails(),
            }),
          ),
          catchError((error) =>
            of(UserActions.registerLoginUserFail({ error })),
          ),
        ),
      ),
    ),
  );

  private _registerUserSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UserActions.registerUserSuccess),
        tap(() => {
          this._router.navigateByUrl('/characters/new');
          this._audioService.playBackgroundMusic(defaultMusic);
        }),
      ),
    { dispatch: false },
  );

  private _changeMusicState$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UserActions.changeMusicState),
        switchMap(({ music }) =>
          this._authDbService.updateUserDetails$({ music }).pipe(
            take(1),
            withLatestFrom(this._store.select(getWorldMusic)),
            tap(([_, worldMusic]) => {
              if (music) {
                const selectedMusic = worldMusic || defaultMusic;
                this._audioService.playBackgroundMusic(selectedMusic);
              } else {
                this._audioService.stopBackgroundMusic();
              }
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  private _changeNoisesState$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UserActions.changeNoisesState),
        switchMap(({ noises }) =>
          this._authDbService.updateUserDetails$({ noises }).pipe(
            take(1),
            withLatestFrom(this._store.select(getWorldWeather)),
            tap(([_, weather]) => {
              if (noises) this._audioService.playWeatherSound(weather);
              else this._audioService.stopWeatherSound();
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  private _changeChatPreviewState$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UserActions.changeChatPreviewState),
        switchMap(({ chatPreview }) =>
          this._authDbService.updateUserDetails$({ chatPreview }).pipe(take(1)),
        ),
      ),
    { dispatch: false },
  );

  private _toggleNextLanguage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.toggleNextLanguage),
      withLatestFrom(this._store.select(getUserLanguage)),
      map(([_, lang]) => {
        const languageKeys = Object.keys(LANGUAGES) as Array<keyof Languages>;
        const currentIndex = languageKeys.findIndex(
          (langKey) => langKey === lang,
        );

        if (currentIndex === -1) return UserActions.changeLanguageFailed();

        const nextIndex = (currentIndex + 1) % languageKeys.length;
        const nextLanguage = languageKeys[nextIndex];

        return UserActions.changeLanguage({ language: nextLanguage });
      }),
    ),
  );

  private _changeLanguage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UserActions.changeLanguage),
        tap(({ language }) => {
          this._translocoService.setActiveLang(language);
        }),
        withLatestFrom(this._store.select(getUserMail)),
        filter(([_, userMail]) => !!userMail),
        switchMap(([{ language }]) =>
          this._authDbService.updateUserDetails$({ language }).pipe(take(1)),
        ),
      ),
    { dispatch: false },
  );

  private _deleteAccount$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.deleteAccount),
      switchMap(() =>
        this._authDbService.delete$().pipe(
          take(1),
          map(() => UserActions.logout()),
        ),
      ),
    ),
  );

  private _logout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.logout),
      tap(() => {
        this._localService.clearToken();
        this._router.navigateByUrl('/');
        this._fullscreenManagerService.removeFullScreen();
        this._audioService.stopBackgroundMusic();
        this._socketService.disconnect();
      }),
      map(() => HudDisplayActions.hideHud({ name: 'settings' })),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _authService: AuthenticationService,
    private readonly _authDbService: AuthenticationDbService,
    private readonly _characterCreationService: CharacterCreationManagerService,
    private readonly _fullscreenManagerService: FullscreenManagerService,
    private readonly _localService: LocalService,
    private readonly _router: Router,
    private readonly _socketService: SocketService,
    private readonly _store: Store,
    private readonly _translocoService: TranslocoService,
  ) {}
}
