import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HudDisplayState } from '../../index';

const selectRoot = createFeatureSelector<HudDisplayState>('hud-display');

export const getAllHudDisplay = createSelector(
  selectRoot,
  (hudDisplay: HudDisplayState) => hudDisplay,
);

export const getHudPlayerInfos = createSelector(
  selectRoot,
  ({ playerInfos }) => playerInfos,
);

export const getHudChat = createSelector(selectRoot, ({ chat }) => chat);

export const getHudDialog = createSelector(selectRoot, ({ dialog }) => dialog);

export const getHudInventory = createSelector(
  selectRoot,
  ({ inventory }) => inventory,
);

export const getHudContainer = createSelector(
  selectRoot,
  ({ container }) => container,
);

export const getHudPresentation = createSelector(
  selectRoot,
  ({ presentation }) => presentation,
);

export const getHudNotification = createSelector(
  selectRoot,
  ({ notification }) => notification,
);

export const getHudQuests = createSelector(selectRoot, ({ quests }) => quests);

export const getHudSettings = createSelector(
  selectRoot,
  ({ settings }) => settings,
);

export const getHudShop = createSelector(selectRoot, ({ shop }) => shop);

export const getHudBank = createSelector(selectRoot, ({ bank }) => bank);

export const getHudJob = createSelector(selectRoot, ({ jobs }) => jobs);

export const getHudSocials = createSelector(
  selectRoot,
  ({ socials }) => socials,
);
