import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { itemTypesItemConsumable } from '@t12/inventory/constants/item-types-consumable.constant';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getPlayerItemInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { hotkeyMaxAllowed } from '@t12/key-commands/constants/hotkey-max-allowed.constant';
import { HotkeyEntity } from '@t12/key-commands/interfaces/hotkey-entity.interface';
import { HotkeyActions } from '@t12/key-commands/store/hotkey/actions/hotkey.actions';
import {
  getHotkeyByItemCode,
  getHotkeys,
} from '@t12/key-commands/store/hotkey/selectors/hotkey.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { isUserDeviceMobile } from '@t12/user/store/selectors/user.selectors';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs';

@Injectable()
export class InventoryFavoriteEffects {
  private _setFavorite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.setFavorite),
      switchMap(({ itemCode }) =>
        this._store.select(getPlayerItemInventory(itemCode)).pipe(
          take(1),
          withLatestFrom(
            this._store.select(getHotkeys),
            this._store.select(getHotkeyByItemCode(itemCode)),
            this._store.select(isUserDeviceMobile),
          ),
          map(([item, hotkeys, hotkeyExist, isMobileDevice]) => {
            if (!item) return InventoryActions.setFavoriteFailedNoItem();

            const hasNoSlots = hotkeys.length >= hotkeyMaxAllowed;
            const isInvalidType = !itemTypesItemConsumable.includes(item.type);

            if (hotkeyExist)
              return HotkeyActions.removeHotkey({
                code: hotkeyExist.entity.code,
              });

            if (isInvalidType)
              return InventoryActions.setFavoriteFailedWrongKind();

            const hotkeyEntity: HotkeyEntity = {
              type: item.type,
              code: item.code,
              quality: item.quality,
              img: item.img,
              amount: item.amount,
            };

            if (isMobileDevice)
              return hotkeys.length === 0
                ? HotkeyActions.addHotkey({ hotkeyEntity })
                : HotkeyActions.setHotkeyAt({ hotkeyEntity, index: 0 });

            if (hasNoSlots)
              return InventoryActions.setFavoriteFailedNoMoreSlot();

            return HotkeyActions.addHotkey({ hotkeyEntity });
          }),
        ),
      ),
    ),
  );

  private _setFavoriteFailedNoItem$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.setFavoriteFailedNoItem),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas cet objet",
          );
        }),
      ),
    { dispatch: false },
  );

  private _setFavoriteFailedNoMoreSlot$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.setFavoriteFailedNoMoreSlot),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez plus de slots de raccourcis disponible",
          );
        }),
      ),
    { dispatch: false },
  );

  private _setFavoriteFailedWrongKind$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.setFavoriteFailedWrongKind),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous ne pouvez pas mettre cet objet en raccourcis',
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
