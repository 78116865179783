import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HotkeyEntity } from '@t12/key-commands/interfaces/hotkey-entity.interface';
import { Hotkey } from '@t12/key-commands/interfaces/hotkey.interface';

export const HotkeyActions = createActionGroup({
  source: 'HOTKEY',
  events: {
    'Load hotkeys': emptyProps(),
    'Add hotkey': props<{ hotkeyEntity: HotkeyEntity }>(),
    'Set hotkey at': props<{ hotkeyEntity: HotkeyEntity; index: number }>(),
    'Remove hotkey': props<{ code: string }>(),
    'Set hotkeys': props<{ hotkeys: Hotkey[] }>(),
    'Reset hotkeys': emptyProps(),
    'Delete hotkeys': emptyProps(),
  },
});
