import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { AttackKind } from '@t12/common/fight/types/attack-kind.type';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { IHarvestPoint } from '@t12/common/job/interfaces/harvest-point.interface';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { PlayerJobs } from '@t12/common/job/interfaces/player-jobs.interface';
import { Workshop } from '@t12/common/job/interfaces/workshop.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';
import { Tile } from '@t12/common/world/interfaces/tile.interface';
import { WorldPosition } from '@t12/common/world/interfaces/world-position.interface';

export const LocalPlayerActions = createActionGroup({
  source: 'LOCAL PLAYER',
  events: {
    'Load player': emptyProps(),
    'Load player success': props<{ player: IPlayer }>(),
    'Load player failed': emptyProps(),

    'Local player attack': props<{ attackKind: AttackKind }>(),

    'Local player move': props<{
      direction: Looking;
    }>(),

    'Level up': props<{ xp: number }>(),

    'Player death': emptyProps(),
    'Player death failed': emptyProps(),
    'Apply death sanction': props<{
      worldPosition: WorldPosition;
    }>(),

    'Add gold': props<{ gold: number }>(),

    'Try equip item': props<{ item: Item }>(),
    'Try un equip item': props<{ item: Item }>(),

    'Add new job': props<{ job: PlayerJob }>(),
    'Remove job': props<{ job: PlayerJob }>(),
    'Set jobs': props<{ jobs: PlayerJobs }>(),
    'Inc Job XP': props<{ jobCode: JobCode }>(),

    Interaction: emptyProps(),
    'Interact tile': props<{ tile: Tile }>(),
    'Interact success': emptyProps(),
    'Get tile description': props<{ position: Position }>(),

    'Interact character': props<{ character: Character }>(),
    'Init dialog': props<{ position: Position }>(),
    'Next dialog': props<{ position: Position }>(),

    'Interact container': props<{ containerKind: ContainerKind }>(),

    'Pick item': props<{ item: Item }>(),
    'Drop item': props<{ itemCode: string; amount: number }>(),
    'Use item': props<{ itemCode: string }>(),

    harvest: props<{ harvestPoint: IHarvestPoint }>(),
    'Open workshop': props<{ workshop: Workshop }>(),
  },
});
