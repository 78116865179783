import { createReducer, on } from '@ngrx/store';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { DayTime } from '@t12/common/world/enums/day-time.enum';
import { WeatherKind } from '@t12/common/world/enums/weather-kind.enum';
import { Tile } from '@t12/common/world/interfaces/tile.interface';
import { LoadingStatus } from '@t12/utils/enums/loading-status.enum';
import { WorldActions } from '../actions/world-actions';
import { initialWorldState } from '../index';

export const WorldReducer = createReducer(
  initialWorldState,

  on(WorldActions.init, () => ({
    ...initialWorldState,
  })),

  on(WorldActions.setWeather, (worldState, { weather }) => {
    switch (weather) {
      case WeatherKind.RAINY: {
        if (worldState.time !== DayTime.NIGHT) {
          return {
            ...worldState,
            weather,
            time: DayTime.NIGHT,
          };
        }
        break;
      }
      case WeatherKind.CLOUDY: {
        if (worldState.time !== DayTime.DAY) {
          return {
            ...worldState,
            weather,
            time: DayTime.DAY,
          };
        }
        break;
      }
      default:
        break;
    }

    return {
      ...worldState,
      weather,
    };
  }),

  on(WorldActions.setWorldLoadingStatus, (worldState, { loadingStatus }) => ({
    ...worldState,
    loadingStatus,
  })),

  on(WorldActions.setTime, (worldState, { time }) => {
    let updatedWeather = worldState.weather;

    if (time === DayTime.DAY) {
      updatedWeather =
        worldState.weather === WeatherKind.CLOUDY
          ? WeatherKind.CLOUDY
          : WeatherKind.CLEAR;
    } else if (time === DayTime.NIGHT) {
      updatedWeather =
        worldState.weather === WeatherKind.RAINY
          ? WeatherKind.RAINY
          : WeatherKind.CLEAR;
    }

    return {
      ...worldState,
      time,
      weather: updatedWeather,
    };
  }),

  on(WorldActions.loadWorld, WorldActions.teleportTo, (worldState) => ({
    ...worldState,
    loadingStatus: LoadingStatus.IN_PROGRESS,
  })),

  on(WorldActions.loadWorldSuccess, (worldState, { world }) => ({
    code: world.code,
    enableWeather: world.enableWeather,
    loadingStatus: LoadingStatus.LOADED,
    music: world.music,
    name: world.name,
    tiles: world.tiles,
    time: world.time,
    weather: world.weather,
  })),

  on(WorldActions.addItemTile, (worldState, { x, y, item }) => {
    const updateItem = (tileItem: Item | undefined): Item => {
      if (!tileItem) return item;
      if (tileItem.code === item.code) {
        return { ...tileItem, amount: tileItem.amount + item.amount };
      }
      return tileItem;
    };

    return {
      ...worldState,
      tiles: worldState.tiles.map((row, rowIndex) =>
        rowIndex === y
          ? row.map((tile, colIndex) =>
              colIndex === x ? { ...tile, item: updateItem(tile.item) } : tile,
            )
          : row,
      ),
    };
  }),

  on(WorldActions.addContainerTile, (worldState, { x, y, container }) => {
    const updateContainer = (
      tileContainer: IContainer | undefined,
    ): IContainer => {
      return container
        ? ({ img: container.img, kind: container.kind } as IContainer)
        : tileContainer!;
    };

    return {
      ...worldState,
      tiles: worldState.tiles.map((row, rowIndex) =>
        rowIndex === y
          ? row.map((tile, colIndex) =>
              colIndex === x
                ? { ...tile, container: updateContainer(tile.container) }
                : tile,
            )
          : row,
      ),
    };
  }),

  on(WorldActions.removeEntity, (worldState, { x, y, entity }) => {
    const updateTile = (tile: Tile): Tile => {
      return entity ? { ...tile, [entity]: undefined } : tile;
    };

    return {
      ...worldState,
      tiles: worldState.tiles.map((row, rowIndex) =>
        rowIndex === y
          ? row.map((tile, colIndex) =>
              colIndex === x ? updateTile(tile) : tile,
            )
          : row,
      ),
    };
  }),

  on(WorldActions.removeItemTile, (worldState, { x, y, amount }) => {
    const updateTile = (tile: Tile): Tile => {
      if (tile.item?.amount && tile.item.amount > amount) {
        return {
          ...tile,
          item: { ...tile.item, amount: tile.item.amount - amount },
        };
      }
      return { ...tile, item: undefined };
    };

    return {
      ...worldState,
      tiles: worldState.tiles.map((row, rowIndex) =>
        rowIndex === y
          ? row.map((tile, colIndex) =>
              colIndex === x ? updateTile(tile) : tile,
            )
          : row,
      ),
    };
  }),
);
